import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  Box,
  ButtonLoading,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'
import { useJoinSubOrganizationMutation } from 'myopswat-web/src/api/organization'
import { TreeNode } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'
import { enqueueSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  selectedOrganization: TreeNode
}

const JoinOrganizationModal = NiceModal.create(({ selectedOrganization }: IProps) => {
  const modal = useModal()
  const { t: translate } = useTranslation()
  const [joinSubOrg, { isLoading: isJoining }] = useJoinSubOrganizationMutation()

  const onCancel = () => {
    modal.hide()
  }

  const onSubmit = async (formData: TreeNode) => {
    try {
      const res = await joinSubOrg({ subOrganizationId: formData.id }).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('joinSubOrganizationSuccess'), { variant: 'success' })
        modal.resolve()
        modal.hide()
      } else {
        const errorMessages = res.errors.map((e: any) => e.message)
        if (errorMessages.length > 0) enqueueSnackbar(errorMessages.join(', '), { variant: 'error' })
        else enqueueSnackbar(translate('joinSubOrganizationFail'), { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const isLoading = useMemo(() => {
    return isJoining
  }, [isJoining])

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove()
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle component="div">
        <Typography variant="h3">
          Join{' '}
          {
            <TypographyLineClamp
              line={1}
              variant="h3"
              sx={{
                maxWidth: 300
              }}
              style={{ display: '-webkit-inline-box', verticalAlign: 'bottom' }}
              tooltipValue={selectedOrganization.name}
            >
              {selectedOrganization.name}
            </TypographyLineClamp>
          }
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ fontWeight: 'normal' }}>
        <Box>
          Are you sure you want to join{' '}
          {
            <TypographyLineClamp
              line={1}
              sx={{
                fontWeight: 'normal',
                maxWidth: 240
              }}
              style={{ display: '-webkit-inline-box', verticalAlign: 'bottom' }}
              tooltipValue={selectedOrganization.name}
            >
              {selectedOrganization.name}
            </TypographyLineClamp>
          }{' '}
          as the Super Admin?
        </Box>
        <div>Please note that you will not be able to leave the organization if you are the sole Super Admin.</div>
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: onCancel,
            disabled: isLoading
          }}
        >
          Cancel
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              onSubmit(selectedOrganization)
            },
            disabled: isLoading
          }}
          isLoading={isLoading}
        >
          Yes
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  )
})

export default JoinOrganizationModal
