import { useLazySystemValueListQuery } from 'myopswat-web/src/api/system'
import { SYSTEM_VALUE_KEYS } from 'myopswat-web/src/constants'
import { useEffect, useState } from 'react'
import { AcademyAnalytics } from '../../../../api/academy/type'

export interface StatisticType {
  id: string
  title: string
  value: string
  description: string
}

export const useStatistics = (analytics?: AcademyAnalytics) => {
  const [getSystemValueList, { data: systemValueInfo }] = useLazySystemValueListQuery()
  const [stats, setStats] = useState<StatisticType[]>([])

  useEffect(() => {
    if (!analytics) {
      getSystemValueList([SYSTEM_VALUE_KEYS.ACADEMY_ANALYTICS])
    }
  }, [analytics, getSystemValueList])

  useEffect(() => {
    const systemAnalytics = systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.ACADEMY_ANALYTICS)?.data
      ?.academy_analytics

    const { enterpriseAcademyPurchases, registeredUserCount, totalUserEnrolled, totalUserCompleted } =
      analytics || systemAnalytics || {}

    setStats([
      {
        id: 'enterprise',
        title: 'Trusted by',
        value: enterpriseAcademyPurchases,
        description: 'different enterprises'
      },
      { id: 'registered', title: 'We have', value: registeredUserCount, description: 'registered students' },
      { id: 'enrolled', title: 'Recorded', value: totalUserEnrolled, description: 'courses taken' },
      {
        id: 'completed',
        title: 'Qualification awarded',
        value: totalUserCompleted,
        description: 'certifications granted'
      }
    ])
  }, [analytics, systemValueInfo])

  return { stats }
}
