import { createContext, useMemo } from 'react'

import { useGetAcademyUserProfileQuery } from 'myopswat-web/src/api/academy'
import { AcademyProfile, AcademyProfileFields } from 'myopswat-web/src/api/academy/type'

import AcademyPage from '.'

import './styles.css'

type AcademyContextProps = {
  isLoading?: boolean
  isError?: boolean
  isAcademyStudent: boolean
  academyProfile: AcademyProfile
  ctaLink: string
}

const defaultAcademyProfile: AcademyProfile = {
  userType: 2,
  totalEnrolledCourses: 0,
  totalCompletedCourses: 0,
  totalAvailableCourses: 0,
  isAllRecommendCoursesCompleted: false,
  completedCourses: [],
  ongoingCourses: [],
  recommendCourses: []
}

export const AcademyContext = createContext<AcademyContextProps>({
  isAcademyStudent: false,
  academyProfile: defaultAcademyProfile,
  ctaLink: ''
})

const academyStudentUserTypes = new Set([1, 2])

const requiredFieldsByUserType: Record<number, AcademyProfileFields[]> = {
  1: ['ongoingCourses', 'recommendCourses', 'completedCourses'],
  2: ['ongoingCourses', 'recommendCourses', 'completedCourses'],
  3: ['recommendCourses'],
  4: ['recommendCourses']
}

const getCtaLink = (userType: number) =>
  userType === 1 ? 'https://learn.opswatacademy.com/certifications' : 'https://learn.opswatacademy.com/pages/partners'

const validateAcademyProfile = (profile: AcademyProfile) => {
  const requiredFields = requiredFieldsByUserType[profile.userType] || []
  return requiredFields.every(field => field in profile && profile[field] != null)
}

const AcademyProvider = () => {
  const { data: academyProfile, isLoading, isError: queryError } = useGetAcademyUserProfileQuery()

  const isAcademyStudent = academyStudentUserTypes.has(academyProfile?.userType ?? 0)
  const isError = queryError || !validateAcademyProfile(academyProfile || defaultAcademyProfile)

  const contextValue = useMemo(
    () => ({
      isLoading,
      isError,
      isAcademyStudent,
      academyProfile: academyProfile || defaultAcademyProfile,
      ctaLink: getCtaLink(academyProfile?.userType ?? 0)
    }),
    [isLoading, isError, isAcademyStudent, academyProfile]
  )

  return (
    <AcademyContext.Provider value={contextValue}>
      <AcademyPage />
    </AcademyContext.Provider>
  )
}

export default AcademyProvider
