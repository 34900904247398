import React, { useCallback, useEffect, useState } from 'react'

import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DIALOGS, handleBackCM } from '@myopswat/common'
import { useRejectSurveyMutation, useSubmitSurveyMutation } from 'myopswat-web/src/api/survey'
import { ISubmitSurveyInput } from 'myopswat-web/src/api/survey/types'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import {
  saveCloseNPSSurvey,
  selectDialogs,
  selectIsSkipPersonal,
  toggleDialogs
} from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { dialogURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import DialogNPSSurvey from '.'
import DialogNPSNotification from './dialogNotification'
import DialogNPSSuccess from './dialogSuccess'
import { DialogNPSSurveyContext, EXCLUDE_NPS_PATHS, NPS_OPTIONS } from './interface'
import DialogReferralSource from '../DialogReferralSouce'

const DialogNPSSurveyProvider: React.FC<any> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isSkipPersonal = useTypedSelector(selectIsSkipPersonal)
  const isCustomer = _get(profileData, 'isCustomer', true)

  const { t: translate } = useTranslation()
  const [surveyData, setSurveyData] = useState<any>()
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)

  const npsOrigin = _get(dialogType, DIALOGS_WEB.NPS_SURVEY_ORIGIN)
  const openNotificationDialog = _get(dialogType, DIALOGS_WEB.NPS_NOTIFICATION, false)
  const openPersonalizeDialog = _get(dialogType, DIALOGS.PERSONAL_INFORMATION, false)
  const hideSurveyDialog = _get(dialogType, DIALOGS_WEB.NPS_SURVEY_HIDDEN, false)

  // conditions
  const showSurveyCondition = !openPersonalizeDialog && !hideSurveyDialog
  const openSurveyDialog = _get(dialogType, DIALOGS_WEB.NPS_SURVEY, false) && showSurveyCondition
  const showNotification = _get(surveyData, 'isShowPopup') && showSurveyCondition
  const surveyExtraOptions = _get(surveyData, 'extraOptions', {})
  const isSkippable = _get(surveyData, 'isSkippable', false)
  const skipDays = _get(surveyData, 'skipDays')

  const defaultKeys = ['surveyId', 'action', 'allowedContact', 'representative', 'answers']

  const { control, handleSubmit, reset, setValue } = useForm<ISubmitSurveyInput>({
    defaultValues: {
      surveyId: _get(surveyData, 'id', ''),
      action: 'answer',
      allowedContact: true,
      representative: 'ALL',
      answers: []
    }
  })

  const [rejectSurvey, { isLoading: isLoadingRejectSurvey }] = useRejectSurveyMutation()
  const [submitSurvey, { isLoading: isLoadingSubmitSurvey }] = useSubmitSurveyMutation()

  const handleOpenNotificationDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.NPS_NOTIFICATION]: true
      })
    )
  }

  const handleCloseNotificationDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.NPS_NOTIFICATION]: false
      })
    )
  }

  const handleOpenSurveyDialog = () => {
    reset()
    handleCloseNotificationDialog()
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.NPS_SURVEY]: true
      })
    )
  }

  const handleCloseSurveyDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.NPS_SURVEY]: false
      })
    )
  }

  const handleOpenReferralDialog = () => {
    if (!openPersonalizeDialog) {
      dispatch(saveCloseNPSSurvey(true))
      return <DialogReferralSource />
    }
    return null
  }

  const handleGoBack = () => {
    if (window.location.pathname.includes(dialogURL)) {
      handleBackCM(dialogURL)
    }
  }

  const handleOpenSuccessDialog = () => {
    handleCloseSurveyDialog()
    setOpenSuccessDialog(true)
  }

  const handleCloseSuccessDialog = (goBack?: boolean) => {
    setOpenSuccessDialog(false)
    goBack && handleGoBack()
  }

  const handleSubmitNPSSurvey = useCallback(
    async (data: ISubmitSurveyInput) => {
      try {
        let answers: any[] = []
        let allLicensedProducts = false
        Object.entries(data).forEach(([key, value]) => {
          if (!defaultKeys.includes(key)) {
            const answer = value === null ? undefined : _get(value, 'value', value)
            if (answer === NPS_OPTIONS.ALL_LICENSED_PRODUCTS) {
              allLicensedProducts = true
            } else {
              answers = [
                ...answers,
                {
                  questionId: key,
                  answer
                }
              ]
            }
            delete data[`${key as keyof ISubmitSurveyInput}`]
          }
        })

        await submitSurvey({ ...data, surveyId: _get(surveyData, 'id', ''), answers, allLicensedProducts })
          .unwrap()
          .then(data => {
            if (data?.success) {
              handleOpenSuccessDialog()
            } else {
              enqueueSnackbar(translate('submitFeedbackFail'), {
                variant: 'error'
              })
            }
          })
          .catch(() => {
            enqueueSnackbar(translate('submitFeedbackFail'), {
              variant: 'error'
            })
          })
      } catch (error) {
        console.error(error)
      }
    },
    [surveyData]
  )

  const handleRejectNPSSurvey = useCallback(
    async (action: 'reject' | 'no_answer') => {
      try {
        handleCloseNotificationDialog()
        handleCloseSurveyDialog()
        handleGoBack()
        if (npsOrigin !== 'left-menu') {
          await rejectSurvey({ surveyId: _get(surveyData, 'id', ''), action })
            .unwrap()
            .then((response: any) => {
              if (!_get(response, 'success', true)) {
                console.log(_get(response, 'errors'))
              }
            })
        }
        handleOpenReferralDialog()
      } catch (error) {
        console.error(error)
      }
    },
    [npsOrigin, surveyData]
  )

  const handleGetSurvey = useCallback(
    async (origin: string) => {
      if (origin === 'left-menu' || origin === 'response-action') {
        setSurveyData(_get(profileData, 'staticSurvey'))
      } else {
        setSurveyData(_get(profileData, 'survey'))
      }
    },
    [profileData]
  )

  const handleReset = useCallback(() => {
    reset()
  }, [])

  const handleGetNPSOption = useCallback(
    (key: string) => {
      const defaultValue = _get(DEFAULT_EXTRA_OPTIONS, key, '')
      return _get(surveyExtraOptions, key, defaultValue)
    },
    [surveyExtraOptions]
  )

  useEffect(() => {
    handleGetSurvey(npsOrigin)
  }, [npsOrigin])

  useEffect(() => {
    if (!EXCLUDE_NPS_PATHS.includes(window.location.pathname)) {
      if (showNotification === true) {
        !openSurveyDialog && handleOpenNotificationDialog()
      } else if (showNotification === false) {
        handleOpenSurveyDialog()
      }
    }
  }, [showNotification])

  return (
    <DialogNPSSurveyContext.Provider
      value={{
        control,
        handleSubmit,
        handleSubmitNPSSurvey,
        handleRejectNPSSurvey,
        isLoadingRejectSurvey,
        isLoadingSubmitSurvey,
        surveyData,
        openSurveyDialog,
        openSuccessDialog,
        openNotificationDialog,
        handleOpenSurveyDialog,
        handleCloseSurveyDialog,
        handleCloseSuccessDialog,
        handleReset,
        setValue,
        handleOpenReferralDialog,
        isCustomer,
        handleGetNPSOption,
        isSkippable,
        npsOrigin,
        skipDays
      }}
    >
      {surveyData && (!isSkipPersonal || ['left-menu', 'response-action'].includes(npsOrigin)) && (
        <>
          <DialogNPSNotification />
          <DialogNPSSurvey />
          <DialogNPSSuccess />
        </>
      )}
    </DialogNPSSurveyContext.Provider>
  )
}

export default DialogNPSSurveyProvider

const DEFAULT_EXTRA_OPTIONS: any = {
  allowedContactText: 'Product Management team can contact me to learn about my experience',
  footerText: 'By submitting your feedback, you are contributing to the enhancement of OPSWAT products and services.',
  submitBtn: 'Submit',
  cancelBtn: 'Dismiss',
  identityText: 'I am completing the form as a representative of',
  placeholder: {
    detractor:
      'We are sorry to hear that you are encountering difficulties with our product. Providing detailed feedback allows us to better tailor our services to meet your needs and exceed your expectations.',
    default: `To protect your privacy, please don't include any sensitive information.`
  },
  helperText: {
    detractor:
      'We are here to help - your detailed feedback is key to improving and ensuring your experience is exceptional.',
    default: ''
  }
}
