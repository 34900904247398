import { DIALOGS } from '@myopswat/common'

import { selectDialogs, toggleDialogs } from '../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from '../store'

let resolveCallback: any

const useConfirmationDialog = () => {
  const dialogState = useTypedSelector(selectDialogs)
  const dispatch = useAppDispatch()

  const onConfirm = () => {
    closeConfirmation()
    resolveCallback(true)
  }

  const onCancel = () => {
    closeConfirmation()
    resolveCallback(false)
  }

  const openConfirmation = async (data: any, customContent?: any) => {
    dispatch(
      toggleDialogs({
        [DIALOGS.CONFIRM]: true,
        [`${DIALOGS.CONFIRM}_DATA`]: data,
        [`${DIALOGS.CONFIRM}_CUSTOM_CONTENT`]: customContent
      })
    )

    return new Promise((res: any) => {
      resolveCallback = res
    })
  }

  const closeConfirmation = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.CONFIRM]: false
      })
    )
    setTimeout(() => {
      dispatch(
        toggleDialogs({
          [`${DIALOGS.CONFIRM}_DATA`]: null,
          [`${DIALOGS.CONFIRM}_CUSTOM_CONTENT`]: null
        })
      )
    }, 100)
  }

  return {
    openConfirmation,
    closeConfirmation,
    onCancel,
    onConfirm,
    dialogState
  }
}

export { useConfirmationDialog as default }
