import _template from 'lodash/template'

import { useContext } from 'react'

import { Box, Button, Grid, Typography } from '@opswat/react-ui'

import { useUserProfile } from 'myopswat-web/src/hooks'
import { getSeriesPercentages } from 'myopswat-web/src/pages/LicensedProductsPage/CustomerLicensesPage/sections/ChartSection/utils'

import { AcademyDonutChart, AcademySection } from '../../components'
import { AcademyContext } from '../../context'

const LearningProgressSection = () => {
  const { academyProfile, ctaLink } = useContext(AcademyContext)
  const { getProfileProperty } = useUserProfile()

  const { totalEnrolledCourses, totalCompletedCourses, totalAvailableCourses } = academyProfile

  const firstName = getProfileProperty('firstName', '')

  const isEmptyData = totalEnrolledCourses === 0 && totalCompletedCourses === 0

  const enrollmentStats = {
    enrolled: totalEnrolledCourses,
    notEnrolled: totalAvailableCourses - totalEnrolledCourses
  }

  const completionStats = {
    completed: totalCompletedCourses,
    notCompleted: totalEnrolledCourses - totalCompletedCourses
  }

  const handleRenderDescription = (count: number, totalCount: number, description: string) => {
    const text = `${count}/${totalCount}`
    const template = _template(description)
    return template({ statistics: `<strong>${text}</strong>` })
  }

  const handleRenderCenterTitle = (statistics: any, color: string) => {
    return (
      <Typography sx={{ fontSize: '28px', fontWeight: 500, color }}>
        {getSeriesPercentages(Object.values(statistics), 0)[0]}%
      </Typography>
    )
  }

  return (
    <AcademySection title="Learning Progress">
      <Grid
        container
        sx={{
          backgroundColor: '#EFF4FF',
          padding: '30px 40px'
        }}
      >
        <Grid
          item
          xs={12}
          md={isEmptyData ? 12 : 3}
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            [theme.breakpoints.down(1200)]: {
              flexBasis: '100%',
              maxWidth: '100%',
              alignItems: 'center',
              textAlign: 'center',
              marginBottom: '30px'
            }
          })}
        >
          <Box>
            <Typography
              color="#1B273C"
              sx={{
                fontSize: isEmptyData ? '20px' : '14px',
                lineHeight: isEmptyData ? '24px' : '20px',
                textAlign: isEmptyData ? 'center' : 'left',
                fontWeight: 500
              }}
            >
              Welcome <span style={{ textTransform: 'capitalize' }}>{firstName}</span>
            </Typography>
            {!isEmptyData && (
              <Typography variant="body2" color="#485161">
                Tracking your learning progress
              </Typography>
            )}
          </Box>
          {!isEmptyData && (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              sx={{
                width: 'fit-content'
              }}
              href={ctaLink}
            >
              Start Learning
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          sx={theme => ({
            display: isEmptyData ? 'none' : 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '3vw',
            [theme.breakpoints.down(1200)]: {
              flexBasis: '100%',
              maxWidth: '100%',
              justifyContent: 'center'
            },
            [theme.breakpoints.down(976)]: {
              flexWrap: 'wrap'
            }
          })}
        >
          <AcademyDonutChart
            id="academy-enrollment-chart"
            title={'Course Enrollment'}
            labels={['Enrolled', 'Not Enrolled']}
            colors={['#1D6BFC', '#8E939B']}
            statistics={enrollmentStats}
            description={handleRenderDescription(
              totalEnrolledCourses,
              totalAvailableCourses,
              'You have enrolled in <%= statistics %> courses'
            )}
            centerTitle={handleRenderCenterTitle(enrollmentStats, '#707682')}
          />
          <AcademyDonutChart
            id="academy-completion-chart"
            title={'Course Completion'}
            labels={['Completed', 'Not Completed']}
            colors={['#15a87c', '#8E939B']}
            statistics={completionStats}
            description={handleRenderDescription(
              totalCompletedCourses,
              totalEnrolledCourses,
              'You have completed <%= statistics %> courses'
            )}
            centerTitle={handleRenderCenterTitle(completionStats, '#15a87c')}
          />
        </Grid>
      </Grid>
    </AcademySection>
  )
}

export default LearningProgressSection
