import { EnergyIcon } from '@opswat/react-icon'

import { SectionCard, SectionCardContent } from '../../components'

const LevelUp = () => {
  return (
    <SectionCard>
      <SectionCardContent
        icon={<EnergyIcon />}
        title="Level Up Your Cybersecurity Career"
        description="OPSWAT Academy stands at the forefront of Critical Infrastructure Protection (CIP) training, delivering vital
        cybersecurity education to both individuals and organizations around the globe. Dedicated to equipping
        cybersecurity professionals with the essential skills to protect critical infrastructure, OPSWAT Academy
        strives to close the cybersecurity skills gap with its innovative training programs."
      />
    </SectionCard>
  )
}

export default LevelUp
