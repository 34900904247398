import _isEmpty from 'lodash/isEmpty'

import { useCallback, useEffect, useRef, useState } from 'react'

import { Composer, Container, getClient, MessageList, WebchatClient, WebchatProvider } from '@botpress/webchat'
import { CloseIcon, RefreshIcon, SearchIcon } from '@opswat/react-icon'
import { Box, Button, FormHelperText, InputAdornment, TextField } from '@opswat/react-ui'

import {
  selectCurrentChatbotTopic,
  selectHasChattedWithChatbot,
  selectHasInitializedChatbot,
  selectHasShownSubmitCaseMessage,
  setCurrentChatbotTopic,
  setHasChattedWithChatbot,
  setHasInitializedChatbot
} from 'myopswat-web/src/containers/SupportServicesContainer/supportServicesContainerSlice'
import { useGetChatbotUser } from 'myopswat-web/src/hooks/useGetChatbotUser'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import CustomChatbotHeader from './ChatbotHeader'
import CustomChatbotHeaderButton from './ChatbotHeaderButton'
import {
  CHAT_BOT_CUSTOM_EVENT,
  CHAT_BOT_ERROR,
  SUPPORT_CHATBOT_CONFIG,
  SUPPORT_CHATBOT_HELPER_TEXT,
  SUPPORT_CHATBOT_PLACEHOLDER
} from './constants'

import './chatbot.css'

export const ChatbotSearch: React.FC<unknown> = () => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const currentChatbotTopic = useTypedSelector(selectCurrentChatbotTopic)
  const hasInitializedChatbot = useTypedSelector(selectHasInitializedChatbot)
  const hasChattedWithChatbot = useTypedSelector(selectHasChattedWithChatbot)
  const hasShownSubmitCaseMessage = useTypedSelector(selectHasShownSubmitCaseMessage)

  const dispatch = useAppDispatch()
  const helperTextRef = useRef<any>()
  const userData = useGetChatbotUser(profileData)

  const [isChatbotOpen, setIsChatbotOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<any>()
  const [inputValue, setInputValue] = useState<string>('')
  const [client, setClient] = useState<WebchatClient | null>(null)

  const handleToggleChatbot = () => {
    setIsChatbotOpen(prev => !prev)
  }

  const handleSendMessageToChatbot = async (message: string) => {
    if (!hasInitializedChatbot) {
      await client?.newConversation().then(() => dispatch(setHasInitializedChatbot(true)))
    }
    await client
      ?.sendMessage({
        type: 'text',
        text: message
      })
      .then(() => {
        dispatch(setHasChattedWithChatbot(true))
      })
      .catch((e: Error) => {
        console.error(e)
        if (e.message.includes(CHAT_BOT_ERROR.CONVERSATION_CREATING)) {
          setErrorMessage(SUPPORT_CHATBOT_HELPER_TEXT.SUBMIT_CASE_ERROR)
        }
      })
  }

  const handleSubmitChatbot = async (e?: any) => {
    e?.stopPropagation()
    if (!inputValue) {
      return
    }
    if (hasChattedWithChatbot) {
      handleToggleChatbot()
      return
    }
    handleToggleChatbot()
    handleSendMessageToChatbot(inputValue)
  }

  const handleReplaceVoiceIcon = () => {
    const svgElement = document.querySelector('.bpComposerVoiceButtonIcon')

    if (svgElement) {
      const customSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      customSvg.setAttribute('width', '16')
      customSvg.setAttribute('height', '18')
      customSvg.setAttribute('viewBox', '0 0 16 18')
      customSvg.setAttribute('fill', 'none')
      customSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      customSvg.innerHTML = `
          <g id="mic-2 1">
            <g id="Group">
              <path id="Vector" d="M12 10.332V10.9987C12 13.208 10.2093 14.9987 8 14.9987C5.79067 14.9987 4 13.208 4 10.9987V10.332" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_2" d="M2 10.332H14" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_3" d="M8 16.3333V15" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
              <path id="Vector_4" d="M4 7.66602V5.66602C4 3.45668 5.79067 1.66602 8 1.66602C10.2093 1.66602 12 3.45668 12 5.66602V7.66602" stroke="#1B273C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            </g>
          </g>
        `
      svgElement.replaceWith(customSvg)
    }
  }

  const handleOffsetChatbotPosition = () => {
    if (helperTextRef.current) {
      // check the height of the helper text and set the position of the chatbot modal accordingly
      const interval = setInterval(() => {
        const helperTextHeight = helperTextRef.current.offsetHeight ?? 0
        const chatbotContainer = document.querySelector('.bpContainer')
        if (chatbotContainer) {
          chatbotContainer.setAttribute('style', `top: -${helperTextHeight > 16 ? helperTextHeight - 16 : 0}px`)
          clearInterval(interval)
        }
      }, 500)
    }
  }

  const handleRestartConversationBottom = useCallback(() => {
    const interval = setInterval(() => {
      const chatbots = document.getElementsByClassName('bpOpen')
      if (chatbots.length > 0) {
        const elements = document.getElementsByName('webchat')
        if (elements.length > 0) {
          const iframeChat = elements[0] as HTMLIFrameElement
          const documentChat = iframeChat.contentWindow?.document
          const chatbotAction = documentChat?.querySelector('.bpHeaderContentActionsIcons')
          if (chatbotAction) {
            chatbotAction.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
            )
            setTimeout(() => {
              const restartConversationButton = documentChat?.querySelector('.bpModalDialogNewConversationButton')
              if (restartConversationButton) {
                restartConversationButton.dispatchEvent(
                  new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                  })
                )
              }
            }, 500)
            clearInterval(interval)
          }
        }
      }
    }, 500)
  }, [])

  const handleRestartConversationMiddle = () => {
    client
      ?.newConversation()
      .then(() => {
        dispatch(setCurrentChatbotTopic(''))
      })
      .catch((e: Error) => {
        console.error(e)
        if (e.message.includes(CHAT_BOT_ERROR.DISCONNECTED)) {
          setErrorMessage(SUPPORT_CHATBOT_HELPER_TEXT.SUBMIT_CASE_ERROR)
        }
      })
  }

  const handleOpenLiveAgentChat = useCallback((userData: any) => {
    setTimeout(() => handleToggleChatbot(), 5000)
    window.botpress.open()
    window.botpress.updateUser({
      data: userData
    })
    if (window.botpress.initialized) {
      handleRestartConversationBottom()
    }
  }, [])

  const handleConnectChatbot = () => {
    const newClient = getClient({ clientId: process.env.REACT_APP_SUPPORT_BOTPRESS_ID ?? '' })

    newClient.on('*', e => {
      // for debugging purposes, will be removed later
      if (['DEV', 'STAGING'].includes(process.env.REACT_APP_ENV ?? '')) {
        console.log('chatbot event:', e)
      }

      if (e.type === 'conversation') {
        setErrorMessage(undefined)
      }

      if (e.type === 'customEvent' && e.payload) {
        const payload = e.payload

        switch (payload.eventType) {
          case CHAT_BOT_CUSTOM_EVENT.TOPIC_CHANGED:
            if (payload.topic) {
              dispatch(setCurrentChatbotTopic(payload.topic))
              setInputValue(payload.topic)
            }
            break
          case CHAT_BOT_CUSTOM_EVENT.LIVE_CHAT_REQUESTED:
            handleOpenLiveAgentChat({ ...userData, ...payload })
            break
        }
      }
    })

    setClient(newClient)
  }

  useEffect(() => {
    handleConnectChatbot()

    const observer = new MutationObserver(handleReplaceVoiceIcon)
    observer.observe(document.body, { childList: true, subtree: true })

    window.addEventListener('resize', handleOffsetChatbotPosition)
    handleOffsetChatbotPosition()

    return () => {
      observer.disconnect()
      window.removeEventListener('resize', handleOffsetChatbotPosition)
    }
  }, [])

  useEffect(() => {
    inputValue && dispatch(setCurrentChatbotTopic(inputValue))
  }, [inputValue])

  useEffect(() => {
    setInputValue(currentChatbotTopic)
  }, [currentChatbotTopic])

  return (
    <Box className="bpChatbotContainer">
      <Box className="bpSearchContainer">
        <TextField
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              handleSubmitChatbot()
            }
          }}
          onClick={() => {
            hasChattedWithChatbot && handleToggleChatbot()
          }}
          placeholder={SUPPORT_CHATBOT_PLACEHOLDER}
          className="bpChatbotSearchInput"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={`${process.env.REACT_APP_S3_ASSETS_URL}/images/chatbot_avatar.gif`}
                  width={40}
                  height={40}
                  style={{
                    borderRadius: '50%'
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Button className="bpChatbotHeaderSearchButton" onClick={handleSubmitChatbot}>
                  <SearchIcon color="#FFFFFF" size={16} />
                </Button>
              </InputAdornment>
            )
          }}
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off'
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: '#FFFFFF'
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#1B273C'
            },
            input: {
              '&::placeholder': {
                opacity: 1,
                color: '#707682'
              }
            }
          }}
        />
        <FormHelperText
          ref={helperTextRef}
          sx={{
            marginTop: '7px',
            textAlign: 'center',
            color: '#616875'
          }}
        >
          {SUPPORT_CHATBOT_HELPER_TEXT.ACTION}{' '}
          <span
            style={{
              color: '#1B273C'
            }}
          >
            {SUPPORT_CHATBOT_HELPER_TEXT.KEYWORD_1}
          </span>{' '}
          or{' '}
          <span
            style={{
              color: '#1B273C'
            }}
          >
            {SUPPORT_CHATBOT_HELPER_TEXT.KEYWORD_2}
          </span>
        </FormHelperText>
        {client && (
          <WebchatProvider client={client} configuration={SUPPORT_CHATBOT_CONFIG} userData={userData}>
            <div className={`chatbot${isChatbotOpen ? 'Expanded' : 'Collapsed'}`}>
              <Container>
                <CustomChatbotHeaderButton
                  onClick={handleToggleChatbot}
                  icon={<CloseIcon size={24} color="#1B273C" />}
                  style={{
                    position: 'absolute',
                    top: '11px',
                    right: '18px'
                  }}
                />
                <CustomChatbotHeaderButton
                  onClick={handleRestartConversationMiddle}
                  icon={<RefreshIcon size={24} color="#1B273C" />}
                  style={{
                    position: 'absolute',
                    top: '11px',
                    right: '58px'
                  }}
                />
                {isChatbotOpen && (
                  <CustomChatbotHeader
                    defaultShowMessage={hasShownSubmitCaseMessage || !_isEmpty(errorMessage)}
                    submitCaseText={errorMessage}
                  />
                )}
                <MessageList />
                <Composer />
              </Container>
            </div>
          </WebchatProvider>
        )}
      </Box>
    </Box>
  )
}
