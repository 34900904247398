import { useContext } from 'react'

import { AcademyError, AcademyLoading } from './components'
import { AcademyContext } from './context'
import { GeneralView } from './general'
import { StudentView } from './student'

const AcademyPage = () => {
  const { isAcademyStudent, isLoading, isError } = useContext(AcademyContext)

  if (isLoading) return <AcademyLoading />

  if (isError) return <AcademyError />

  return isAcademyStudent ? <StudentView /> : <GeneralView />
}

export default AcademyPage
