import { Box, Button, Typography } from '@opswat/react-ui'

interface IProps {
  icon?: React.ReactNode
  title?: React.ReactNode
  content: React.ReactNode
  buttonText: string
  buttonLink: string
}

const AcademyNoData = ({ icon, title, content, buttonText, buttonLink }: IProps) => {
  return (
    <Box
      sx={{
        padding: '30px 40px',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}
    >
      {icon}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        {title && <Typography variant="h3">{title}</Typography>}
        <Typography variant="body2" color="#1B273C">
          {content}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          width: 'fit-content',
          borderColor: '#1B273C',
          color: '#1B273C',
          '&:hover': {
            borderColor: '#1B273C'
          }
        }}
        href={buttonLink}
      >
        {buttonText}
      </Button>
    </Box>
  )
}

export default AcademyNoData
