import { useEffect, useMemo, useState } from 'react'

import { useModal } from '@ebay/nice-modal-react'
import { formatDatetime } from '@opswat/react-core'
import { TableLoading, TemplateSection } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

import { useLazyNestedOrganizationQuery, useLazyNestedOrganizationUsersQuery } from 'myopswat-web/src/api/organization'
import {
  INestedOrganizationQuery,
  NestedOrganizationUserFilterInput,
  RawNestedOrganizationUserFilterInput
} from 'myopswat-web/src/api/organization/types'
import { useLazyNestedPortalRoleQuery } from 'myopswat-web/src/api/role'
import { NestedSelectOption } from 'myopswat-web/src/components/NestedOrganizationSelect'
import { useUserProfile, useUserRoles } from 'myopswat-web/src/hooks'

import { TreeNode } from 'myopswat-web/src/pages/MyInformationPage/General/RecursiveTree'
import ChangeRoleModal from '../ChangeRole/ChangeRoleModal'
import CreateSubOrganizationModel from '../CreateSubOrganization/CreateSubOrganizationModel'
import InviteUserModal from '../InviteUser/InviteUserModal'
import RemoveUserModal from '../RemoveUser/RemoveUserModal'
import SetSuperAdminModal from '../SetSuperAdmin/SetSuperAdminModal'
import JoinOrganizationModal from './JoinOrganizationModal'
import { OrgUserTable } from './OrgUserTable'
import { NestedOrganizationUser, OrganizationUser } from './type'
import UserFilter from './UserFilterV2'

const defaultQuery: NestedOrganizationUserFilterInput = {
  q: '',
  status: [],
  portalRoles: [],
  currentOrgId: '',
  selectedOrgIds: []
}

interface IProps {
  organization: any
}

export const UserTabV2 = ({ organization }: IProps) => {
  const { state } = useLocation()
  const { getProfileProperty } = useUserProfile()

  const [query, setQuery] = useState<RawNestedOrganizationUserFilterInput>({
    ...defaultQuery,
    currentOrgId: organization?.id,
    portalRoles: state?.portalRoles || [],
    selectedOrgIds: state?.selectedOrgIds || []
  })

  const [getNestedOrganizationUsers, { data, isFetching: isFetchingUsers }] = useLazyNestedOrganizationUsersQuery()
  const [getOrganizationTree, { data: organizationTree }] = useLazyNestedOrganizationQuery()
  const [getNestedPortalRole, { data: roles = [] }] = useLazyNestedPortalRoleQuery()

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '')
    }
  }, [])

  const refetchOrganizationUsers = async () => {
    await getNestedOrganizationUsers({
      ...query,
      selectedOrgIds: query.selectedOrgIds.map((item: any) => item.value)
    }).unwrap()
  }

  const refetchOrganizationUsersWithQuery = async (newQuery: RawNestedOrganizationUserFilterInput) => {
    await getNestedOrganizationUsers({
      ...newQuery,
      selectedOrgIds: newQuery.selectedOrgIds.map(item => item.value)
    }).unwrap()
  }

  // Modals
  const inviteModal = useModal(InviteUserModal)
  const removeModal = useModal(RemoveUserModal)
  const changeRoleModal = useModal(ChangeRoleModal)
  const setSuperAdminModel = useModal(SetSuperAdminModal)
  const createSubOrganization = useModal(CreateSubOrganizationModel)
  const joinOrgModal = useModal(JoinOrganizationModal)

  const roleMap = useMemo(() => {
    return roles.reduce((obj: any, role: any) => ((obj[role.id] = role), obj), {})
  }, [roles])

  const currentOrgRoles = useMemo(() => {
    return data?.portalRoles?.organizationPortalRoles ?? []
  }, [data])

  const nonSuperAdminRoleMap = useMemo(() => {
    const currentOrgRoleMap = currentOrgRoles.reduce((obj: any, role: any) => ((obj[role.id] = role), obj), {})

    return Object.fromEntries(
      Object.entries(currentOrgRoleMap).filter((entry: any[]) => entry[1].name !== 'Super Admin')
    )
  }, [currentOrgRoles])

  const { isAdmin, isSuperAdmin, isProfileSuperAdmin } = useUserRoles(roleMap)

  const handleSearch = (searchData: RawNestedOrganizationUserFilterInput) => {
    const selectedOrgIds = searchData.selectedOrgIds.map(item => item.value)

    const availableRoles = roles
      .filter(
        (r: any) =>
          selectedOrgIds.length === 0 || r.organizationId === null || selectedOrgIds.includes(r.organizationId)
      )
      .map(item => item.id)

    const portalRoles = searchData.portalRoles.filter(
      role => selectedOrgIds.length === 0 || availableRoles.includes(role)
    )

    const newQuery = { ...query, ...searchData, portalRoles }
    setQuery(newQuery)
    refetchOrganizationUsersWithQuery(newQuery)
  }

  const onInviteUser = () => {
    inviteModal.show({ roleMap: nonSuperAdminRoleMap, organization }).then(async () => {
      await refetchOrganizationUsers()
    })
  }

  const onRemoveUser = (orgUser: any) => {
    removeModal
      .show({
        orgUser: orgUser
      })
      .then(async () => {
        await refetchOrganizationUsers()
      })
  }

  const onCreateSubOrganization = () => {
    createSubOrganization.show({ organization }).then(async () => {
      await refetchOrganizationUsers()
      getOrganizationTree(organization?.id)
      getNestedPortalRole(organization?.id)
    })
  }

  const onJoinOrganization = (selectedOrganization: TreeNode) => {
    joinOrgModal.show({ selectedOrganization }).then(() => {
      refetchOrganizationUsers()
    })
  }

  const disabledCreateSubOrg = _get(_get(organization, 'ssoMeta', {}), 'rank') == 5

  const onChangeRole = (user: any) => {
    changeRoleModal
      .show({
        user: user,
        roleMap: nonSuperAdminRoleMap
      })
      .then(async () => {
        await refetchOrganizationUsers()
      })
  }

  const onSetSuperAdmin = (user: any) => {
    setSuperAdminModel
      .show({
        orgUser: user
      })
      .then(async () => {
        await refetchOrganizationUsers()
      })
  }

  useEffect(() => {
    getOrganizationTree(organization?.id)
    getNestedPortalRole(organization?.id)
    refetchOrganizationUsersWithQuery(query)
  }, [])

  const timeZone = getProfileProperty('timezone', 'MMM d, yyyy, h:mm:ss a')

  const convertToNestedOrganizationUsers = (nestedUsers: any[], timeZone = '', level = 0): NestedOrganizationUser[] => {
    return nestedUsers.map(
      (orgUser: any, index): NestedOrganizationUser => ({
        id: orgUser.id,
        name: level === 0 ? orgUser.name : `SUB-ORGANIZATION ${index + 1} (${orgUser.name})`,
        isSelected: orgUser.filtered,
        hasAccess: orgUser.permission,
        users: orgUser.users.map(
          (user: any): OrganizationUser => ({
            id: user.id,
            name: user.fullName,
            email: user.email || user.userEmail,
            active: user.isActive,
            role: user.portalRoleIds.map((role: string) => _get(roleMap, role, '').name).join(', '),
            lastLogin: formatDatetime(user.lastLogin, undefined, timeZone) || '--',
            isLoginUser: user.userId === getProfileProperty('id', ''),
            isAdmin: isAdmin(user.portalRoleIds),
            isSuperAdmin: isSuperAdmin(user.portalRoleIds),
            userId: user.userId,
            userSsoId: user.userSsoId,
            portalRoleIds: user.portalRoleIds
          })
        ),
        children: convertToNestedOrganizationUsers(orgUser.children || [], timeZone, level + 1)
      })
    )
  }

  const convertToNestedSelectOptions = (tree?: INestedOrganizationQuery, level = 0): NestedSelectOption[] => {
    if (!tree) return []

    let options: NestedSelectOption[] = []
    options.push({
      value: tree.id,
      label: tree.name,
      level: level,
      styles: level === 0 ? { color: '#154FBA' } : {}
    })

    if (tree.children && tree.children.length > 0) {
      tree.children.forEach(child => {
        options = options.concat(convertToNestedSelectOptions(child, level + 1))
      })
    }

    return options
  }

  const orgUsers: NestedOrganizationUser[] = useMemo(() => {
    if (_isEmpty(data)) return []

    return convertToNestedOrganizationUsers(data.nestedOrganizationUsers || [], timeZone)
  }, [data, roleMap, timeZone])

  function getIdsWithUsers(nodes: NestedOrganizationUser[]): string[] {
    return nodes.flatMap(node => {
      const hasUsers = node.users.length > 0

      const childrenWithUsers = node.children ? getIdsWithUsers(node.children) : []

      return hasUsers || childrenWithUsers.length > 0 ? [node.id, ...childrenWithUsers] : childrenWithUsers
    })
  }

  const initialCollapsedNodes = useMemo(() => {
    const { q, portalRoles, selectedOrgIds } = query

    if (q || portalRoles.length > 0 || selectedOrgIds.length > 0) {
      return getIdsWithUsers(orgUsers)
    }

    return [organization.id]
  }, [orgUsers, query])

  const nestedOrgs: NestedSelectOption[] = useMemo(() => {
    return convertToNestedSelectOptions(organizationTree)
  }, [organizationTree])

  return (
    <TemplateSection spacing={2}>
      <UserFilter
        organization={organization}
        roles={roles}
        query={query}
        nestedOrgs={nestedOrgs}
        onSearch={handleSearch}
        onInviteUser={onInviteUser}
        onCreateSubOrganization={onCreateSubOrganization}
        disabledCreateSubOrg={disabledCreateSubOrg}
      />

      {isFetchingUsers ? (
        <TableLoading />
      ) : (
        <OrgUserTable
          orgs={orgUsers}
          onChangeRole={onChangeRole}
          onRemoveUser={onRemoveUser}
          onSetSuperAdmin={isProfileSuperAdmin ? onSetSuperAdmin : undefined}
          initialCollapsedNodes={initialCollapsedNodes}
          onJoinOrganization={onJoinOrganization}
        />
      )}
    </TemplateSection>
  )
}
