import _get from 'lodash/get'
import _isInteger from 'lodash/isInteger'
import _isEmpty from 'lodash/isEmpty'

import { FC, useContext, useEffect } from 'react'
import { useFormState, useWatch } from 'react-hook-form'

import { ButtonLoading, DialogAdvanced, Grid } from '@opswat/react-ui'
import imageBg from 'myopswat-web/src/assets/images/feedback-bg.png'

import NPSSurveyForm from '../../Form/NPSSurveyForm'
import { DialogNPSSurveyContext, NPS_MAX_LENGTH, NPS_OPTIONS } from './interface'

const DialogNPSSurvey: FC<unknown> = () => {
  const {
    control,
    surveyData,
    openSurveyDialog,
    handleSubmit,
    handleSubmitNPSSurvey,
    isLoadingSubmitSurvey,
    handleRejectNPSSurvey,
    handleReset,
    isCustomer,
    handleGetNPSOption,
    isSkippable,
    npsOrigin,
    skipDays
  } = useContext(DialogNPSSurveyContext)

  const { isValid } = useFormState({
    control
  })

  const surveyTitle = _get(surveyData, 'name', 'Product Feedback')
  const surveyQuestions = _get(surveyData, 'questions', []).map((question: any) =>
    ['text'].includes(question.type)
      ? {
          ...question,
          maxLength: NPS_MAX_LENGTH
        }
      : question
  )
  const scoreQuestion = surveyQuestions.find((item: any) => ['score'].includes(item.type))
  const scoreValue = useWatch({
    control,
    name: scoreQuestion?.id
  })

  function getNpsDropdownQuestionData(item: any) {
    const productOptions = [
      {
        label: 'All My Licensed Products',
        value: NPS_OPTIONS.ALL_LICENSED_PRODUCTS,
        groupName: item.data.length > 0 ? _get(item, ['data', '0', 'groupName']) : ''
      },
      ...item.data
    ]

    //!! HARD CODED: To ensure that Academy is always after CX in the dropdown
    const cxProductId = '7e80eac7-10f9-4b44-a01c-9d840edd7cc8'
    const academyProductId = '052e7062-a502-4b4d-b0e4-203fec11bdb6'

    const indexCxProduct = productOptions.findIndex((item: any) => item.value === cxProductId)
    const indexAcademyProduct = productOptions.findIndex((item: any) => item.value === academyProductId)

    // Swap their places if Academy is before CX
    if (indexCxProduct !== -1 && indexAcademyProduct !== -1 && indexAcademyProduct < indexCxProduct) {
      const temp = productOptions[indexCxProduct]
      productOptions[indexCxProduct] = productOptions[indexAcademyProduct]
      productOptions[indexAcademyProduct] = temp
    }

    return productOptions
  }

  function checkNPSValidation(questions: any[]) {
    let newQuestions = [...questions].map((question: any) => {
      if (question.id === '0b753739-4166-4d13-9fec-fd1066c6fb15' && question.required) {
        return {
          ...question,
          required: true,
          validate: (value: any) => !_isEmpty(value)
        }
      }
      return question
    })
    if (scoreQuestion) {
      if (isCustomer) {
        newQuestions = newQuestions.map((item: any) =>
          item.type === 'dropdown'
            ? {
                ...item,
                data: getNpsDropdownQuestionData(item)
              }
            : item
        )
      }
      const requireComment = _isInteger(scoreValue) && Number(scoreValue) < 7
      newQuestions = newQuestions.map((question: any) =>
        ['text'].includes(question.type)
          ? {
              ...question,
              required: requireComment,
              validate: (value: string) => !requireComment || !_isEmpty(value?.trim()),
              placeholder: _get(
                question,
                ['placeHolder', requireComment ? 'detractor' : 'default'],
                handleGetNPSOption(`placeHolder.${requireComment ? 'detractor' : 'default'}`)
              ),
              helperText: _get(
                question,
                ['helperText', requireComment ? 'detractor' : 'default'],
                handleGetNPSOption(`helperText.${requireComment ? 'detractor' : 'default'}`)
              )
            }
          : question
      )
    }
    return newQuestions
  }

  function handleDisplayDismissButton() {
    if (npsOrigin === 'left-menu') return 'Dismiss'

    if (!skipDays) return 'Dismiss'

    return skipDays === 1 ? 'Skip for 1 day' : `Skip for ${skipDays} days`
  }

  useEffect(() => {
    handleReset()
  }, [openSurveyDialog])

  return (
    <DialogAdvanced
      open={openSurveyDialog}
      title={surveyTitle}
      background={imageBg}
      content={<NPSSurveyForm questions={checkNPSValidation(surveyQuestions)} />}
      actions={
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Grid
              container
              spacing={2}
              justifyContent={npsOrigin === 'left-menu' || isSkippable ? 'space-between' : 'flex-end'}
            >
              {/* Cancel Btn */}
              {(npsOrigin === 'left-menu' || isSkippable) && (
                <Grid item xs="auto">
                  <ButtonLoading
                    propsButton={{
                      variant: 'text',
                      color: 'inherit',
                      disabled: isLoadingSubmitSurvey,
                      onClick: () => handleRejectNPSSurvey('reject')
                    }}
                  >
                    {handleDisplayDismissButton()}
                  </ButtonLoading>
                </Grid>
              )}

              {/* Submit Btn */}
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    disabled: !isValid || isLoadingSubmitSurvey,
                    onClick: handleSubmit(handleSubmitNPSSurvey)
                  }}
                  propsLoading={{ color: 'inherit' }}
                  isLoading={isLoadingSubmitSurvey}
                >
                  {handleGetNPSOption('submitBtn')}
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
      dialogTitleProps={{
        sx: {
          padding: '16px 30px'
        }
      }}
      dialogContentProps={{
        sx: {
          paddingBottom: '0px',
          paddingX: '30px',
          // hide scrollbars
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
      dialogActionsProps={{
        sx: {
          paddingX: '20px',
          paddingY: '0px',
          marginY: '16px'
        }
      }}
    />
  )
}

export default DialogNPSSurvey
