import { FC } from 'react'

import _get from 'lodash/get'

import { DIALOGS } from '@myopswat/common'
import { ButtonLoading, DialogAdvanced, Grid } from '@opswat/react-ui'
import { useAccountSettingsUpdateMutation } from 'myopswat-web/src/api/account'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { openInNewTab } from 'myopswat-web/src/utils'

import SOCSurveyForm from '../Form/SOCSurveyForm'

const DialogSOCSurvey: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [accountSettingsUpdate, { isLoading }] = useAccountSettingsUpdateMutation()

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.SOC_SURVEY]: false
      })
    )
  }

  const handleSubmitSurvey = async () => {
    try {
      await accountSettingsUpdate({
        showSocSurvey: false
      })
        .unwrap()
        .then()
        .catch(err => {
          console.error(err)
        })

      await handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DialogAdvanced
      title="OPSWAT Security Operations Center Survey"
      open={_get(dialogType, DIALOGS.SOC_SURVEY, false)}
      onClose={() => {
        handleClose()
      }}
      content={
        <>
          <SOCSurveyForm />
        </>
      }
      actions={
        <>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: () => handleSubmitSurvey(),
                  disabled: isLoading
                }}
                propsLoading={{ color: 'primary' }}
                isLoading={isLoading}
              >
                No - do not ask again
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: () => {
                    handleSubmitSurvey()
                    openInNewTab('https://www.surveymonkey.com/r/JFJSC8V')
                  },
                  disabled: isLoading,
                  fullWidth: true
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={false}
              >
                Take the SOC Survey
              </ButtonLoading>
            </Grid>
          </Grid>
        </>
      }
      dialogProps={{
        maxWidth: 'xs'
      }}
    />
  )
}
export default DialogSOCSurvey
