import { useState } from 'react'

import { Button, Grid } from '@opswat/react-ui'

import { RecommendCourse } from 'myopswat-web/src/api/academy/type'

import CourseCard from './CourseCard'

interface EnrollCoursesProps {
  courses: RecommendCourse[]
}

const EnrollCourses = ({ courses }: EnrollCoursesProps) => {
  const [visibleCourses, setVisibleCourses] = useState(4)

  const handleViewMore = () => {
    setVisibleCourses(prev => prev + 4)
  }

  return (
    <Grid item container xs={12} direction="column" gap={2.5} alignItems="center">
      {courses.slice(0, visibleCourses).map(course => (
        <CourseCard key={course.courseName} {...course} />
      ))}
      {courses.length > visibleCourses && (
        <Button variant="outlined" color="primary" onClick={handleViewMore} style={{ alignSelf: 'center' }}>
          View More Courses
        </Button>
      )}
    </Grid>
  )
}

export default EnrollCourses
