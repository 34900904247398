import { ReactNode } from 'react'

import { Grid } from '@opswat/react-ui'

import { Welcome } from '../general/sections'
import AcademySection from './AcademySection'

interface AcademyLoadingErrorLayoutProps {
  children: ReactNode
}

const AcademyLoadingErrorLayout = ({ children }: AcademyLoadingErrorLayoutProps) => {
  return (
    <Grid item xs={12}>
      <AcademySection title="Learning Progress">
        <Welcome />
        {children}
      </AcademySection>
    </Grid>
  )
}

export default AcademyLoadingErrorLayout
