import { Box, TypographyTooltipClamp } from "@opswat/react-ui"
import { FALSE_SUBMISSION_STATUS_STYLE, FalseSubmission, FalseSubmissionStatusEnum } from "myopswat-web/src/api/analyzer/falseSubmission/types"

export const handleRenderStatus = (data: FalseSubmission) => {
  const statusValue = FalseSubmissionStatusEnum[data.cxStatus]

  return (
    <Box display="flex" justifyContent="center">
      <TypographyTooltipClamp
        line={1}
        tooltipValue={statusValue}
        sx={{
          ...FALSE_SUBMISSION_STATUS_STYLE[data.cxStatus],
          padding: '0px 4px',
          width: 'fit-content',
          fontSize: '12px',
          fontWeight: 500,
          display: 'inline-block'
        }}
      >
        {statusValue}
      </TypographyTooltipClamp>
    </Box>
  )
}