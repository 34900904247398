import { Grid, List, ListItem, Typography } from '@opswat/react-ui'

interface SectionCardContentProps {
  icon: React.ReactNode
  title: string
  description?: string
  descriptions?: string[]
}

const SectionCardContent = ({ icon, title, description, descriptions }: SectionCardContentProps) => {
  return (
    <>
      <Grid item xs={12}>
        {icon}
      </Grid>

      <Grid item xs={12}>
        <Typography
          color={'#1B273C'}
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '24px'
          }}
        >
          {title}
        </Typography>
      </Grid>

      {description && (
        <Grid item xs={12}>
          <Typography color={'#1B273C'} variant="body2">
            {description}
          </Typography>
        </Grid>
      )}

      {descriptions && (
        <Grid item xs={12}>
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
              p: '0 0 0 12px'
            }}
          >
            {descriptions.map(desc => (
              <ListItem
                key={desc}
                sx={{ display: 'list-item', p: 0, fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}
              >
                {desc}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </>
  )
}

export default SectionCardContent
