import React, { useCallback, useEffect, useMemo } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _parseInt from 'lodash/parseInt'

import NiceModal from '@ebay/nice-modal-react'
import { SnackbarProvider } from 'notistack'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { withPermission } from './components/HOC/withPermission'

import { DIALOGS, KEYS } from '@myopswat/common'
import { getLocalStorage, removeLocalStorage, useTabFocus } from '@opswat/react-core'
import { Page404, ScrollToTop, SnackbarNotistack } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'

import {
  academyPageURL,
  activePageURL,
  authSuccessfullyURL,
  changePasswordPageURL,
  changePasswordSSOPageURL,
  confirmEmailPageURL,
  dialogURL,
  engineStatusLinuxPageURL,
  engineStatusPackagePageURL,
  engineStatusWindowsPageURL,
  homePageURL,
  licensedProductsFlexPageURL,
  licensedProductsPageURL,
  loginPageURL,
  logoutPageURL,
  mfaPageURL,
  myHomeDetailPageURL,
  myInformationFlexPageURL,
  myOrganizationFlexPageURL,
  noPermissionPageUrl,
  oldPaymentPageUrl,
  organizationAcceptInvitationURL,
  paymentPageURL,
  productDownloadsPageURL,
  registerPageURL,
  reportFalseDetectionDetailPageURL,
  reportFalseDetectionFlexPageURL,
  reportFalseDetectionPageURL,
  resendEmailPageURL,
  resetPasswordPageURL,
  supportServicesPageAllCasesURL,
  supportServicesPageURL,
  supportServicesSubmitCasePageURL,
  testIframePageURL,
  unsubscribedPageURL,
  verifyOTPPageURL
} from './routes'

import { oldUrlsMap } from './routes/handleRoutes'

import ActiveContainer from './containers/ActiveContainer'
import ChangePasswordContainer from './containers/ChangePasswordContainer'
import ConfirmEmailContainer from './containers/ConfirmEmailContainer'
import DialogContainer from './containers/DialogContainer'
import EngineStatusContainer from './containers/EngineStatusContainer'
import HomeContainer from './containers/HomeContainer'
import LayoutContainer from './containers/LayoutContainer'
import { toggleDialogs } from './containers/LayoutContainer/layoutContainerSlice'
import LicensedProductsContainer from './containers/LicensedProductsContainer'
import LoadingContainer from './containers/LoadingContainer'
import LoginContainer from './containers/LoginContainer'
import LogoutContainer from './containers/LogoutContainer'
import MFAContainer from './containers/MFAContainer'
import MyDetailContainer from './containers/MyDetailContainer'
import MyInformationContainer from './containers/MyInformationContainer'
import MyOrganizationContainer from './containers/MyOrganizationContainer'
import NoPermissionContainer from './containers/NoPermissionContainer'
import OldPageContainer from './containers/OldPageContainer'
import OrganizationAcceptInvitationContainer from './containers/OrganizationAcceptInvitationContainer'
import PaymentContainer from './containers/PaymentContainer'
import ProductDownloadsContainer from './containers/ProductDownloadsContainer'
import PublicLayoutContainer from './containers/PublicLayoutContainer'
import RegisterContainer from './containers/RegisterContainer'
import ReportFalseDetectionContainer from './containers/ReportFalseDetectionContainer'
import ResendEmailContainer from './containers/ResendEmailContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import SSOContainer from './containers/SSOContainer'
import SSOSuccessfullyContainer from './containers/SSOSuccessfullyContainer'
import SupportServicesContainer from './containers/SupportServicesContainer'
import SupportServicesAllCasesContainer from './containers/SupportServicesContainer/allCases'
import SupportSubmitCaseContainer from './containers/SupportSubmitCaseContainer'
import UnsubscribedContainer from './containers/UnsubscribedContainer'

import image404 from './assets/images/404-bg.png'
import imageBg from './assets/images/mfa-bg.png'

import AIModal from './components/AIModal'
import HelmetCore from './components/SEO/HelmetCore'

import { isFalseSubmissionDisabled, MATOMO_URL } from './constants'
import { DIALOGS_WEB } from './constants/dialogs'
import { setCurrentMyOrgs, setCurrentOrgs } from './containers/LicensedProductsContainer/licensedProductContainerSlice'
import ReportFalseDetectionDetailContainer from './containers/ReportFalseDetectionDetailContainer'
import TestIframeContainer from './containers/TestIframeContainer'
import VerifyOTPContainer from './containers/VerifyOTPContainer'
import { useAppDispatch, useTypedSelector } from './store'
import AcademyContainer from './containers/AcademyContainer'

interface RouteProps {
  path: string
  component?: React.ReactNode
  index?: boolean
  isHiding?: boolean
  layoutType?: string
}

const App = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const isHidingOrganizationPage = useMemo(() => {
    if (profileData)
      return _isEmpty(_get(profileData, 'currentOrganizationId') || _get(profileData, 'usingOrganizationId'))
    return false
  }, [profileData])

  const enableOldLogin = ['DEV', 'STAGING'].includes(process.env.REACT_APP_ENV ?? '')

  const ssoRoutes = [
    {
      path: '/',
      component: <LoadingContainer />,
      index: true
    },
    {
      path: loginPageURL,
      component: enableOldLogin ? <LoginContainer /> : <LoadingContainer />
    },
    {
      path: registerPageURL,
      component: <RegisterContainer />
    },
    {
      path: resetPasswordPageURL,
      component: <ResetPasswordContainer />
    },
    {
      path: changePasswordPageURL,
      component: <ChangePasswordContainer />
    },
    {
      path: confirmEmailPageURL,
      component: <ConfirmEmailContainer />
    },
    {
      path: resendEmailPageURL,
      component: <ResendEmailContainer />
    },
    {
      path: verifyOTPPageURL,
      component: <VerifyOTPContainer />
    },
    {
      path: changePasswordSSOPageURL
    },
    {
      path: activePageURL,
      component: <ActiveContainer />
    },
    {
      path: authSuccessfullyURL,
      component: <SSOSuccessfullyContainer />
    }
  ]

  const oldRoutes = Object.keys(oldUrlsMap)

  const pageRoutes = [
    {
      path: homePageURL,
      component: <HomeContainer />
    },
    {
      path: myInformationFlexPageURL,
      component: <MyInformationContainer />
    },
    {
      path: myHomeDetailPageURL,
      component: (
        <>
          {_get(profileData, 'enabledAi', false) && <AIModal />}
          <MyDetailContainer />
        </>
      )
    },
    {
      path: mfaPageURL,
      component: <MFAContainer />
    },
    {
      path: licensedProductsFlexPageURL,
      component: <LicensedProductsContainer />
    },
    {
      path: productDownloadsPageURL,
      component: <ProductDownloadsContainer />
    },
    {
      isHiding: isHidingOrganizationPage,
      path: myOrganizationFlexPageURL,
      component: <MyOrganizationContainer />
    },
    {
      path: engineStatusWindowsPageURL,
      component: <EngineStatusContainer />
    },
    {
      path: engineStatusLinuxPageURL,
      component: <EngineStatusContainer />
    },
    {
      path: engineStatusPackagePageURL,
      component: <EngineStatusContainer />
    },
    {
      path: noPermissionPageUrl,
      component: <NoPermissionContainer />
    },
    {
      path: dialogURL,
      component: <DialogContainer />
    },
    {
      path: supportServicesPageURL,
      component: <SupportServicesContainer />
    },
    {
      path: supportServicesPageAllCasesURL,
      component: <SupportServicesAllCasesContainer />
    },
    {
      path: supportServicesSubmitCasePageURL,
      component: <SupportSubmitCaseContainer />
    },
    {
      isHiding: isFalseSubmissionDisabled,
      path: reportFalseDetectionDetailPageURL,
      component: <ReportFalseDetectionDetailContainer />
    },
    {
      isHiding: isFalseSubmissionDisabled,
      path: reportFalseDetectionFlexPageURL,
      component: <ReportFalseDetectionContainer />
    },
    {
      isHiding: isFalseSubmissionDisabled,
      path: reportFalseDetectionPageURL,
      component: <ReportFalseDetectionContainer />
    },
    {
      path: academyPageURL,
      component: <AcademyContainer />
    }
    // {
    //   isHiding: !isEnabledPOCFeatures,
    //   path: iframeLicensedProductsFlexPageURL,
    //   component: <LicensedProductsContainer />
    // }
  ]

  const publicRoutes = [
    {
      path: paymentPageURL,
      component: <PaymentContainer />
    },
    {
      path: oldPaymentPageUrl,
      component: <PaymentContainer />
    },
    {
      path: organizationAcceptInvitationURL,
      component: <OrganizationAcceptInvitationContainer />
    },
    {
      path: unsubscribedPageURL,
      component: <UnsubscribedContainer />
    },
    enableOldLogin && {
      path: testIframePageURL,
      component: <TestIframeContainer />
    }
  ]

  const emptyLayoutRoutes = [
    {
      path: logoutPageURL,
      component: <LogoutContainer />,
      layoutType: 'LOGOUT'
    }
  ]

  const createScript = (id: string, scriptText: string, src: string | null, async: boolean) => {
    const script = document.createElement('script')

    if (id) {
      script.setAttribute('id', id)
    }

    if (src) {
      script.setAttribute('src', src)
    }

    if (async) {
      script.async = true
    }

    if (scriptText) {
      script.appendChild(document.createTextNode(scriptText))
    }

    try {
      document.head.appendChild(script)
    } catch (e) {
      script.text = scriptText
      document.head.appendChild(script)
    }
  }

  const noScriptForGTM = (id: string, url: string) => {
    const script = document.createElement('noscript')
    script.setAttribute('id', id)
    const x = document.createElement('iframe')
    x.setAttribute('src', url)
    x.setAttribute('width', '0')
    x.setAttribute('height', '0')
    x.setAttribute('style', 'display:none;visibility:hidden')
    script.appendChild(x)

    const body = document.getElementsByTagName('body')[0]
    body.insertBefore(script, body.firstChild)
  }

  const initGTM = (value: string) => {
    if (!value) {
      return false
    }

    const id = 'google-tag-manager'
    const scriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${value}');
    `
    const url = `https://www.googletagmanager.com/ns.html?id=${value}`
    noScriptForGTM(id, url)
    createScript(id, scriptText, null, false)

    return true
  }

  const initMatomo = useCallback(() => {
    const _mtm = (window._mtm = window._mtm || [])
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.async = true
    g.src = MATOMO_URL
    s.parentNode?.insertBefore(g, s)
  }, [])

  useEffect(() => {
    // Init Matomo
    initMatomo()

    // Init GTM
    const gtmId = process.env.REACT_APP_GTM_ID ?? ''
    initGTM(gtmId)

    const cleanup = useTabFocus(async () => {
      if (await getLocalStorage(KEYS.TOKEN_EXPIRED_TIME)) {
        const expireTime = await _parseInt(`${getLocalStorage(KEYS.TOKEN_EXPIRED_TIME)}`)
        const currentTime = await new Date().getTime()

        if (expireTime <= currentTime) {
          await dispatch(
            toggleDialogs({
              [DIALOGS.SESSION_TIMEOUT]: true,
              [DIALOGS_WEB.NPS_NOTIFICATION]: false,
              [DIALOGS_WEB.NPS_SURVEY]: false
            })
          )
          removeLocalStorage(KEYS.BOTPRESS_WEBCHAT)
          if (window.botpress?.initialized) {
            window.botpress.close()
          }
        }
      }
    })

    return cleanup
  }, [])

  const trackPageView = _debounce(() => {
    if (window._paq) {
      window._paq.push(['setCustomUrl', `${window.origin}${location.pathname}`])
      window._paq.push(['setDocumentTitle', document.title])
      window._paq.push(['trackPageView'])
    }
  }, 1000)

  useEffect(() => {
    trackPageView()
    // reset current customer orgs
    if (!location.pathname.includes(licensedProductsPageURL)) {
      dispatch(setCurrentOrgs([]))
      dispatch(setCurrentMyOrgs([]))
    }
  }, [location.pathname])

  return (
    <>
      <HelmetCore />

      <ScrollToTop />

      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        autoHideDuration={10000}
        maxSnack={500}
        Components={{
          success: SnackbarNotistack,
          info: SnackbarNotistack,
          error: SnackbarNotistack,
          warning: SnackbarNotistack
        }}
      >
        <NiceModal.Provider>
          <Routes>
            <Route element={<SSOContainer />}>
              {_map(ssoRoutes, (route: RouteProps) => {
                if (route.isHiding) return <></>
                return <Route key={route.path} index={route.index} path={route.path} element={route.component} />
              })}
            </Route>

            <Route element={<LayoutContainer />}>
              {_map(pageRoutes, (route: RouteProps) => {
                if (route.isHiding) return <></>
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={withPermission(route.component, route.path, location.pathname)}
                  />
                )
              })}
              {_map(oldRoutes, (route: string) => (
                <Route key={route} path={route} element={<OldPageContainer />} />
              ))}
            </Route>

            <Route element={<PublicLayoutContainer />}>
              {_map(publicRoutes, (route: RouteProps) => {
                if (route.isHiding) return <></>
                return <Route key={route.path} path={route.path} element={route.component} />
              })}
            </Route>

            {_map(emptyLayoutRoutes, (route: RouteProps) => {
              if (route.isHiding) return <></>
              return <Route key={route.path} path={route.path} element={route.component} />
            })}

            <Route
              path="*"
              element={
                <Page404
                  background={imageBg}
                  icon={<img src={image404} alt="opswat-404" />}
                  text="Sorry, we can't find that page! Don't worry though, everything is STILL AWESOME!"
                  textButton="Go Back Home"
                  propsButton={{ onClick: () => navigate(homePageURL) }}
                  propsTypo={{ color: 'white' }}
                />
              }
            />
          </Routes>
        </NiceModal.Provider>
      </SnackbarProvider>
    </>
  )
}

export default App
