import { Box, Typography } from '@opswat/react-ui'

interface IProps {
  title: React.ReactNode
  children: React.ReactNode
}

const AcademySection = ({ title, children }: IProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2.5
      }}
    >
      <Typography variant="body1" color="#1B273C">
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default AcademySection
