import { Grid, Typography } from '@opswat/react-ui'

import { RecommendCourse } from 'myopswat-web/src/api/academy/type'

import { EnrollCourses, ItemNotFoundMessage } from '../../components'

interface CoursesProps {
  courses: RecommendCourse[]
}

const Courses = ({ courses }: CoursesProps) => {
  return (
    <Grid item container xs={12} direction="column" gap={2.5}>
      <Typography
        color={'#1B273C'}
        sx={{
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '24px'
        }}
      >
        Explore Professional Courses
      </Typography>
      {courses.length > 0 ? <EnrollCourses courses={courses} /> : <ItemNotFoundMessage />}
    </Grid>
  )
}

export default Courses
