import { useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@opswat/react-icon'
import { Box, SwiperButton, SwiperSlider } from '@opswat/react-ui'

interface IProps {
  data: any[]
  component: any
  slidesToShow?: number
}

const AcademySwiper = ({ data, component, slidesToShow }: IProps) => {
  const [swiper, setSwiper] = useState<any>(null)

  return (
    <SwiperSlider
      contents={data}
      slidesToShow={slidesToShow}
      ContentComponent={component}
      propsStyleSwiper={{
        spaceBetween: 25,
        style: {
          padding: '4px 6px 32px 6px'
        }
      }}
      onSwiper={setSwiper}
      customControlButtons={
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '45%',
              left: '4px',
              transform: 'translate(-50%, -50%)',
              zIndex: 1201
            }}
          >
            <SwiperButton isButtonPrev leftIcon={<ChevronLeftIcon />} customSwiper={swiper} />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '45%',
              right: '-24px',
              transform: 'translate(-50%, -50%)',
              zIndex: 1201
            }}
          >
            <SwiperButton isButtonNext rightIcon={<ChevronRightIcon />} customSwiper={swiper} />
          </Box>
        </>
      }
    />
  )
}

export default AcademySwiper
