/** ----------------- PATHNAME -------------------- */
export const reportFalseDetectionSubmitPath = 'submit'
export const reportFalseDetectionHistoryPath = 'history'
export const reportFalseDetectionDetailPath = 'detail'
/** ----------------- ROUTE -------------------- */
export const reportFalseDetectionPageURL = '/report-false-detection'
export const reportFalseDetectionFlexPageURL = `${reportFalseDetectionPageURL}/:tabPath`
export const reportFalseDetectionSubmitPageURL = `${reportFalseDetectionPageURL}/${reportFalseDetectionSubmitPath}`
export const reportFalseDetectionHistoryPageURL = `${reportFalseDetectionPageURL}/${reportFalseDetectionHistoryPath}`
export const reportFalseDetectionDetailPageURL = `${reportFalseDetectionHistoryPageURL}/:submissionId/${reportFalseDetectionDetailPath}`
