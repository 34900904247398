import { Box, Typography } from '@opswat/react-ui'

interface StatisticCardProps {
  title: string
  value: string
  description: string
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, value, description }) => {
  return (
    <Box
      p={{ xs: '10px 20px', lg: '10px 20px 10px 30px' }}
      sx={{
        backgroundColor: '#E1E9FE',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
      }}
    >
      <Typography color="#485161" variant="body2" sx={{ lineHeight: '20px' }}>
        {title}
      </Typography>

      <Typography color="#141E2F" sx={{ fontSize: '32px', fontWeight: 700, lineHeight: '36px' }}>
        {value}
      </Typography>

      <Typography color="#485161" variant="subtitle2" sx={{ lineHeight: '20px' }}>
        {description}
      </Typography>
    </Box>
  )
}

export default StatisticCard
