import { Box, Button, Grid, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { RecommendCourse } from 'myopswat-web/src/api/academy/type'
import { openInNewTab } from 'myopswat-web/src/utils'
import { courseBackground } from '../constants'

const CourseCard = ({
  courseName,
  description,
  lessons,
  duration,
  courseUrl,
  imageCourseUrl,
  isEnroll
}: RecommendCourse) => {
  const handleEnrollClick = () => {
    openInNewTab(courseUrl)
  }

  return (
    <Grid
      container
      sx={{
        background: '#FFF',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)'
      }}
    >
      <Grid item xs={12} md={3} container justifyContent="center" alignItems="center">
        <Box
          component="img"
          src={imageCourseUrl}
          alt={courseName}
          sx={{
            backgroundImage: `url("data:image/svg+xml,${courseBackground}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '204px',
            objectFit: 'contain',
            padding: '20px'
          }}
        />
      </Grid>

      <Grid item container xs={12} md direction="column" gap={1.5} p={2.5} justifyContent="space-between">
        <Typography
          color="#1B273C"
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px'
          }}
        >
          {courseName}
        </Typography>
        <TypographyLineClamp
          line={3}
          tooltipValue={description}
          tooltipPlacement="top"
          color="#141E2F"
          style={{
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '20px',
            wordBreak: 'keep-all'
          }}
        >
          {description}
        </TypographyLineClamp>

        <Grid item container alignItems="center" gap="4px">
          <Typography color="#707682" sx={{ fontSize: '10px', fontWeight: 400, lineHeight: '16px' }}>
            {lessons}
          </Typography>
          <Box component="span" sx={{ width: 3, height: 3, bgcolor: '#707682', borderRadius: '50%' }} />
          <Typography color="#707682" sx={{ fontSize: '10px', fontWeight: 400, lineHeight: '16px' }}>
            {duration}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleEnrollClick}
            sx={{
              minWidth: '100px'
            }}
          >
            {isEnroll ? 'Continue' : 'Enroll'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CourseCard
