import { useCallback } from 'react'

import { BruteForce, ExcellenceIcon, ScalableIcon } from '@opswat/react-icon'
import { Box, Button, Grid } from '@opswat/react-ui'

import AcademyBanner from 'myopswat-web/src/assets/images/academy_banner.svg'
import { openInNewTab } from 'myopswat-web/src/utils'

import { SectionCard, SectionCardContent } from '../../components'

const LEARN_MORE_URL =
  'https://integration-static.opswatacademy.com/OPSWAT-Academy-Business-Subscriptions/OPSWAT-Academy-Business-Subscriptions.pdf'
const CONTACT_SALES_URL = 'https://opswatacademy.com/contact-us'

const EnterpriseSubscription = () => {
  const handleClickLearnMore = useCallback(() => {
    openInNewTab(LEARN_MORE_URL)
  }, [])

  const handleClickContactSales = useCallback(() => {
    openInNewTab(CONTACT_SALES_URL)
  }, [])

  return (
    <SectionCard>
      <SectionCardContent
        icon={<ScalableIcon />}
        title="OPSWAT Academy Pro Subscription"
        description="The OPSWAT Academy Pro Subscription provides unlimited access to a comprehensive training platform designed to enhance the adoption and management of OPSWAT solutions. With courses spanning Associate, Expert, and Professional levels, this subscription equips staff and administrators with the skills needed to deploy, maintain, and optimize OPSWAT products effectively."
      />
      <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }, gap: { xs: 1.5 } }}>
        <Grid item xs={12} md={6} container gap={1.5}>
          <SectionCardContent
            icon={<ExcellenceIcon />}
            title="Challenges of Managing a New Solution"
            descriptions={[
              `Vulnerability Issues when switching between security systems.`,
              `Unprepared administrators due to network or site changes.`,
              `Lack of properly trained staff, impacting productivity.`,
              `Inability to utilize the product's full potential.`,
              `Troubleshooting challenges, delaying issue resolution.`
            ]}
          />
          <SectionCardContent
            icon={<BruteForce />}
            title="Your Path Toward OPSWAT Product Mastery Starts Here"
            descriptions={[
              `Fully online - No infrastructure requirements`,
              `Learn at your own pace`,
              `Accessible 24/7`,
              `Get certified in days, not months`
            ]}
          />

          <Grid container spacing={1} pl={1.5}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClickLearnMore}>
                Learn More
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleClickContactSales}>
                Contact Sales
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md justifyContent="flex-end" alignItems="center">
          <Box width="100%" sx={{ maxWidth: { xs: '100%', md: '500px' } }} height="auto">
            <img
              src={AcademyBanner}
              alt="Academy Banner"
              style={{ width: '100%', height: 'auto', objectFit: 'contain', maxWidth: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </SectionCard>
  )
}

export default EnterpriseSubscription
