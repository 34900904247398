import { ReactNode } from 'react'

import { Grid } from '@opswat/react-ui'

interface CardProps {
  children: ReactNode
}

const SectionCard = ({ children }: CardProps) => {
  return (
    <Grid
      container
      direction="column"
      gap={2}
      sx={{
        padding: '30px 30px 40px 30px',
        alignSelf: 'stretch',
        background: '#FFF',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)'
      }}
    >
      {children}
    </Grid>
  )
}

export default SectionCard
