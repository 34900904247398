import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

import { Box, FormControlLabel, Grid, Radio, RadioGroup, TableAdvanced, TextField, Typography } from '@opswat/react-ui'
import { useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { IPortalPermission } from 'myopswat-web/src/api/permission/types'
import { ICreateUpdatePortalRoleForm, IPermission, IRoleObject, IRoleObjectForm } from 'myopswat-web/src/api/role/types'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from './RoleSchema'
import './styles.css'

const PERMISSION_NAMES = ['None', 'View Only', 'Full Access']

interface IProps {
  formRef: UseFormReturn<ICreateUpdatePortalRoleForm>
  globalPortalPermissions: IPortalPermission[]
  defaultRoleObjects: IRoleObject[]
  isTableLoading: boolean
  onSelectPermission: (roleObject: IRoleObjectForm) => IRoleObjectForm[]
  viewOnly?: boolean
  organization: any
  isSingleOrg?: boolean
}

const CreateUpdateRoleForm = ({
  formRef,
  globalPortalPermissions,
  defaultRoleObjects,
  isTableLoading,
  onSelectPermission,
  viewOnly,
  organization,
  isSingleOrg
}: IProps) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue
  } = formRef

  const [inputLength, setInputLength] = useState({ name: 0, description: 0 })

  const loadCheckedValue = (
    roleObjectForms: IRoleObjectForm[],
    defaultRoleObjectId: string,
    currentPermission: IPermission
  ) => {
    return roleObjectForms.some(
      roleObjectForm =>
        roleObjectForm.id === defaultRoleObjectId &&
        roleObjectForm.selectedPermissions.some(p => p === currentPermission.id)
    )
  }

  const renderFancyPermissionHeaders = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%'
        }}
      >
        {PERMISSION_NAMES.map(permName => (
          <Typography
            key={permName}
            variant="body2"
            sx={{
              textAlign: 'center',
              flex: 1,
              fontWeight: 500
            }}
          >
            {permName}
          </Typography>
        ))}
      </Box>
    )
  }

  const columnArray = useMemo(() => {
    return [
      {
        key: 'name',
        header: (
          <Typography variant="body2" sx={{ paddingLeft: 2, fontWeight: 500 }}>
            Permissions
          </Typography>
        ),
        body: (data: IRoleObject) => (
          <Typography variant="body2" sx={{ paddingLeft: 2 }}>
            {_get(data, 'name', '--')}
          </Typography>
        ),
        style: {
          minWidth: 300,
          width: 300
        }
      },
      {
        key: 'permissions',
        header: renderFancyPermissionHeaders(),
        body: (data: IRoleObject) => {
          const defaultRoleObjectId = _get(data, 'id')
          const defaultRoleObjectPermissions = _get(data, 'permissions', [])
          return (
            <Controller
              name="roleObjects"
              control={control}
              render={({ field }) => (
                <RadioGroup row sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                  {globalPortalPermissions.map((permission: IPermission) => {
                    const isGlobalPermissionInDefault = defaultRoleObjectPermissions.find(p => p.id === permission.id)
                    return (
                      <FormControlLabel
                        key={defaultRoleObjectId + permission.id}
                        disabled={viewOnly}
                        checked={loadCheckedValue(field.value, defaultRoleObjectId, permission)}
                        sx={{
                          visibility: isGlobalPermissionInDefault ? 'visible' : 'hidden'
                        }}
                        control={<Radio size="medium" />}
                        label={<></>}
                        onChange={() => {
                          field.onChange(
                            onSelectPermission({
                              id: defaultRoleObjectId,
                              selectedPermissions: [permission.id]
                            })
                          )
                        }}
                      />
                    )
                  })}
                </RadioGroup>
              )}
            />
          )
        },
        style: { minWidth: 'auto', textAlign: 'left', paddingTop: '0 !important', paddingBottom: '0 !important' }
      }
    ]
  }, [control, onSelectPermission])

  const sortedRoles = useMemo(() => {
    return _sortBy(defaultRoleObjects, item => {
      return Array.from(_get(item, 'name', '--')).map(char => {
        if (/\d/.test(char)) return `0${char}`
        if (/[a-z]/.test(char)) return `1${char}`
        if (/[A-Z]/.test(char)) return `2${char}`
        return `3${char}`
      })
    })
  }, [defaultRoleObjects])

  useEffect(() => {
    const { unsubscribe } = watch(value => {
      setTimeout(() => {
        const result: any = {}
        Object.keys(value).forEach(val => {
          result[val] = _get(value, val, '').length
        })
        setInputLength(result)
      }, 5)
    })
    return () => unsubscribe()
  }, [watch])

  return (
    <Grid id="role-crud-box" container spacing={2}>
      {!watch('id') && !isSingleOrg && (
        <Grid item xs={12}>
          <Typography variant="body2">
            You are adding new role into current organization <strong>{_get(organization, 'name', '--')}</strong>.
          </Typography>
          <Typography variant="body2">
            To add new role to a different organization, please switch your current organization first.
          </Typography>
        </Grid>
      )}

      {!isSingleOrg && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1" marginBottom={0.5}>
              Organization <span style={{ color: '#D00300' }}>*</span>
            </Typography>
            <TextField fullWidth size="small" value={_get(organization, 'name', '--')} disabled />
          </Box>
        </Grid>
      )}
      
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Name*</Typography>
            <Typography variant="subtitle2">
              {inputLength.name}/{MAX_NAME_LENGTH}
            </Typography>
          </Box>
          <TextField
            required
            fullWidth
            disabled={viewOnly}
            size="small"
            {...register(`name`)}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            onInput={(evt: any) => {
              const newValue = evt.target.value
              setValue('name', newValue.length > MAX_NAME_LENGTH ? newValue.slice(0, MAX_NAME_LENGTH) : newValue)
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">Description</Typography>
            <Typography variant="subtitle2">
              {inputLength.description}/{MAX_DESCRIPTION_LENGTH}
            </Typography>
          </Box>

          <TextField
            required
            fullWidth
            disabled={viewOnly}
            size="small"
            multiline
            minRows={3}
            {...register(`description`)}
            error={!!errors?.description}
            helperText={errors?.description?.message}
            onChange={evt => {
              const newValue = evt.target.value
              setValue(
                'description',
                newValue.length > MAX_DESCRIPTION_LENGTH ? newValue.slice(0, MAX_DESCRIPTION_LENGTH) : newValue
              )
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableAdvanced
            boxMinHeight="0px"
            boxMaxHeight="325px"
            columns={columnArray}
            isLoading={isTableLoading}
            data={sortedRoles}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default CreateUpdateRoleForm
