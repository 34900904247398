import { Grid, Link, Typography } from '@opswat/react-ui'
import { supportServicesPageURL } from 'myopswat-web/src/routes'
import { useNavigate } from 'react-router-dom'

const ItemNotFoundMessage = () => {
  const navigate = useNavigate()

  const handleSupportClick = () => {
    navigate(supportServicesPageURL)
  }

  return (
    <Grid item container xs={12} direction="column" alignItems="center">
      <Typography variant="subtitle2" sx={{ lineHeight: '20px', color: '#1B273C' }}>
        Oops! It looks like we're having trouble retrieving the information right now.
      </Typography>
      <Typography variant="subtitle2" sx={{ lineHeight: '20px', color: '#1B273C' }}>
        Please try refreshing the page in a few minutes.
      </Typography>
      <Typography variant="subtitle2" sx={{ lineHeight: '20px', color: '#1B273C' }}>
        If the issue persists, feel free to contact our{' '}
        <Link onClick={handleSupportClick} sx={{ color: '#1D6BFC', cursor: 'pointer' }}>
          Support
        </Link>{' '}
        team for help.
      </Typography>
    </Grid>
  )
}

export default ItemNotFoundMessage
