export const backgroundSvg = encodeURIComponent(`
    <svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="url(#paint0_linear_41299_70617)" />
        <g opacity="0.1">
          <mask id="mask0_41299_70617" maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
            <rect width="100%" height="100%" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_41299_70617)">
            <g clip-path="url(#clip0_41299_70617)">
              <path
                d="M116.5 200.9C116.8 200.9 117 200.8 117.2 200.6L124.3 193.5C124.6 193.5 125 193.4 125.2 193.1L126.2 192.1L127.5 190.8L135.9 182.4C135.9 182.4 135.9 182.4 136 182.4C136.5 182.4 137 182.2 137.4 181.8L138.4 180.8L191.2 128C191.6 127.6 191.8 127.1 191.8 126.5L201.2 117.1L211.1 107.2C211.6 106.7 211.6 105.9 211.2 105.4L619.9 -303.3L619.2 -304L210.3 104.9C209.9 104.9 209.5 105 209.2 105.3L189.9 124.6C189.4 124.6 188.8 124.8 188.4 125.2L181.1 132.5L134.6 179C134.2 179.4 134 179.9 134 180.5L124.3 190.2L123.3 191.2C123 191.5 122.9 191.8 122.9 192.1L116.8 198.2L115.8 199.2C115.7 199.3 115.6 199.5 115.6 199.6L-105.3 420.5L-104.6 421.2L116 200.7C116.1 200.8 116.3 200.9 116.5 200.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M559.27 -364.078L-165.295 360.488L-164.588 361.195L559.977 -363.37L559.27 -364.078Z"
                fill="#1D6BFC"
              />
              <path
                d="M553.271 -370.079L-171.294 354.486L-170.587 355.193L553.978 -369.372L553.271 -370.079Z"
                fill="#1D6BFC"
              />
              <path
                d="M547.272 -376.081L-177.293 348.484L-176.586 349.191L547.979 -375.374L547.272 -376.081Z"
                fill="#1D6BFC"
              />
              <path
                d="M541.273 -382.083L-183.292 342.482L-182.585 343.189L541.98 -381.376L541.273 -382.083Z"
                fill="#1D6BFC"
              />
              <path
                d="M535.274 -388.085L-189.292 336.48L-188.585 337.187L535.981 -387.378L535.274 -388.085Z"
                fill="#1D6BFC"
              />
              <path
                d="M529.204 -394.017L-195.362 330.549L-194.654 331.256L529.911 -393.31L529.204 -394.017Z"
                fill="#1D6BFC"
              />
              <path
                d="M523.205 -400.019L-201.361 324.547L-200.653 325.254L523.912 -399.311L523.205 -400.019Z"
                fill="#1D6BFC"
              />
              <path
                d="M517.206 -406.021L-207.36 318.545L-206.653 319.252L517.913 -405.313L517.206 -406.021Z"
                fill="#1D6BFC"
              />
              <path
                d="M511.206 -412.022L-213.359 312.543L-212.652 313.25L511.914 -411.315L511.206 -412.022Z"
                fill="#1D6BFC"
              />
              <path
                d="M505.208 -418.024L-219.358 306.541L-218.651 307.248L505.915 -417.317L505.208 -418.024Z"
                fill="#1D6BFC"
              />
              <path
                d="M499.209 -424.027L-225.356 300.539L-224.649 301.246L499.916 -423.32L499.209 -424.027Z"
                fill="#1D6BFC"
              />
              <path
                d="M493.21 -430.029L-231.356 294.537L-230.649 295.244L493.917 -429.321L493.21 -430.029Z"
                fill="#1D6BFC"
              />
              <path
                d="M487.21 -436.031L-237.355 288.535L-236.648 289.242L487.917 -435.324L487.21 -436.031Z"
                fill="#1D6BFC"
              />
              <path
                d="M481.211 -442.033L-243.354 282.533L-242.647 283.24L481.918 -441.326L481.211 -442.033Z"
                fill="#1D6BFC"
              />
              <path
                d="M475.212 -448.035L-249.353 276.53L-248.646 277.238L475.919 -447.328L475.212 -448.035Z"
                fill="#1D6BFC"
              />
              <path
                d="M469.213 -454.037L-255.352 270.528L-254.645 271.236L469.92 -453.33L469.213 -454.037Z"
                fill="#1D6BFC"
              />
              <path
                d="M463.214 -460.039L-261.352 264.527L-260.644 265.234L463.921 -459.332L463.214 -460.039Z"
                fill="#1D6BFC"
              />
              <path
                d="M457.215 -466.041L-267.351 258.525L-266.643 259.232L457.922 -465.334L457.215 -466.041Z"
                fill="#1D6BFC"
              />
              <path
                d="M451.216 -472.043L-273.35 252.523L-272.643 253.23L451.923 -471.336L451.216 -472.043Z"
                fill="#1D6BFC"
              />
              <path
                d="M445.146 -478.115L-279.419 246.45L-278.712 247.157L445.853 -477.408L445.146 -478.115Z"
                fill="#1D6BFC"
              />
              <path
                d="M439.147 -484.117L-285.418 240.448L-284.711 241.155L439.854 -483.41L439.147 -484.117Z"
                fill="#1D6BFC"
              />
              <path
                d="M433.148 -490.119L-291.418 234.446L-290.711 235.153L433.855 -489.412L433.148 -490.119Z"
                fill="#1D6BFC"
              />
              <path
                d="M427.149 -496.121L-297.417 228.444L-296.71 229.151L427.856 -495.414L427.149 -496.121Z"
                fill="#1D6BFC"
              />
              <path
                d="M421.15 -502.123L-303.416 222.442L-302.709 223.149L421.857 -501.416L421.15 -502.123Z"
                fill="#1D6BFC"
              />
              <path
                d="M415.151 -508.125L-309.415 216.44L-308.708 217.147L415.858 -507.418L415.151 -508.125Z"
                fill="#1D6BFC"
              />
              <path
                d="M409.151 -514.127L-315.414 210.438L-314.707 211.145L409.858 -513.42L409.151 -514.127Z"
                fill="#1D6BFC"
              />
              <path
                d="M403.152 -520.129L-321.413 204.436L-320.706 205.143L403.859 -519.422L403.152 -520.129Z"
                fill="#1D6BFC"
              />
              <path
                d="M397.153 -526.131L-327.412 198.434L-326.705 199.141L397.86 -525.424L397.153 -526.131Z"
                fill="#1D6BFC"
              />
              <path
                d="M391.154 -532.133L-333.411 192.432L-332.704 193.139L391.861 -531.426L391.154 -532.133Z"
                fill="#1D6BFC"
              />
              <path
                d="M385.155 -538.135L-339.41 186.43L-338.703 187.137L385.862 -537.428L385.155 -538.135Z"
                fill="#1D6BFC"
              />
              <path
                d="M379.156 -544.137L-345.41 180.428L-344.703 181.135L379.863 -543.43L379.156 -544.137Z"
                fill="#1D6BFC"
              />
              <path
                d="M373.157 -550.139L-351.409 174.426L-350.702 175.133L373.864 -549.432L373.157 -550.139Z"
                fill="#1D6BFC"
              />
              <path
                d="M367.158 -556.141L-357.408 168.424L-356.701 169.131L367.865 -555.434L367.158 -556.141Z"
                fill="#1D6BFC"
              />
              <path
                d="M361.159 -562.143L-363.407 162.422L-362.7 163.129L361.866 -561.436L361.159 -562.143Z"
                fill="#1D6BFC"
              />
              <path d="M1069.7 146.465L345.139 871.03L345.846 871.738L1070.41 147.172L1069.7 146.465Z" fill="#1D6BFC" />
              <path d="M1063.7 140.463L339.14 865.029L339.847 865.736L1064.41 141.17L1063.7 140.463Z" fill="#1D6BFC" />
              <path
                d="M1057.71 134.461L333.141 859.026L333.848 859.734L1058.41 135.168L1057.71 134.461Z"
                fill="#1D6BFC"
              />
              <path
                d="M1051.71 128.459L327.141 853.025L327.848 853.732L1052.41 129.166L1051.71 128.459Z"
                fill="#1D6BFC"
              />
              <path
                d="M1045.71 122.457L321.142 847.023L321.849 847.73L1046.41 123.164L1045.71 122.457Z"
                fill="#1D6BFC"
              />
              <path
                d="M1039.64 116.385L315.073 840.95L315.78 841.657L1040.34 117.092L1039.64 116.385Z"
                fill="#1D6BFC"
              />
              <path
                d="M1033.64 110.383L309.073 834.948L309.78 835.655L1034.35 111.09L1033.64 110.383Z"
                fill="#1D6BFC"
              />
              <path
                d="M1027.64 104.381L303.074 828.946L303.782 829.653L1028.35 105.088L1027.64 104.381Z"
                fill="#1D6BFC"
              />
              <path
                d="M1021.64 98.3791L297.075 822.944L297.782 823.651L1022.35 99.0862L1021.64 98.3791Z"
                fill="#1D6BFC"
              />
              <path
                d="M1015.64 92.3765L291.076 816.942L291.783 817.649L1016.35 93.0836L1015.64 92.3765Z"
                fill="#1D6BFC"
              />
              <path
                d="M1009.64 86.3747L285.077 810.94L285.784 811.647L1010.35 87.0818L1009.64 86.3747Z"
                fill="#1D6BFC"
              />
              <path
                d="M1003.64 80.3726L279.078 804.938L279.785 805.645L1004.35 81.0797L1003.64 80.3726Z"
                fill="#1D6BFC"
              />
              <path
                d="M997.644 74.3708L273.079 798.936L273.786 799.643L998.351 75.0779L997.644 74.3708Z"
                fill="#1D6BFC"
              />
              <path
                d="M991.645 68.3687L267.08 792.934L267.787 793.641L992.352 69.0758L991.645 68.3687Z"
                fill="#1D6BFC"
              />
              <path
                d="M985.646 62.3669L261.081 786.932L261.788 787.639L986.353 63.074L985.646 62.3669Z"
                fill="#1D6BFC"
              />
              <path
                d="M979.647 56.3648L255.082 780.93L255.789 781.637L980.354 57.0719L979.647 56.3648Z"
                fill="#1D6BFC"
              />
              <path d="M973.648 50.363L249.083 774.928L249.79 775.635L974.355 51.0701L973.648 50.363Z" fill="#1D6BFC" />
              <path
                d="M967.649 44.3609L243.083 768.926L243.79 769.633L968.356 45.068L967.649 44.3609Z"
                fill="#1D6BFC"
              />
              <path
                d="M961.65 38.3591L237.084 762.924L237.791 763.631L962.357 39.0662L961.65 38.3591Z"
                fill="#1D6BFC"
              />
              <path d="M955.65 32.357L231.085 756.922L231.792 757.629L956.357 33.0641L955.65 32.357Z" fill="#1D6BFC" />
              <path d="M949.651 26.355L225.086 750.92L225.793 751.627L950.358 27.0621L949.651 26.355Z" fill="#1D6BFC" />
              <path
                d="M943.652 20.3533L219.087 744.919L219.794 745.626L944.359 21.0604L943.652 20.3533Z"
                fill="#1D6BFC"
              />
              <path
                d="M937.653 14.3509L213.088 738.916L213.795 739.623L938.361 15.058L937.653 14.3509Z"
                fill="#1D6BFC"
              />
              <path
                d="M931.654 8.34857L207.089 732.914L207.796 733.621L932.361 9.05567L931.654 8.34857Z"
                fill="#1D6BFC"
              />
              <path d="M925.655 2.3465L201.09 726.912L201.797 727.619L926.362 3.0536L925.655 2.3465Z" fill="#1D6BFC" />
              <path
                d="M919.656 -3.65546L195.091 720.91L195.798 721.617L920.363 -2.94836L919.656 -3.65546Z"
                fill="#1D6BFC"
              />
              <path
                d="M913.657 -9.65741L189.092 714.908L189.799 715.615L914.364 -8.95031L913.657 -9.65741Z"
                fill="#1D6BFC"
              />
              <path
                d="M907.658 -15.6594L183.093 708.906L183.8 709.613L908.365 -14.9523L907.658 -15.6594Z"
                fill="#1D6BFC"
              />
              <path
                d="M901.659 -21.6613L177.093 702.904L177.8 703.611L902.366 -20.9542L901.659 -21.6613Z"
                fill="#1D6BFC"
              />
              <path
                d="M895.66 -27.6633L171.094 696.902L171.801 697.609L896.367 -26.9562L895.66 -27.6633Z"
                fill="#1D6BFC"
              />
              <path
                d="M889.661 -33.6652L165.095 690.9L165.802 691.607L890.368 -32.9581L889.661 -33.6652Z"
                fill="#1D6BFC"
              />
              <path
                d="M518.3 219.3C518.6 219.3 518.8 219.2 519 219L523.6 214.4C523.8 214.4 523.9 214.5 524.1 214.5C524.6 214.5 525.1 214.3 525.5 213.9L584.7 154.7C585.1 154.3 585.3 153.8 585.3 153.2L597.8 140.7C598.1 140.4 598.2 140 598.2 139.6L605.3 132.5C605.5 132.3 605.6 132 605.5 131.7L830.3 -93.1L829.6 -93.8L604.8 131.1C604.5 131.1 604.2 131.1 604 131.3L596.9 138.4C596.5 138.4 596.1 138.5 595.8 138.8L583.3 151.3C582.8 151.3 582.2 151.5 581.8 151.9L522.6 211.1C522.1 211.6 521.9 212.4 522.1 213L517.5 217.6C517.2 217.9 517.2 218.2 517.3 218.6L105.1 630.9L105.8 631.6L518.1 219.3C518.2 219.3 518.3 219.3 518.3 219.3Z"
                fill="#1D6BFC"
              />
              <path
                d="M508.5 241.1C508.8 241.1 509 241 509.2 240.8L513.8 236.2C514 236.2 514.1 236.3 514.3 236.3C514.8 236.3 515.3 236.1 515.7 235.7L583.6 167.8C584 167.4 584.2 166.9 584.2 166.3L599.9 150.6C600.2 150.3 600.3 149.9 600.3 149.5L607.4 142.4C607.6 142.2 607.7 141.9 607.6 141.6L836.3 -87.1L835.6 -87.8L606.9 141.1C606.6 141.1 606.3 141.1 606.1 141.3L599 148.4C598.6 148.4 598.2 148.5 597.9 148.8L582.2 164.5C581.7 164.5 581.1 164.7 580.7 165.1L512.8 233C512.3 233.5 512.1 234.3 512.3 234.9L507.7 239.5C507.4 239.8 507.4 240.1 507.5 240.5L111.1 636.9L111.8 637.6L508.3 241.1C508.4 241.1 508.5 241.1 508.5 241.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M374.7 387C375 387 375.2 386.9 375.4 386.7L380 382.1C380.2 382.1 380.3 382.2 380.5 382.2C381 382.2 381.5 382 381.9 381.6L583 180.5C583.4 180.1 583.6 179.6 583.6 179L598.8 163.8C599.1 163.5 599.2 163.1 599.2 162.7L606.3 155.6C606.5 155.4 606.6 155.1 606.5 154.8L842.3 -81L841.6 -81.7L605.8 154.2C605.5 154.2 605.2 154.2 605 154.4L597.9 161.5C597.5 161.5 597.1 161.6 596.8 161.9L581.6 177.1C581.1 177.1 580.5 177.3 580.1 177.7L379.1 378.8C378.6 379.3 378.4 380.1 378.6 380.7L374 385.3C373.7 385.6 373.7 385.9 373.8 386.3L117.1 642.9L117.8 643.6L374.5 386.9C374.5 386.9 374.6 387 374.7 387Z"
                fill="#1D6BFC"
              />
              <path
                d="M385.7 388C386 388 386.2 387.9 386.4 387.7L391 383.1C391.2 383.1 391.3 383.2 391.5 383.2C392 383.2 392.5 383 392.9 382.6L581.5 194C581.9 193.6 582.1 193.1 582.1 192.5L598.1 176.5C598.4 176.2 598.5 175.8 598.5 175.4L605.6 168.3C605.8 168.1 605.9 167.8 605.8 167.5L848.3 -75L847.6 -75.7L605.2 166.8C604.9 166.8 604.6 166.8 604.4 167L597.3 174.1C596.9 174.1 596.5 174.2 596.2 174.5L580.2 190.5C579.7 190.5 579.1 190.7 578.7 191.1L390 379.8C389.5 380.3 389.3 381.1 389.5 381.7L384.9 386.3C384.6 386.6 384.6 386.9 384.7 387.3L123.1 648.9L123.8 649.6L385.4 388C385.5 388 385.6 388 385.7 388Z"
                fill="#1D6BFC"
              />
              <path
                d="M415.3 382.4C415.6 382.4 415.8 382.3 416 382.1L420.6 377.5C420.8 377.5 420.9 377.6 421.1 377.6C421.6 377.6 422.1 377.4 422.5 377L574.9 224.6C575.3 224.2 575.5 223.7 575.5 223.1L594.4 204.2C594.7 203.9 594.8 203.5 594.8 203.1L601.9 196C602.1 195.8 602.2 195.5 602.1 195.2L860.4 -63.1L859.7 -63.8L601.3 194.7C601 194.7 600.7 194.7 600.5 194.9L593.4 202C593 202 592.6 202.1 592.3 202.4L573.4 221.3C572.9 221.3 572.3 221.5 571.9 221.9L419.7 374.2C419.2 374.7 419 375.5 419.2 376.1L414.6 380.7C414.3 381 414.3 381.3 414.4 381.7L135.1 660.9L135.8 661.6L415.1 382.3C415.1 382.4 415.2 382.4 415.3 382.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M433 376.7C433.3 376.7 433.5 376.6 433.7 376.4L436.7 373.4C437 373.4 437.4 373.3 437.6 373L438.7 371.9C439.2 371.9 439.7 371.7 440.1 371.3L568.6 243C569 242.6 569.2 242.1 569.2 241.5L590.1 220.6C590.4 220.3 590.5 219.9 590.5 219.5L597.6 212.4C597.8 212.2 597.9 211.9 597.8 211.6L866.4 -57L865.7 -57.7L597 211C596.7 211 596.4 211 596.2 211.2L589.1 218.3C588.7 218.3 588.3 218.4 588 218.7L567.1 239.6C566.6 239.6 566 239.8 565.6 240.2L437.3 368.5C436.9 368.9 436.7 369.4 436.7 370L435.6 371.1C435.3 371.4 435.2 371.7 435.2 372L432.2 375C431.9 375.3 431.9 375.6 432 376L141.1 666.9L141.8 667.6L432.7 376.7C432.8 376.7 432.9 376.7 433 376.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M475.9 358.1C476.3 358.1 476.6 358 476.9 357.7L490.9 343.7C490.9 343.7 490.9 343.7 491 343.7C491.5 343.7 492 343.5 492.4 343.1L538.4 297.1C538.8 296.7 539 296.2 539 295.6L573.5 261.1C573.8 260.8 573.9 260.4 573.9 260L581 252.9C581.2 252.7 581.3 252.4 581.2 252.1L878.4 -45.1L877.7 -45.8L580.5 251.5C580.2 251.5 579.9 251.5 579.7 251.7L572.6 258.8C572.2 258.8 571.8 258.9 571.5 259.2L537 293.8C536.5 293.8 535.9 294 535.5 294.4L489.5 340.4C489.1 340.8 488.9 341.3 488.9 341.9L474.9 355.9C474.5 356.3 474.4 356.9 474.6 357.4L153.1 678.9L153.8 679.6L475.4 358C475.6 358.1 475.7 358.1 475.9 358.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M454.3 367.8C454.7 367.8 455 367.7 455.3 367.4L460.3 362.4C460.8 362.4 461.3 362.2 461.7 361.8L558.3 265.2C558.7 264.8 558.9 264.3 558.9 263.7L583.7 238.9C584 238.6 584.1 238.2 584.1 237.8L591.2 230.7C591.4 230.5 591.5 230.2 591.4 229.9L872.3 -51L871.6 -51.7L590.7 229.3C590.4 229.3 590.1 229.3 589.9 229.5L582.8 236.6C582.4 236.6 582 236.7 581.7 237L557 261.8C556.5 261.8 555.9 262 555.5 262.4L458.9 359C458.5 359.4 458.3 359.9 458.3 360.5L453.3 365.5C452.9 365.9 452.8 366.5 453 367L147 673L147.7 673.7L453.7 367.7C453.9 367.7 454.1 367.8 454.3 367.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M347.1 378.9C347.5 378.9 347.8 378.8 348.1 378.5L390.3 336.3C390.7 335.9 390.8 335.3 390.6 334.8L522.5 202.9C522.6 202.9 522.7 202.9 522.7 202.9C523 202.9 523.2 202.8 523.4 202.6L528 198C528.2 198 528.3 198.1 528.5 198.1C529 198.1 529.5 197.9 529.9 197.5L582.7 144.7C583.1 144.3 583.3 143.8 583.3 143.2L595.8 130.7C596.1 130.4 596.2 130 596.2 129.6L603.3 122.5C603.5 122.3 603.6 122 603.5 121.7L824.3 -98.9L823.6 -99.6L602.8 121.1C602.5 121.1 602.2 121.1 602 121.3L594.9 128.4C594.5 128.4 594.1 128.5 593.8 128.8L581.3 141.3C580.8 141.3 580.2 141.5 579.8 141.9L527 194.7C526.5 195.2 526.3 196 526.5 196.6L521.9 201.2C521.6 201.5 521.6 201.8 521.7 202.2L389.9 334.1C389.4 333.9 388.8 334 388.4 334.4L346.2 376.6C345.8 377 345.7 377.6 345.9 378.1L99.1001 624.9L99.8001 625.6L346.6 378.8C346.8 378.9 346.9 378.9 347.1 378.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M506.6 339.5C507 339.5 507.3 339.4 507.6 339.1L553.6 293.1C553.9 292.8 554 292.4 554 292L561.1 284.9C561.3 284.7 561.4 284.4 561.3 284.1L884.4 -39L883.7 -39.7L560.5 283.5C560.2 283.5 559.9 283.5 559.7 283.7L552.6 290.8C552.2 290.8 551.8 290.9 551.5 291.2L505.5 337.2C505.1 337.6 505 338.2 505.2 338.7L159.1 684.9L159.8 685.6L506 339.4C506.2 339.5 506.4 339.5 506.6 339.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M399.9 385.8C400.2 385.8 400.4 385.7 400.6 385.5L405.2 380.9C405.4 380.9 405.5 381 405.7 381C406.2 381 406.7 380.8 407.1 380.4L579.2 208.3C579.6 207.9 579.8 207.4 579.8 206.8L596.8 189.8C597.1 189.5 597.2 189.1 597.2 188.7L604.3 181.6C604.5 181.4 604.6 181.1 604.5 180.8L854.4 -69.1L853.7 -69.8L603.7 180.3C603.4 180.3 603.1 180.3 602.9 180.5L595.8 187.6C595.4 187.6 595 187.7 594.7 188L577.7 205C577.2 205 576.6 205.2 576.2 205.6L404.2 377.6C403.7 378.1 403.5 378.9 403.7 379.5L399.1 384.1C398.8 384.4 398.8 384.7 398.9 385.1L129.1 654.9L129.8 655.6L399.7 385.7C399.7 385.8 399.8 385.8 399.9 385.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M182.8 338.2C183.1 338.2 183.3 338.1 183.5 337.9L190.6 330.8C190.9 330.8 191.3 330.7 191.5 330.4L202.2 319.7C202.2 319.7 202.2 319.7 202.3 319.7C202.8 319.7 203.3 319.5 203.7 319.1L236.8 286C237.2 285.6 237.4 285.1 237.4 284.5L246.5 275.4C246.9 275 247 274.4 246.8 273.9L476.4 44.3C476.5 44.3 476.6 44.3 476.6 44.3C476.9 44.3 477.1 44.2 477.3 44L481.9 39.4C482.1 39.4 482.2 39.5 482.4 39.5C482.9 39.5 483.4 39.3 483.8 38.9L516.9 5.8C517.3 5.40001 517.5 4.90001 517.5 4.3L526.1 -4.3C526.4 -4.60001 526.5 -5 526.5 -5.39999L533.6 -12.5C533.8 -12.7 533.9 -13 533.8 -13.3L722 -201.3L721.3 -202L533 -13.8C532.7 -13.8 532.4 -13.8 532.2 -13.6L525.1 -6.5C524.7 -6.5 524.3 -6.40001 524 -6.10001L515.4 2.5C514.9 2.5 514.3 2.70001 513.9 3.10001L481 36.2C480.5 36.7 480.3 37.5 480.5 38.1L475.9 42.7C475.6 43 475.6 43.3 475.7 43.7L246 273.2C245.5 273 244.9 273.1 244.5 273.5L235.4 282.6C234.9 282.6 234.3 282.8 233.9 283.2L200.8 316.3C200.4 316.7 200.2 317.2 200.2 317.8L189.5 328.5C189.2 328.8 189.1 329.1 189.1 329.4L182 336.5C181.7 336.8 181.7 337.1 181.8 337.5L-3.30005 522.6L-2.60005 523.3L182.5 338.2C182.6 338.2 182.7 338.2 182.8 338.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M115.2 189.7C115.5 189.7 115.7 189.6 115.9 189.4L123 182.3C123.3 182.3 123.7 182.2 123.9 181.9L134.6 171.2C134.6 171.2 134.6 171.2 134.7 171.2C135.2 171.2 135.7 171 136.1 170.6L185.4 121.3L195.3 111.4C195.7 111 195.9 110.5 195.9 109.9L204.8 101L220.6 85.2C221 84.8 221.1 84.2 220.9 83.7L613.9 -309.3L613.2 -310L220.2 83C219.7 82.8 219.1 82.9 218.7 83.3L202.9 99L194 108C193.5 108 192.9 108.2 192.5 108.6L133.3 167.8C132.9 168.2 132.7 168.7 132.7 169.3L122 179.9C121.7 180.2 121.6 180.5 121.6 180.8L114.5 187.9C114.2 188.2 114.2 188.5 114.3 188.9L-111.3 414.5L-110.6 415.2L115 189.6C115.1 189.7 115.2 189.7 115.2 189.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M114.7 178.2C115 178.2 115.2 178.1 115.4 177.9L117 176.3L122.5 170.8C122.8 170.8 123.2 170.7 123.4 170.4L125 168.8L134.1 159.7C134.1 159.7 134.1 159.7 134.2 159.7C134.7 159.7 135.2 159.5 135.6 159.1L137.2 157.5L189.3 105.4L205.1 89.6C205.5 89.2 205.7 88.7 205.7 88.1L354.5 -60.7C354.9 -61.1 355 -61.7 354.8 -62.2L607.9 -315.3L607.2 -316L354.1 -62.9C353.6 -63.1 353 -63 352.6 -62.6L203.8 86.2C203.3 86.2 202.7 86.4 202.3 86.8L186.5 102.6L134.4 154.7L132.8 156.3C132.4 156.7 132.2 157.2 132.2 157.8L123.1 166.9L121.5 168.5C121.2 168.8 121.1 169.1 121.1 169.4L114 176.5C113.7 176.8 113.7 177.1 113.8 177.5L-117.3 408.6L-116.6 409.3L114.5 178.2C114.6 178.2 114.6 178.2 114.7 178.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M117 163.9C117.3 163.9 117.5 163.8 117.7 163.6L124.8 156.5C125.1 156.5 125.5 156.4 125.7 156.1L136.4 145.4C136.4 145.4 136.4 145.4 136.5 145.4C137 145.4 137.5 145.2 137.9 144.8L339 -56.3C339.4 -56.7 339.6 -57.2 339.6 -57.8L343.6 -61.8C344 -62.2 344.1 -62.8 343.9 -63.3L602 -321.4L601.3 -322.1L343.1 -63.9C342.6 -64.1 342 -64 341.6 -63.6L337.6 -59.6C337.1 -59.6 336.5 -59.4 336.1 -59L135 142C134.6 142.4 134.4 142.9 134.4 143.5L123.7 154.2C123.4 154.5 123.3 154.8 123.3 155.1L116.2 162.2C115.9 162.5 115.9 162.8 116 163.2L-123.3 402.5L-122.6 403.2L116.7 163.9C116.8 163.9 116.9 163.9 117 163.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M120.8 136.1C121.1 136.1 121.3 136 121.5 135.8L128.6 128.7C128.9 128.7 129.3 128.6 129.5 128.3L140.2 117.6C140.2 117.6 140.2 117.6 140.3 117.6C140.8 117.6 141.3 117.4 141.7 117L313.8 -55.1C314.3 -55.6 314.5 -56.4 314.3 -57.1C314.3 -57.3 314.3 -57.5 314.2 -57.6L589.9 -333.3L589.2 -334L313.5 -58.3C313.3 -58.4 313.1 -58.4 313 -58.4C312.3 -58.6 311.6 -58.4 311 -57.9L138.9 114.2C138.5 114.6 138.3 115.1 138.3 115.7L127.6 126.4C127.3 126.7 127.2 127 127.2 127.3L120.1 134.4C119.8 134.7 119.8 135 119.9 135.4L-135.3 390.6L-134.6 391.3L120.6 136C120.7 136 120.8 136.1 120.8 136.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M125.1 119.8C125.4 119.8 125.6 119.7 125.8 119.5L132.9 112.4C133.2 112.4 133.6 112.3 133.8 112L144.5 101.3C144.5 101.3 144.5 101.3 144.6 101.3C145.1 101.3 145.6 101.1 146 100.7L298.4 -51.7C299.1 -52.4 299.2 -53.4 298.7 -54.1L583.9 -339.3L583.2 -340L298 -54.8C297.2 -55.3 296.2 -55.2 295.6 -54.5L143.2 97.9C142.8 98.3 142.6 98.8 142.6 99.4L131.9 110C131.6 110.3 131.5 110.6 131.5 110.9L124.4 118C124.1 118.3 124.1 118.6 124.2 119L-141.3 384.5L-140.6 385.2L124.9 119.7C125 119.7 125 119.8 125.1 119.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M141.9 79.4C142.2 79.4 142.4 79.3 142.6 79.1L149.7 72C150 72 150.4 71.9 150.6 71.6L161.3 60.9C161.3 60.9 161.3 60.9 161.4 60.9C161.9 60.9 162.4 60.7 162.8 60.3L259.4 -36.3C260.1 -37 260.2 -38 259.7 -38.7L572.2 -351.2L571.5 -351.9L258.9 -39.3C258.1 -39.8 257.1 -39.7 256.5 -39L159.9 57.6C159.5 58 159.3 58.5 159.3 59.1L148.6 69.8C148.3 70.1 148.2 70.4 148.2 70.7L141.1 77.8C140.8 78.1 140.8 78.4 140.9 78.8L-153.1 372.8L-152.4 373.5L141.6 79.5C141.7 79.4 141.8 79.4 141.9 79.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M131.4 101.5C131.7 101.5 131.9 101.4 132.1 101.2L139.2 94.1C139.5 94.1 139.9 94 140.1 93.7L150.8 83C150.8 83 150.8 83 150.9 83C151.4 83 151.9 82.8 152.3 82.4L280.7 -46C281.4 -46.7 281.5 -47.7 281 -48.4L577.9 -345.3L577.2 -346L280.3 -49.1C279.5 -49.6 278.5 -49.5 277.9 -48.8L149.5 79.6C149.1 80 148.9 80.5 148.9 81.1L138.2 91.8C137.9 92.1 137.8 92.4 137.8 92.7L130.7 99.8C130.4 100.1 130.4 100.4 130.5 100.8L-147.3 378.6L-146.6 379.3L131.2 101.5C131.3 101.4 131.3 101.5 131.4 101.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M189.1 344.5C189.4 344.5 189.6 344.4 189.8 344.2L196.9 337.1C197.2 337.1 197.6 337 197.8 336.7L208.5 326C208.5 326 208.5 326 208.6 326C209.1 326 209.6 325.8 210 325.4L243.2 292.2C243.6 291.8 243.8 291.3 243.8 290.7L252.6 281.9C253 281.5 253.1 280.9 252.9 280.4L482.5 50.8C482.6 50.8 482.7 50.8 482.7 50.8C483 50.8 483.2 50.7 483.4 50.5L488 45.9C488.2 45.9 488.3 46 488.5 46C489 46 489.5 45.8 489.9 45.4L523.1 12.2C523.5 11.8 523.7 11.3 523.7 10.7L532.3 2.10001C532.6 1.8 532.7 1.39999 532.7 1L540.1 -6.39999C540.3 -6.59999 540.4 -6.89999 540.3 -7.2L728.3 -195.2L727.6 -195.9L539.6 -7.89999C539.3 -7.89999 539 -7.89999 538.8 -7.7L531.4 -0.300003C531 -0.300003 530.6 -0.199997 530.3 0.100006L521.7 8.7C521.2 8.7 520.6 8.90001 520.2 9.3L487 42.5C486.5 43 486.3 43.8 486.5 44.4L481.9 49C481.6 49.3 481.6 49.6 481.7 50L252.2 279.7C251.7 279.5 251.1 279.6 250.7 280L241.9 288.8C241.4 288.8 240.8 289 240.4 289.4L207.2 322.6C206.8 323 206.6 323.5 206.6 324.1L195.9 334.8C195.6 335.1 195.5 335.4 195.5 335.7L188.4 342.8C188.1 343.1 188.1 343.4 188.2 343.8L3 528.9L3.7 529.6L188.8 344.5C188.9 344.5 189 344.5 189.1 344.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M195.5 350.2C195.8 350.2 196 350.1 196.2 349.9L203.3 342.8C203.6 342.8 204 342.7 204.2 342.4L214.9 331.7C214.9 331.7 214.9 331.7 215 331.7C215.5 331.7 216 331.5 216.4 331.1L249.6 297.9C250 297.5 250.2 297 250.2 296.4L258.8 287.8C259.2 287.4 259.3 286.8 259.1 286.3L488.2 57.2C488.3 57.2 488.4 57.2 488.4 57.2C488.7 57.2 488.9 57.1 489.1 56.9L493.7 52.3C493.9 52.3 494 52.4 494.2 52.4C494.7 52.4 495.2 52.2 495.6 51.8L528.8 18.6C529.2 18.2 529.4 17.7 529.4 17.1L538.3 8.19998C538.6 7.89998 538.7 7.49998 538.7 7.09999L545.8 -1.52588e-05C546 -0.200012 546.1 -0.500015 546 -0.800018L734.3 -189L733.6 -189.7L545.3 -1.40001C545 -1.40001 544.7 -1.40001 544.5 -1.20001L537.4 5.89998C537 5.89998 536.6 5.99998 536.3 6.29999L527.4 15.2C526.9 15.2 526.3 15.4 525.9 15.8L492.8 49C492.3 49.5 492.1 50.3 492.3 50.9L487.7 55.5C487.4 55.8 487.4 56.1 487.5 56.5L258.3 285.5C257.8 285.3 257.2 285.4 256.8 285.8L248.2 294.4C247.7 294.4 247.1 294.6 246.7 295L213.5 328.2C213.1 328.6 212.9 329.1 212.9 329.7L202.2 340.4C201.9 340.7 201.8 341 201.8 341.3L194.7 348.4C194.4 348.7 194.4 349 194.5 349.4L9 534.9L9.7 535.6L195.2 350.1C195.3 350.1 195.4 350.2 195.5 350.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M202.1 355.5C202.4 355.5 202.6 355.4 202.8 355.2L209.9 348.1C210.2 348.1 210.6 348 210.8 347.7L221.5 337C221.5 337 221.5 337 221.6 337C222.1 337 222.6 336.8 223 336.4L256.5 302.9C256.9 302.5 257.1 302 257.1 301.4L265.2 293.3C265.6 292.9 265.7 292.3 265.5 291.8L493.3 64C493.4 64 493.5 64 493.5 64C493.8 64 494 63.9 494.2 63.7L498.8 59.1C499 59.1 499.1 59.2 499.3 59.2C499.8 59.2 500.3 59 500.7 58.6L534.2 25.1C534.6 24.7 534.8 24.2 534.8 23.6L544 14.4C544.3 14.1 544.4 13.7 544.4 13.3L551.5 6.19998C551.7 5.99998 551.8 5.69998 551.7 5.39998L740.3 -183L739.6 -183.7L551 4.89998C550.7 4.89998 550.4 4.89999 550.2 5.09999L543.1 12.2C542.7 12.2 542.3 12.3 542 12.6L532.8 21.8C532.3 21.8 531.7 22 531.3 22.4L497.8 55.9C497.3 56.4 497.1 57.2 497.3 57.8L492.7 62.4C492.4 62.7 492.4 63 492.5 63.4L264.7 291.2C264.2 291 263.6 291.1 263.2 291.5L255.1 299.6C254.6 299.6 254 299.8 253.6 300.2L220.1 333.7C219.7 334.1 219.5 334.6 219.5 335.2L208.8 345.9C208.5 346.2 208.4 346.5 208.4 346.8L201.3 353.9C201 354.2 201 354.5 201.1 354.9L15 540.9L15.7 541.6L201.8 355.5C201.9 355.5 202 355.5 202.1 355.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M208.9 360.7C209.2 360.7 209.4 360.6 209.6 360.4L216.7 353.3C217 353.3 217.4 353.2 217.6 352.9L228.3 342.2C228.3 342.2 228.3 342.2 228.4 342.2C228.9 342.2 229.4 342 229.8 341.6L263.4 308C263.8 307.6 264 307.1 264 306.5L272.1 298.4C272.5 298 272.6 297.4 272.4 296.9L498.6 70.7C498.7 70.7 498.8 70.7 498.8 70.7C499.1 70.7 499.3 70.6 499.5 70.4L504.1 65.8C504.3 65.8 504.4 65.9 504.6 65.9C505.1 65.9 505.6 65.7 506 65.3L539.6 31.7C540 31.3 540.2 30.8 540.2 30.2L549.5 20.9C549.8 20.6 549.9 20.2 549.9 19.8L557 12.7C557.2 12.5 557.3 12.2 557.2 11.9L746.3 -177L745.6 -177.7L556.3 11.6C556 11.6 555.7 11.6 555.5 11.8L548.4 18.9C548 18.9 547.6 19 547.3 19.3L538 28.6C537.5 28.6 536.9 28.8 536.5 29.2L503 62.7C502.5 63.2 502.3 64 502.5 64.6L497.9 69.2C497.6 69.5 497.6 69.8 497.7 70.2L271.6 296.3C271.1 296.1 270.5 296.2 270.1 296.6L262 304.7C261.5 304.7 260.9 304.9 260.5 305.3L226.9 338.9C226.5 339.3 226.3 339.8 226.3 340.4L215.7 351C215.4 351.3 215.3 351.6 215.3 351.9L208.2 359C207.9 359.3 207.9 359.6 208 360L21 546.9L21.7 547.6L208.6 360.7C208.7 360.7 208.8 360.7 208.9 360.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M215.9 365.8C216.2 365.8 216.4 365.7 216.6 365.5L223.7 358.4C224 358.4 224.4 358.3 224.6 358L235.3 347.3C235.3 347.3 235.3 347.3 235.4 347.3C235.9 347.3 236.4 347.1 236.8 346.7L270.6 312.9C271 312.5 271.2 312 271.2 311.4L279 303.6C279.4 303.2 279.5 302.6 279.3 302.1L503.4 78C503.5 78 503.6 78 503.6 78C503.9 78 504.1 77.9 504.3 77.7L508.9 73.1C509.1 73.1 509.2 73.2 509.4 73.2C509.9 73.2 510.4 73 510.8 72.6L544.6 38.8C545 38.4 545.2 37.9 545.2 37.3L554.8 27.7C555.1 27.4 555.2 27 555.2 26.6L562.3 19.5C562.5 19.3 562.6 19 562.5 18.7L752.5 -171.3L751.8 -172L561.8 18C561.5 18 561.2 18 561 18.2L553.9 25.3C553.5 25.3 553.1 25.4 552.8 25.7L543.2 35.3C542.7 35.3 542.1 35.5 541.7 35.9L507.9 69.7C507.4 70.2 507.2 71 507.4 71.6L502.8 76.2C502.5 76.5 502.5 76.8 502.6 77.2L278.4 301.5C277.9 301.3 277.3 301.4 276.9 301.8L269.1 309.6C268.6 309.6 268 309.8 267.6 310.2L233.8 344C233.4 344.4 233.2 344.9 233.2 345.5L222.7 356C222.4 356.3 222.3 356.6 222.3 356.9L215.2 364C214.9 364.3 214.9 364.6 215 365L27 552.9L27.7 553.6L215.6 365.7C215.7 365.7 215.8 365.8 215.9 365.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M223.1 370.5C223.4 370.5 223.6 370.4 223.8 370.2L230.9 363.1C231.2 363.1 231.6 363 231.8 362.7L242.5 352C242.5 352 242.5 352 242.6 352C243.1 352 243.6 351.8 244 351.4L278.2 317.2C278.6 316.8 278.8 316.3 278.8 315.7L286.1 308.4C286.5 308 286.6 307.4 286.4 306.9L507.6 85.7C507.7 85.7 507.8 85.7 507.8 85.7C508.1 85.7 508.3 85.6 508.5 85.4L513.1 80.8C513.3 80.8 513.4 80.9 513.6 80.9C514.1 80.9 514.6 80.7 515 80.3L549.2 46.1C549.6 45.7 549.8 45.2 549.8 44.6L559.6 34.8C559.9 34.5 560 34.1 560 33.7L567.1 26.6C567.3 26.4 567.4 26.1 567.3 25.8L758.3 -165.2L757.6 -165.9L566.6 25.1C566.3 25.1 566 25.1 565.8 25.3L558.7 32.4C558.3 32.4 557.9 32.5 557.6 32.8L547.8 42.6C547.3 42.6 546.7 42.8 546.3 43.2L512.1 77.4C511.6 77.9 511.4 78.7 511.6 79.3L507 83.9C506.7 84.2 506.7 84.5 506.8 84.9L285.6 306.3C285.1 306.1 284.5 306.2 284.1 306.6L276.8 313.9C276.3 313.9 275.7 314.1 275.3 314.5L241.1 348.7C240.7 349.1 240.5 349.6 240.5 350.2L229.8 360.9C229.5 361.2 229.4 361.5 229.4 361.8L222.3 368.9C222 369.2 222 369.5 222.1 369.9L33 558.9L33.7 559.6L222.8 370.5C222.9 370.5 223 370.5 223.1 370.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M230.6 375.1C230.9 375.1 231.1 375 231.3 374.8L238.4 367.7C238.7 367.7 239.1 367.6 239.3 367.3L250 356.6C250.5 356.6 251 356.4 251.4 356L286 321.4C286.4 321 286.6 320.5 286.6 319.9L293.7 312.8C294.1 312.4 294.2 311.8 294 311.3L511.7 93.6C511.8 93.6 511.9 93.6 511.9 93.6C512.2 93.6 512.4 93.5 512.6 93.3L517.2 88.7C517.4 88.7 517.5 88.8 517.7 88.8C518.2 88.8 518.7 88.6 519.1 88.2L553.7 53.6C554.1 53.2 554.3 52.7 554.3 52.1L564.3 42.1C564.6 41.8 564.7 41.4 564.7 41L571.8 33.9C572 33.7 572.1 33.4 572 33.1L764.3 -159L763.6 -159.7L571.4 32.6C571.1 32.6 570.8 32.6 570.6 32.8L563.5 39.9C563.1 39.9 562.7 40 562.4 40.3L552.4 50.3C551.9 50.3 551.3 50.5 550.9 50.9L516.3 85.5C515.8 86 515.6 86.8 515.8 87.4L511.2 92C510.9 92.3 510.9 92.6 511 93L293.3 310.6C292.8 310.4 292.2 310.5 291.8 310.9L284.7 318C284.2 318 283.6 318.2 283.2 318.6L248.6 353.2C248.2 353.6 248 354.1 248 354.7L237.3 365.4C237 365.7 236.9 366 236.9 366.3L229.8 373.4C229.5 373.7 229.5 374 229.6 374.4L39 564.9L39.7 565.6L230.3 375C230.4 375 230.5 375.1 230.6 375.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M238.3 379.4C238.6 379.4 238.8 379.3 239 379.1L246.1 372C246.4 372 246.8 371.9 247 371.6L257.7 360.9C258.2 360.9 258.7 360.7 259.1 360.3L294.2 325.2C294.6 324.8 294.8 324.3 294.8 323.7L301.6 316.9C302 316.5 302.1 315.9 301.9 315.4L515.6 101.7C515.7 101.7 515.8 101.7 515.8 101.7C516.1 101.7 516.3 101.6 516.5 101.4L521.1 96.8C521.3 96.8 521.4 96.9 521.6 96.9C522.1 96.9 522.6 96.7 523 96.3L558.1 61.2C558.5 60.8 558.7 60.3 558.7 59.7L569 49.4C569.3 49.1 569.4 48.7 569.4 48.3L576.5 41.2C576.7 41 576.8 40.7 576.7 40.4L770.3 -153L769.6 -153.7L575.9 40C575.6 40 575.3 40 575.1 40.2L568 47.3C567.6 47.3 567.2 47.4 566.9 47.7L556.7 58C556.2 58 555.6 58.2 555.2 58.6L520.1 93.7C519.6 94.2 519.4 95 519.6 95.6L515 100.2C514.7 100.5 514.7 100.8 514.8 101.2L301.2 314.8C300.7 314.6 300.1 314.7 299.7 315.1L292.9 321.9C292.4 321.9 291.8 322.1 291.4 322.5L256.3 357.6C255.9 358 255.7 358.5 255.7 359.1L245 369.8C244.7 370.1 244.6 370.4 244.6 370.7L237.5 377.8C237.2 378.1 237.2 378.4 237.3 378.8L45 570.9L45.7 571.6L238 379.3C238.1 379.3 238.2 379.4 238.3 379.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M246.2 383.4C246.5 383.4 246.7 383.3 246.9 383.1L254 376C254.3 376 254.7 375.9 254.9 375.6L265.6 364.9C265.6 364.9 265.6 364.9 265.7 364.9C266.2 364.9 266.7 364.7 267.1 364.3L303.1 328.3C303.5 327.9 303.7 327.4 303.7 326.8L309.8 320.7C310.2 320.3 310.3 319.7 310.1 319.2L518.8 110.5C518.9 110.5 519 110.5 519 110.5C519.3 110.5 519.5 110.4 519.7 110.2L524.3 105.6C524.5 105.6 524.6 105.7 524.8 105.7C525.3 105.7 525.8 105.5 526.2 105.1L562.2 69.1C562.6 68.7 562.8 68.2 562.8 67.6L573.3 57.1C573.6 56.8 573.7 56.4 573.7 56L580.8 48.9C581 48.7 581.1 48.4 581 48.1L776.3 -147L775.6 -147.7L580.2 47.7C579.9 47.7 579.6 47.7 579.4 47.9L572.3 55C571.9 55 571.5 55.1 571.2 55.4L560.7 66C560.2 66 559.6 66.2 559.2 66.6L523.2 102.6C522.7 103.1 522.5 103.9 522.7 104.5L518.1 109.1C517.8 109.4 517.8 109.7 517.9 110.1L309.3 318.6C308.8 318.4 308.2 318.5 307.8 318.9L301.7 325C301.2 325 300.6 325.2 300.2 325.6L264.2 361.6C263.8 362 263.6 362.5 263.6 363.1L253 373.7C252.7 374 252.6 374.3 252.6 374.6L245.5 381.7C245.2 382 245.2 382.3 245.3 382.7L51 576.9L51.7 577.6L246 383.4C246.1 383.4 246.1 383.4 246.2 383.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M254.4 387.2C254.7 387.2 254.9 387.1 255.1 386.9L262.2 379.8C262.5 379.8 262.9 379.7 263.1 379.4L273.8 368.7C273.8 368.7 273.8 368.7 273.9 368.7C274.4 368.7 274.9 368.5 275.3 368.1L312 331.4C312.4 331 312.6 330.5 312.6 329.9L318.6 323.9C319 323.5 319.1 322.9 318.9 322.4L521.8 119.5C521.9 119.5 522 119.5 522 119.5C522.3 119.5 522.5 119.4 522.7 119.2L527.3 114.6C527.5 114.6 527.6 114.7 527.8 114.7C528.3 114.7 528.8 114.5 529.2 114.1L565.9 77.4C566.3 77 566.5 76.5 566.5 75.9L577.3 65.1C577.6 64.8 577.7 64.4 577.7 64L584.8 56.9C585 56.7 585.1 56.4 585 56.1L782.3 -141L781.6 -141.7L584.2 55.7C583.9 55.7 583.6 55.7 583.4 55.9L576.3 63C575.9 63 575.5 63.1 575.2 63.4L564.4 74.2C563.9 74.2 563.3 74.4 562.9 74.8L526.2 111.5C525.7 112 525.5 112.8 525.7 113.4L521.1 118C520.8 118.3 520.8 118.6 520.9 119L318.2 321.7C317.7 321.5 317.1 321.6 316.7 322L310.7 328C310.2 328 309.6 328.2 309.2 328.6L272.5 365.3C272.1 365.7 271.9 366.2 271.9 366.8L261.2 377.5C260.9 377.8 260.8 378.1 260.8 378.4L253.7 385.5C253.4 385.8 253.4 386.1 253.5 386.5L57 582.9L57.7 583.6L254.2 387.1C254.3 387.2 254.4 387.2 254.4 387.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M262.9 390.7C263.2 390.7 263.4 390.6 263.6 390.4L270.7 383.3C271 383.3 271.4 383.2 271.6 382.9L282.3 372.2C282.8 372.2 283.3 372 283.7 371.6L321.1 334.2C321.5 333.8 321.7 333.3 321.7 332.7L327.4 327C327.8 326.6 327.9 326 327.7 325.5L524.4 128.8C524.5 128.8 524.6 128.8 524.6 128.8C524.9 128.8 525.1 128.7 525.3 128.5L529.9 123.9C530.1 123.9 530.2 124 530.4 124C530.9 124 531.4 123.8 531.8 123.4L569.2 86C569.6 85.6 569.8 85.1 569.8 84.5L580.9 73.4C581.2 73.1 581.3 72.7 581.3 72.3L588.4 65.2C588.6 65 588.7 64.7 588.6 64.4L788.3 -135L787.6 -135.7L588 63.9C587.7 63.9 587.4 63.9 587.2 64.1L580.1 71.2C579.7 71.2 579.3 71.3 579 71.6L568 82.7C567.5 82.7 566.9 82.9 566.5 83.3L529.1 120.7C528.6 121.2 528.4 122 528.6 122.6L524 127.2C523.7 127.5 523.7 127.8 523.8 128.2L327.1 324.8C326.6 324.6 326 324.7 325.6 325.1L319.9 330.8C319.4 330.8 318.8 331 318.4 331.4L281 368.8C280.6 369.2 280.4 369.7 280.4 370.3L269.7 381C269.4 381.3 269.3 381.6 269.3 381.9L262.2 389C261.9 389.3 261.9 389.6 262 390L63 588.9L63.7 589.6L262.6 390.7C262.8 390.7 262.9 390.7 262.9 390.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M272.9 392.8C273.2 392.8 273.4 392.7 273.6 392.5L280.7 385.4C281 385.4 281.4 385.3 281.6 385L292.3 374.3C292.3 374.3 292.3 374.3 292.4 374.3C292.9 374.3 293.4 374.1 293.8 373.7L330.7 336.8C331.1 336.4 331.3 335.9 331.3 335.3L336.8 329.8C337.2 329.4 337.3 328.8 337.1 328.3L525.5 139.9C525.6 139.9 525.7 139.9 525.7 139.9C526 139.9 526.2 139.8 526.4 139.6L531 135C531.2 135 531.3 135.1 531.5 135.1C532 135.1 532.5 134.9 532.9 134.5L571.5 95.9C571.9 95.5 572.1 95 572.1 94.4L584.6 81.9C584.9 81.6 585 81.2 585 80.8L592.1 73.7C592.3 73.5 592.4 73.2 592.3 72.9L794.3 -129L793.6 -129.7L591.5 72.4C591.2 72.4 590.9 72.4 590.7 72.6L583.6 79.7C583.2 79.7 582.8 79.8 582.5 80.1L570 92.6C569.5 92.6 568.9 92.8 568.5 93.2L529.9 131.8C529.4 132.3 529.2 133.1 529.4 133.7L524.8 138.3C524.5 138.6 524.5 138.9 524.6 139.3L336.4 327.6C335.9 327.4 335.3 327.5 334.9 327.9L329.4 333.4C328.9 333.4 328.3 333.6 327.9 334L291 370.9C290.6 371.3 290.4 371.8 290.4 372.4L279.7 383C279.4 383.3 279.3 383.6 279.3 383.9L272.2 391C271.9 391.3 271.9 391.6 272 392L69 594.9L69.7 595.6L272.6 392.7C272.7 392.8 272.8 392.8 272.9 392.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M282.2 395.4C282.5 395.4 282.7 395.3 282.9 395.1L290 388C290.3 388 290.7 387.9 290.9 387.6L301.6 376.9C301.6 376.9 301.6 376.9 301.7 376.9C302.2 376.9 302.7 376.7 303.1 376.3L340.8 338.6C341.2 338.2 341.4 337.7 341.4 337.1L346.3 332.2C346.7 331.8 346.8 331.2 346.6 330.7L526.8 150.5C526.9 150.5 527 150.5 527 150.5C527.3 150.5 527.5 150.4 527.7 150.2L532.3 145.6C532.5 145.6 532.6 145.7 532.8 145.7C533.3 145.7 533.8 145.5 534.2 145.1L574.2 105.1C574.6 104.7 574.8 104.2 574.8 103.6L586.7 91.7C587 91.4 587.1 91 587.1 90.6L594.2 83.5C594.4 83.3 594.5 83 594.4 82.7L800.4 -123.3L799.7 -124L593.7 82C593.4 82 593.1 82 592.9 82.2L585.8 89.3C585.4 89.3 585 89.4 584.7 89.7L572.8 101.6C572.3 101.6 571.7 101.8 571.3 102.2L531.3 142.2C530.8 142.7 530.6 143.5 530.8 144.1L526.2 148.7C525.9 149 525.9 149.3 526 149.7L345.8 330.1C345.3 329.9 344.7 330 344.3 330.4L339.4 335.3C338.9 335.3 338.3 335.5 337.9 335.9L300.2 373.6C299.8 374 299.6 374.5 299.6 375.1L289 385.7C288.7 386 288.6 386.3 288.6 386.6L281.5 393.7C281.2 394 281.2 394.3 281.3 394.7L75 600.9L75.7 601.6L282 395.4C282 395.4 282.1 395.4 282.2 395.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M291.8 397.8C292.1 397.8 292.3 397.7 292.5 397.5L299.6 390.4C299.9 390.4 300.3 390.3 300.5 390L311.2 379.3C311.2 379.3 311.2 379.3 311.3 379.3C311.8 379.3 312.3 379.1 312.7 378.7L351.8 339.6C352.2 339.2 352.4 338.7 352.4 338.1L356.3 334.2C356.7 333.8 356.8 333.2 356.6 332.7L527.3 162C527.4 162 527.5 162 527.5 162C527.8 162 528 161.9 528.2 161.7L532.8 157.1C533 157.1 533.1 157.2 533.3 157.2C533.8 157.2 534.3 157 534.7 156.6L576.5 114.8C576.9 114.4 577.1 113.9 577.1 113.3L589.3 101.1C589.6 100.8 589.7 100.4 589.7 100L596.8 92.9C597 92.7 597.1 92.4 597 92.1L806.3 -117L805.6 -117.7L596.3 91.7C596 91.7 595.7 91.7 595.5 91.9L588.4 99C588 99 587.6 99.1 587.3 99.4L575.1 111.6C574.6 111.6 574 111.8 573.6 112.2L531.8 154C531.3 154.5 531.1 155.3 531.3 155.9L526.7 160.5C526.4 160.8 526.4 161.1 526.5 161.5L355.9 332C355.4 331.8 354.8 331.9 354.4 332.3L350.5 336.2C350 336.2 349.4 336.4 349 336.8L309.9 375.9C309.5 376.3 309.3 376.8 309.3 377.4L298.6 388.1C298.3 388.4 298.2 388.7 298.2 389L291.1 396.1C290.8 396.4 290.8 396.7 290.9 397.1L81 606.9L81.7 607.6L291.5 397.8C291.6 397.8 291.7 397.8 291.8 397.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M301.7 400C302 400 302.2 399.9 302.4 399.7L309.5 392.6C309.8 392.6 310.2 392.5 310.4 392.2L321.1 381.5C321.1 381.5 321.1 381.5 321.2 381.5C321.7 381.5 322.2 381.3 322.6 380.9L363.2 340.3C363.6 339.9 363.8 339.4 363.8 338.8L367.4 335.2C367.8 334.8 367.9 334.2 367.7 333.7L526.9 174.5C527 174.5 527.1 174.5 527.1 174.5C527.4 174.5 527.6 174.4 527.8 174.2L532.4 169.6C532.6 169.6 532.7 169.7 532.9 169.7C533.4 169.7 533.9 169.5 534.3 169.1L578.7 124.7C579.1 124.3 579.3 123.8 579.3 123.2L591.8 110.7C592.1 110.4 592.2 110 592.2 109.6L599.3 102.5C599.5 102.3 599.6 102 599.5 101.7L812.3 -111L811.6 -111.7L598.7 101.3C598.4 101.3 598.1 101.3 597.9 101.5L590.8 108.6C590.4 108.6 590 108.7 589.7 109L577.2 121.5C576.7 121.5 576.1 121.7 575.7 122.1L531.3 166.5C530.8 167 530.6 167.8 530.8 168.4L526.2 173C525.9 173.3 525.9 173.6 526 174L366.9 333C366.4 332.8 365.8 332.9 365.4 333.3L361.8 336.9C361.3 336.9 360.7 337.1 360.3 337.5L319.7 378.1C319.3 378.5 319.1 379 319.1 379.6L308.4 390.3C308.1 390.6 308 390.9 308 391.2L300.9 398.3C300.6 398.6 300.6 398.9 300.7 399.3L87.1001 612.9L87.8001 613.6L301.5 399.9C301.5 399.9 301.6 400 301.7 400Z"
                fill="#1D6BFC"
              />
              <path
                d="M311.7 402C312 402 312.2 401.9 312.4 401.7L319.5 394.6C319.8 394.6 320.2 394.5 320.4 394.2L331.1 383.5C331.1 383.5 331.1 383.5 331.2 383.5C331.7 383.5 332.2 383.3 332.6 382.9L374.8 340.7C375.2 340.3 375.4 339.8 375.4 339.2L378.8 335.8C379.2 335.4 379.3 334.8 379.1 334.3L525.4 188C525.5 188 525.6 188 525.6 188C525.9 188 526.1 187.9 526.3 187.7L530.9 183.1C531.1 183.1 531.2 183.2 531.4 183.2C531.9 183.2 532.4 183 532.8 182.6L580.7 134.7C581.1 134.3 581.3 133.8 581.3 133.2L593.8 120.7C594.1 120.4 594.2 120 594.2 119.6L601.3 112.5C601.5 112.3 601.6 112 601.5 111.7L818.3 -104.9L817.6 -105.6L600.8 111.2C600.5 111.2 600.2 111.2 600 111.4L592.9 118.5C592.5 118.5 592.1 118.6 591.8 118.9L579.3 131.4C578.8 131.4 578.2 131.6 577.8 132L530 179.9C529.5 180.4 529.3 181.2 529.5 181.8L524.9 186.4C524.6 186.7 524.6 187 524.7 187.4L378.3 333.6C377.8 333.4 377.2 333.5 376.8 333.9L373.4 337.3C372.9 337.3 372.3 337.5 371.9 337.9L329.7 380.1C329.3 380.5 329.1 381 329.1 381.6L318.4 392.3C318.1 392.6 318 392.9 318 393.2L310.9 400.3C310.6 400.6 310.6 400.9 310.7 401.3L93.1001 618.9L93.8001 619.6L311.5 401.9C311.5 402 311.6 402 311.7 402Z"
                fill="#1D6BFC"
              />
              <path
                d="M130.6 258.4C130.9 258.4 131.1 258.3 131.3 258.1L138.4 251C138.7 251 139.1 250.9 139.3 250.6L150 239.9C150 239.9 150 239.9 150.1 239.9C150.6 239.9 151.1 239.7 151.5 239.3L188.9 201.9C189.3 201.5 189.5 201 189.5 200.4L203.5 186.4C203.9 186 204 185.4 203.8 184.9L392.2 -3.49997C392.3 -3.49997 392.4 -3.49997 392.4 -3.49997C392.7 -3.49997 392.9 -3.59998 393.1 -3.79999L397.7 -8.39999C397.9 -8.39999 398 -8.29999 398.2 -8.29999C398.7 -8.29999 399.2 -8.5 399.6 -8.89999L437 -46.3C437.4 -46.7 437.6 -47.2 437.6 -47.8L442.3 -52.5C442.6 -52.8 442.7 -53.2 442.7 -53.6L449.8 -60.7C450 -60.9 450.1 -61.2 450 -61.5L656 -267.5L655.3 -268.2L449.3 -62.2C449 -62.2 448.7 -62.2 448.5 -62L441.4 -54.9C441 -54.9 440.6 -54.8 440.3 -54.5L435.6 -49.8C435.1 -49.8 434.5 -49.6 434.1 -49.2L396.7 -11.8C396.2 -11.3 396 -10.5 396.2 -9.89999L391.6 -5.29999C391.3 -5 391.3 -4.69998 391.4 -4.29999L203.1 184.2C202.6 184 202 184.1 201.6 184.5L187.6 198.5C187.1 198.5 186.5 198.7 186.1 199.1L148.7 236.5C148.3 236.9 148.1 237.4 148.1 238L137.4 248.7C137.1 249 137 249.3 137 249.6L129.9 256.7C129.6 257 129.6 257.3 129.7 257.7L-69.3 456.5L-68.6 457.2L130.3 258.3C130.4 258.3 130.5 258.4 130.6 258.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M176.8 332.2C177.1 332.2 177.3 332.1 177.5 331.9L184.6 324.8C184.9 324.8 185.3 324.7 185.5 324.4L196.2 313.7C196.2 313.7 196.2 313.7 196.3 313.7C196.8 313.7 197.3 313.5 197.7 313.1L230.9 279.9C231.3 279.5 231.5 279 231.5 278.4L240.8 269.1C241.2 268.7 241.3 268.1 241.1 267.6L470.2 38.5C470.3 38.5 470.4 38.5 470.4 38.5C470.7 38.5 470.9 38.4 471.1 38.2L475.7 33.6C475.9 33.6 476 33.7 476.2 33.7C476.7 33.7 477.2 33.5 477.6 33.1L510.8 -0.100006C511.2 -0.5 511.4 -1 511.4 -1.60001L519.6 -9.8C519.9 -10.1 520 -10.5 520 -10.9L527.1 -18C527.3 -18.2 527.4 -18.5 527.3 -18.8L716 -207.3L715.3 -208L526.7 -19.4C526.4 -19.4 526.1 -19.4 525.9 -19.2L518.8 -12.1C518.4 -12.1 518 -12 517.7 -11.7L509.5 -3.5C509 -3.5 508.4 -3.29999 508 -2.89999L474.8 30.3C474.3 30.8 474.1 31.6 474.3 32.2L469.7 36.8C469.4 37.1 469.4 37.4 469.5 37.8L240.4 266.9C239.9 266.7 239.3 266.8 238.9 267.2L229.6 276.5C229.1 276.5 228.5 276.7 228.1 277.1L194.9 310.3C194.5 310.7 194.3 311.2 194.3 311.8L183.6 322.5C183.3 322.8 183.2 323.1 183.2 323.4L176.1 330.5C175.8 330.8 175.8 331.1 175.9 331.5L-9.30005 516.6L-8.60005 517.3L176.5 332.2C176.6 332.2 176.7 332.2 176.8 332.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M171.1 325.8C171.4 325.8 171.6 325.7 171.8 325.5L178.9 318.4C179.2 318.4 179.6 318.3 179.8 318L190.5 307.3C190.5 307.3 190.5 307.3 190.6 307.3C191.1 307.3 191.6 307.1 192 306.7L225.2 273.5C225.6 273.1 225.8 272.6 225.8 272L235.6 262.2C236 261.8 236.1 261.2 235.9 260.7L463.8 32.8C463.9 32.8 464 32.8 464 32.8C464.3 32.8 464.5 32.7 464.7 32.5L469.3 27.9C469.5 27.9 469.6 28 469.8 28C470.3 28 470.8 27.8 471.2 27.4L504.4 -5.8C504.8 -6.2 505 -6.7 505 -7.3L512.9 -15.2C513.2 -15.5 513.3 -15.9 513.3 -16.3L520.4 -23.4C520.6 -23.6 520.7 -23.9 520.6 -24.2L710 -213.3L709.3 -214L520 -24.8C519.7 -24.8 519.4 -24.8 519.2 -24.6L512.1 -17.5C511.7 -17.5 511.3 -17.4 511 -17.1L503.1 -9.2C502.6 -9.2 502 -9 501.6 -8.60001L468.4 24.6C467.9 25.1 467.7 25.9 467.9 26.5L463.3 31.1C463 31.4 463 31.7 463.1 32.1L235.3 260C234.8 259.8 234.2 259.9 233.8 260.3L224 270.1C223.5 270.1 222.9 270.3 222.5 270.7L189.3 303.9C188.9 304.3 188.7 304.8 188.7 305.4L178 316.1C177.7 316.4 177.6 316.7 177.6 317L170.5 324.1C170.2 324.4 170.2 324.7 170.3 325.1L-15.3 510.6L-14.6 511.3L170.9 325.8C171 325.8 171.1 325.8 171.1 325.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M165.8 319.2C166.1 319.2 166.3 319.1 166.5 318.9L173.6 311.8C173.9 311.8 174.3 311.7 174.5 311.4L185.2 300.7C185.2 300.7 185.2 300.7 185.3 300.7C185.8 300.7 186.3 300.5 186.7 300.1L220.2 266.6C220.6 266.2 220.8 265.7 220.8 265.1L230.6 255.3C231 254.9 231.1 254.3 230.9 253.8L457 27.7C457.1 27.7 457.2 27.7 457.2 27.7C457.5 27.7 457.7 27.6 457.9 27.4L462.5 22.8C462.7 22.8 462.8 22.9 463 22.9C463.5 22.9 464 22.7 464.4 22.3L497.9 -11.2C498.3 -11.6 498.5 -12.1 498.5 -12.7L506.3 -20.5C506.6 -20.8 506.7 -21.2 506.7 -21.6L513.8 -28.7C514 -28.9 514.1 -29.2 514 -29.5L704 -219.5L703.3 -220.2L513.3 -30.2C513 -30.2 512.7 -30.2 512.5 -30L505.4 -22.9C505 -22.9 504.6 -22.8 504.3 -22.5L496.5 -14.7C496 -14.7 495.4 -14.5 495 -14.1L461.5 19.4C461 19.9 460.8 20.7 461 21.3L456.4 25.9C456.1 26.2 456.1 26.5 456.2 26.9L230.1 253.2C229.6 253 229 253.1 228.6 253.5L218.8 263.3C218.3 263.3 217.7 263.5 217.3 263.9L183.8 297.4C183.4 297.8 183.2 298.3 183.2 298.9L172.5 309.6C172.2 309.9 172.1 310.2 172.1 310.5L165 317.6C164.7 317.9 164.7 318.2 164.8 318.6L-21.3 504.6L-20.6 505.3L165.5 319.2C165.6 319.2 165.7 319.2 165.8 319.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M160.5 312.4C160.8 312.4 161 312.3 161.2 312.1L168.3 305C168.6 305 169 304.9 169.2 304.6L179.9 293.9C179.9 293.9 179.9 293.9 180 293.9C180.5 293.9 181 293.7 181.4 293.3L215 259.7C215.4 259.3 215.6 258.8 215.6 258.2L225.7 248.1C226.1 247.7 226.2 247.1 226 246.6L450.1 22.5C450.2 22.5 450.3 22.5 450.3 22.5C450.6 22.5 450.8 22.4 451 22.2L455.6 17.6C455.8 17.6 455.9 17.7 456.1 17.7C456.6 17.7 457.1 17.5 457.5 17.1L491.1 -16.5C491.5 -16.9 491.7 -17.4 491.7 -18L499.3 -25.6C499.6 -25.9 499.7 -26.3 499.7 -26.7L506.8 -33.8C507 -34 507.1 -34.3 507 -34.6L698 -225.6L697.3 -226.3L506.3 -35.3C506 -35.3 505.7 -35.3 505.5 -35.1L498.4 -28C498 -28 497.6 -27.9 497.3 -27.6L489.7 -20C489.2 -20 488.6 -19.8 488.2 -19.4L454.6 14.2C454.1 14.7 453.9 15.5 454.1 16.1L449.5 20.7C449.2 21 449.2 21.3 449.3 21.7L225.3 246C224.8 245.8 224.2 245.9 223.8 246.3L213.7 256.4C213.2 256.4 212.6 256.6 212.2 257L178.6 290.6C178.2 291 178 291.5 178 292.1L167.3 302.8C167 303.1 166.9 303.4 166.9 303.7L159.8 310.8C159.5 311.1 159.5 311.4 159.6 311.8L-27.3 498.6L-26.6 499.3L160.3 312.4C160.4 312.4 160.5 312.4 160.5 312.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M155.5 305.4C155.8 305.4 156 305.3 156.2 305.1L163.3 298C163.6 298 164 297.9 164.2 297.6L174.9 286.9C174.9 286.9 174.9 286.9 175 286.9C175.5 286.9 176 286.7 176.4 286.3L210.2 252.5C210.6 252.1 210.8 251.6 210.8 251L221.4 240.4C221.8 240 221.9 239.4 221.7 238.9L442.9 17.7C443 17.7 443.1 17.7 443.1 17.7C443.4 17.7 443.6 17.6 443.8 17.4L448.4 12.8C448.6 12.8 448.7 12.9 448.9 12.9C449.4 12.9 449.9 12.7 450.3 12.3L484.1 -21.5C484.5 -21.9 484.7 -22.4 484.7 -23L492 -30.3C492.3 -30.6 492.4 -31 492.4 -31.4L499.5 -38.5C499.7 -38.7 499.8 -39 499.7 -39.3L692 -231.3L691.3 -232L499 -39.8C498.7 -39.8 498.4 -39.8 498.2 -39.6L491.1 -32.5C490.7 -32.5 490.3 -32.4 490 -32.1L482.7 -24.8C482.2 -24.8 481.6 -24.6 481.2 -24.2L447.4 9.60001C446.9 10.1 446.7 10.9 446.9 11.5L442.3 16.1C442 16.4 442 16.7 442.1 17.1L221 238.3C220.5 238.1 219.9 238.2 219.5 238.6L208.9 249.2C208.4 249.2 207.8 249.4 207.4 249.8L173.6 283.6C173.2 284 173 284.5 173 285.1L162.3 295.8C162 296.1 161.9 296.4 161.9 296.7L154.8 303.8C154.5 304.1 154.5 304.4 154.6 304.8L-33.3 492.6L-32.6 493.3L155.3 305.4C155.4 305.4 155.5 305.4 155.5 305.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M150.8 298.2C151.1 298.2 151.3 298.1 151.5 297.9L158.6 290.8C158.9 290.8 159.3 290.7 159.5 290.4L170.2 279.7C170.2 279.7 170.2 279.7 170.3 279.7C170.8 279.7 171.3 279.5 171.7 279.1L205.9 244.9C206.3 244.5 206.5 244 206.5 243.4L217.3 232.6C217.7 232.2 217.8 231.6 217.6 231.1L435.3 13.4C435.4 13.4 435.5 13.4 435.5 13.4C435.8 13.4 436 13.3 436.2 13.1L440.8 8.5C441 8.5 441.1 8.60001 441.3 8.60001C441.8 8.60001 442.3 8.39999 442.7 8L476.9 -26.2C477.3 -26.6 477.5 -27.1 477.5 -27.7L484.6 -34.8C484.9 -35.1 485 -35.5 485 -35.9L492.1 -43C492.3 -43.2 492.4 -43.5 492.3 -43.8L686 -237.3L685.3 -238L491.6 -44.3C491.3 -44.3 491 -44.3 490.8 -44.1L483.7 -37C483.3 -37 482.9 -36.9 482.6 -36.6L475.5 -29.5C475 -29.5 474.4 -29.3 474 -28.9L439.8 5.3C439.3 5.8 439.1 6.59999 439.3 7.2L434.7 11.8C434.4 12.1 434.4 12.4 434.5 12.8L216.8 230.4C216.3 230.2 215.7 230.3 215.3 230.7L204.5 241.5C204 241.5 203.4 241.7 203 242.1L168.8 276.3C168.4 276.7 168.2 277.2 168.2 277.8L157.5 288.5C157.2 288.8 157.1 289.1 157.1 289.4L150 296.5C149.7 296.8 149.7 297.1 149.8 297.5L-39.3 486.6L-38.6 487.3L150.5 298.2C150.6 298.2 150.7 298.2 150.8 298.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M146.2 290.7C146.5 290.7 146.7 290.6 146.9 290.4L154 283.3C154.3 283.3 154.7 283.2 154.9 282.9L165.6 272.2C165.6 272.2 165.6 272.2 165.7 272.2C166.2 272.2 166.7 272 167.1 271.6L201.7 237C202.1 236.6 202.3 236.1 202.3 235.5L213.4 224.4C213.8 224 213.9 223.4 213.7 222.9L427.4 9.2C427.5 9.2 427.6 9.2 427.6 9.2C427.9 9.2 428.1 9.09999 428.3 8.89999L432.9 4.3C433.1 4.3 433.2 4.39999 433.4 4.39999C433.9 4.39999 434.4 4.2 434.8 3.8L469.4 -30.8C469.8 -31.2 470 -31.7 470 -32.3L476.9 -39.2C477.2 -39.5 477.3 -39.9 477.3 -40.3L484.4 -47.4C484.6 -47.6 484.7 -47.9 484.6 -48.2L680 -243.3L679.3 -244L483.9 -48.6C483.6 -48.6 483.3 -48.6 483.1 -48.4L476 -41.3C475.6 -41.3 475.2 -41.2 474.9 -40.9L468 -34C467.5 -34 466.9 -33.8 466.5 -33.4L432 1.10001C431.5 1.60001 431.3 2.39999 431.5 3L426.9 7.60001C426.6 7.90001 426.6 8.20001 426.7 8.60001L213 222.3C212.5 222.1 211.9 222.2 211.5 222.6L200.4 233.7C199.9 233.7 199.3 233.9 198.9 234.3L164.3 268.9C163.9 269.3 163.7 269.8 163.7 270.4L153 281C152.7 281.3 152.6 281.6 152.6 281.9L145.5 289C145.2 289.3 145.2 289.6 145.3 290L-45.3 480.6L-44.6 481.3L146 290.7C146.1 290.7 146.1 290.7 146.2 290.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M141.9 283C142.2 283 142.4 282.9 142.6 282.7L149.7 275.6C150 275.6 150.4 275.5 150.6 275.2L161.3 264.5C161.8 264.5 162.3 264.3 162.7 263.9L197.8 228.8C198.2 228.4 198.4 227.9 198.4 227.3L210.2 215.5C210.6 215.1 210.7 214.5 210.5 214L419.2 5.3C419.3 5.3 419.4 5.3 419.4 5.3C419.7 5.3 419.9 5.2 420.1 5L424.7 0.399994C424.9 0.399994 425 0.5 425.2 0.5C425.7 0.5 426.2 0.299988 426.6 -0.100006L461.7 -35.2C462.1 -35.6 462.3 -36.1 462.3 -36.7L468.9 -43.3C469.2 -43.6 469.3 -44 469.3 -44.4L476.4 -51.5C476.6 -51.7 476.7 -52 476.6 -52.3L674 -249.3L673.3 -250L475.9 -52.7C475.6 -52.7 475.3 -52.7 475.1 -52.5L468 -45.4C467.6 -45.4 467.2 -45.3 466.9 -45L460.3 -38.4C459.8 -38.4 459.2 -38.2 458.8 -37.8L423.7 -2.7C423.2 -2.2 423 -1.40001 423.2 -0.800003L418.6 3.8C418.3 4.10001 418.3 4.40001 418.4 4.8L209.9 213.4C209.4 213.2 208.8 213.3 208.4 213.7L196.6 225.5C196.1 225.5 195.5 225.7 195.1 226.1L160 261.1C159.6 261.5 159.4 262 159.4 262.6L148.7 273.3C148.4 273.6 148.3 273.9 148.3 274.2L141.2 281.3C140.9 281.6 140.9 281.9 141 282.3L-51.3 474.5L-50.6 475.2L141.7 283C141.8 283 141.8 283 141.9 283Z"
                fill="#1D6BFC"
              />
              <path
                d="M137.9 275.1C138.2 275.1 138.4 275 138.6 274.8L145.7 267.7C146 267.7 146.4 267.6 146.6 267.3L157.3 256.6C157.3 256.6 157.3 256.6 157.4 256.6C157.9 256.6 158.4 256.4 158.8 256L194.8 220C195.2 219.6 195.4 219.1 195.4 218.5L207.3 206.6C207.7 206.2 207.8 205.6 207.6 205.1L410.5 2.20001C410.6 2.20001 410.7 2.20001 410.7 2.20001C411 2.20001 411.2 2.10001 411.4 1.89999L416 -2.7C416.2 -2.7 416.3 -2.60001 416.5 -2.60001C417 -2.60001 417.5 -2.8 417.9 -3.2L453.9 -39.2C454.3 -39.6 454.5 -40.1 454.5 -40.7L460.8 -47C461.1 -47.3 461.2 -47.7 461.2 -48.1L468.3 -55.2C468.5 -55.4 468.6 -55.7 468.5 -56L668 -255.3L667.3 -256L467.7 -56.4C467.4 -56.4 467.1 -56.4 466.9 -56.2L459.8 -49.1C459.4 -49.1 459 -49 458.7 -48.7L452.4 -42.4C451.9 -42.4 451.3 -42.2 450.9 -41.8L414.9 -5.8C414.4 -5.3 414.2 -4.5 414.4 -3.89999L409.8 0.700012C409.5 1 409.5 1.30002 409.6 1.70001L206.8 204.5C206.3 204.3 205.7 204.4 205.3 204.8L193.4 216.7C192.9 216.7 192.3 216.9 191.9 217.3L155.9 253.3C155.5 253.7 155.3 254.2 155.3 254.8L144.6 265.5C144.3 265.8 144.2 266.1 144.2 266.4L137.1 273.5C136.8 273.8 136.8 274.1 136.9 274.5L-57.3 468.5L-56.6 469.2L137.6 275C137.7 275 137.8 275.1 137.9 275.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M134.1 266.8C134.4 266.8 134.6 266.7 134.8 266.5L141.9 259.4C142.2 259.4 142.6 259.3 142.8 259L153.5 248.3C153.5 248.3 153.5 248.3 153.6 248.3C154.1 248.3 154.6 248.1 155 247.7L191.7 211C192.1 210.6 192.3 210.1 192.3 209.5L204.5 197.3C204.9 196.9 205 196.3 204.8 195.8L401.5 -0.899994C401.6 -0.899994 401.7 -0.899994 401.7 -0.899994C402 -0.899994 402.2 -1 402.4 -1.20001L407 -5.79999C407.2 -5.79999 407.3 -5.70001 407.5 -5.70001C408 -5.70001 408.5 -5.89999 408.9 -6.3L445.6 -43C446 -43.4 446.2 -43.9 446.2 -44.5L452.3 -50.6C452.6 -50.9 452.7 -51.3 452.7 -51.7L459.8 -58.8C460 -59 460.1 -59.3 460 -59.6L662 -261.3L661.3 -262L459.2 -59.9C458.9 -59.9 458.6 -59.9 458.4 -59.7L451.3 -52.6C450.9 -52.6 450.5 -52.5 450.2 -52.2L444.1 -46.1C443.6 -46.1 443 -45.9 442.6 -45.5L406 -8.89999C405.5 -8.39999 405.3 -7.60001 405.5 -7L400.9 -2.39999C400.6 -2.10001 400.6 -1.79999 400.7 -1.39999L204 195.2C203.5 195 202.9 195.1 202.5 195.5L190.3 207.7C189.8 207.7 189.2 207.9 188.8 208.3L152.1 245C151.7 245.4 151.5 245.9 151.5 246.5L140.8 257.2C140.5 257.5 140.4 257.8 140.4 258.1L133.3 265.2C133 265.5 133 265.8 133.1 266.2L-63.3 462.5L-62.6 463.2L133.9 266.7C133.9 266.8 134 266.8 134.1 266.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M128.5 248.4C128.8 248.4 129 248.3 129.2 248.1L136.3 241C136.6 241 137 240.9 137.2 240.6L147.9 229.9C148.4 229.9 148.9 229.7 149.3 229.3L187.9 190.7C188.3 190.3 188.5 189.8 188.5 189.2L202.2 175.5C202.6 175.1 202.7 174.5 202.5 174L382.7 -6.20001C382.8 -6.20001 382.9 -6.20001 382.9 -6.20001C383.2 -6.20001 383.4 -6.29999 383.6 -6.5L388.2 -11.1C388.4 -11.1 388.5 -11 388.7 -11C389.2 -11 389.7 -11.2 390.1 -11.6L427 -48.5C427.4 -48.9 427.6 -49.4 427.6 -50L432.8 -55.2C433.1 -55.5 433.2 -55.9 433.2 -56.3L440.3 -63.4C440.5 -63.6 440.6 -63.9 440.5 -64.2L650 -273.3L649.3 -274L439.9 -64.7C439.6 -64.7 439.3 -64.7 439.1 -64.5L432 -57.4C431.6 -57.4 431.2 -57.3 430.9 -57L425.7 -51.8C425.2 -51.8 424.6 -51.6 424.2 -51.2L387.3 -14.3C386.8 -13.8 386.6 -13 386.8 -12.4L382.2 -7.79999C381.9 -7.5 381.9 -7.19998 382 -6.79999L201.9 173.4C201.4 173.2 200.8 173.3 200.4 173.7L186.7 187.4C186.2 187.4 185.6 187.6 185.2 188L146.6 226.6C146.2 227 146 227.5 146 228.1L135.3 238.8C135 239.1 134.9 239.4 134.9 239.7L127.8 246.8C127.5 247.1 127.5 247.4 127.6 247.8L-75.3 450.5L-74.6 451.2L128.3 248.3C128.3 248.4 128.4 248.4 128.5 248.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M125.9 239.1C126.2 239.1 126.4 239 126.6 238.8L133.7 231.7C134 231.7 134.4 231.6 134.6 231.3L145.3 220.6C145.3 220.6 145.3 220.6 145.4 220.6C145.9 220.6 146.4 220.4 146.8 220L186.8 180C187.2 179.6 187.4 179.1 187.4 178.5L201.7 164.2C202.1 163.8 202.2 163.2 202 162.7L372.7 -8C372.8 -8 372.9 -8 372.9 -8C373.2 -8 373.4 -8.09998 373.6 -8.29999L378.2 -12.9C378.4 -12.9 378.5 -12.8 378.7 -12.8C379.2 -12.8 379.7 -13 380.1 -13.4L417.8 -51.1C418.2 -51.5 418.4 -52 418.4 -52.6L423.4 -57.6C423.7 -57.9 423.8 -58.3 423.8 -58.7L430.9 -65.8C431.1 -66 431.2 -66.3 431.1 -66.6L644 -279.3L643.3 -280L430.3 -67.1C430 -67.1 429.7 -67.1 429.5 -66.9L422.4 -59.8C422 -59.8 421.6 -59.7 421.3 -59.4L416.3 -54.4C415.8 -54.4 415.2 -54.2 414.8 -53.8L377.1 -16.1C376.6 -15.6 376.4 -14.8 376.6 -14.2L372 -9.60001C371.7 -9.30002 371.7 -9 371.8 -8.60001L201.2 162C200.7 161.8 200.1 161.9 199.7 162.3L185.4 176.6C184.9 176.6 184.3 176.8 183.9 177.2L143.9 217.2C143.5 217.6 143.3 218.1 143.3 218.7L132.6 229.4C132.3 229.7 132.2 230 132.2 230.3L125.1 237.4C124.8 237.7 124.8 238 124.9 238.4L-81.3 444.5L-80.6 445.2L125.6 239C125.7 239.1 125.8 239.1 125.9 239.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M123.5 229.5C123.8 229.5 124 229.4 124.2 229.2L131.3 222.1C131.6 222.1 132 222 132.2 221.7L142.9 211C142.9 211 142.9 211 143 211C143.5 211 144 210.8 144.4 210.4L186.2 168.6C186.6 168.2 186.8 167.7 186.8 167.1L202.2 151.7C202.6 151.3 202.7 150.7 202.5 150.2L361.6 -9C361.7 -9 361.8 -9 361.8 -9C362.1 -9 362.3 -9.09998 362.5 -9.29999L367.1 -13.9C367.3 -13.9 367.4 -13.8 367.6 -13.8C368.1 -13.8 368.6 -14 369 -14.4L408.1 -53.5C408.5 -53.9 408.7 -54.4 408.7 -55L413.4 -59.7C413.7 -60 413.8 -60.4 413.8 -60.8L420.9 -67.9C421.1 -68.1 421.2 -68.4 421.1 -68.7L638 -285.3L637.3 -286L420.4 -69.2C420.1 -69.2 419.8 -69.2 419.6 -69L412.5 -61.9C412.1 -61.9 411.7 -61.8 411.4 -61.5L406.7 -56.8C406.2 -56.8 405.6 -56.6 405.2 -56.2L366.1 -17.1C365.6 -16.6 365.4 -15.8 365.6 -15.2L361 -10.6C360.7 -10.3 360.7 -10 360.8 -9.60001L201.7 149.5C201.2 149.3 200.6 149.4 200.2 149.8L184.8 165.2C184.3 165.2 183.7 165.4 183.3 165.8L141.5 207.6C141.1 208 140.9 208.5 140.9 209.1L130.2 219.8C129.9 220.1 129.8 220.4 129.8 220.7L122.7 227.8C122.4 228.1 122.4 228.4 122.5 228.8L-87.3 438.5L-86.6 439.2L123.2 229.4C123.3 229.5 123.4 229.5 123.5 229.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M121.3 219.6C121.6 219.6 121.8 219.5 122 219.3L129.1 212.2C129.4 212.2 129.8 212.1 130 211.8L140.7 201.1C140.7 201.1 140.7 201.1 140.8 201.1C141.3 201.1 141.8 200.9 142.2 200.5L186.6 156.1C187 155.7 187.2 155.2 187.2 154.6L203.6 138.2C204 137.8 204.1 137.2 203.9 136.7L350.2 -9.60001C350.3 -9.60001 350.4 -9.60001 350.4 -9.60001C350.7 -9.60001 350.9 -9.69998 351.1 -9.89999L355.7 -14.5C355.9 -14.5 356 -14.4 356.2 -14.4C356.7 -14.4 357.2 -14.6 357.6 -15L398.2 -55.6C398.6 -56 398.8 -56.5 398.8 -57.1L403.4 -61.7C403.7 -62 403.8 -62.4 403.8 -62.8L410.9 -69.9C411.1 -70.1 411.2 -70.4 411.1 -70.7L632 -291.3L631.3 -292L410.5 -71.2C410.2 -71.2 409.9 -71.2 409.7 -71L402.6 -63.9C402.2 -63.9 401.8 -63.8 401.5 -63.5L396.9 -58.9C396.4 -58.9 395.8 -58.7 395.4 -58.3L354.8 -17.7C354.3 -17.2 354.1 -16.4 354.3 -15.8L349.7 -11.2C349.4 -10.9 349.4 -10.6 349.5 -10.2L203.2 136C202.7 135.8 202.1 135.9 201.7 136.3L185.3 152.7C184.8 152.7 184.2 152.9 183.8 153.3L139.4 197.7C139 198.1 138.8 198.6 138.8 199.2L128.1 209.9C127.8 210.2 127.7 210.5 127.7 210.8L120.6 217.9C120.3 218.2 120.3 218.5 120.4 218.9L-93.3 432.5L-92.6 433.2L121.1 219.5C121.2 219.6 121.3 219.6 121.3 219.6Z"
                fill="#1D6BFC"
              />
              <path
                d="M119.3 209.6C119.6 209.6 119.8 209.5 120 209.3L127.1 202.2C127.4 202.2 127.8 202.1 128 201.8L138.7 191.1C139.2 191.1 139.7 190.9 140.1 190.5L188 142.6C188.4 142.2 188.6 141.7 188.6 141.1L206.5 123.2C206.9 122.8 207 122.2 206.8 121.7L338.7 -10C338.8 -10 338.9 -10 338.9 -10C339.2 -10 339.4 -10.1 339.6 -10.3L344.2 -14.9C344.4 -14.9 344.5 -14.8 344.7 -14.8C345.2 -14.8 345.7 -15 346.1 -15.4L388.3 -57.6C389 -58.3 389.1 -59.3 388.6 -60L626 -297.3L625.3 -298L387.9 -60.7C387.1 -61.2 386.1 -61.1 385.5 -60.4L343.3 -18.2C342.8 -17.7 342.6 -16.9 342.8 -16.3L338.2 -11.7C337.9 -11.4 337.9 -11.1 338 -10.7L206.1 121.1C205.6 120.9 205 121 204.6 121.4L197.3 128.7L186.7 139.3C186.2 139.3 185.6 139.5 185.2 139.9L137.3 187.8C136.9 188.2 136.7 188.7 136.7 189.3L126 200C125.7 200.3 125.6 200.6 125.6 200.9L118.5 208C118.2 208.3 118.2 208.6 118.3 209L-99.3 426.5L-98.6 427.2L119.1 209.5C119.1 209.6 119.2 209.6 119.3 209.6Z"
                fill="#1D6BFC"
              />
              <path
                d="M161.6 47.2C161.9 47.2 162.1 47.1 162.3 46.9L169.4 39.8C169.7 39.8 170.1 39.7 170.3 39.4L181 28.7C181 28.7 181 28.7 181.1 28.7C181.6 28.7 182.1 28.5 182.5 28.1L228.5 -17.9C229.2 -18.6 229.3 -19.6 228.8 -20.3L565.9 -357.4L565.2 -358.1L228.1 -20.9C227.3 -21.4 226.3 -21.3 225.7 -20.6L179.7 25.4C179.3 25.8 179.1 26.3 179.1 26.9L168.4 37.6C168.1 37.9 168 38.2 168 38.5L160.9 45.6C160.6 45.9 160.6 46.2 160.7 46.6L-159.3 366.6L-158.6 367.3L161.4 47.3C161.5 47.2 161.6 47.2 161.6 47.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M118.6 150.6C118.9 150.6 119.1 150.5 119.3 150.3L126.5 143C126.7 142.9 127 142.8 127.2 142.7L137.9 132C137.9 132 137.9 132 138 132C138.5 132 139 131.8 139.4 131.4L328 -57.3C328.4 -57.7 328.6 -58.2 328.6 -58.8L329.3 -59.5C329.7 -59.9 329.8 -60.5 329.6 -61L595.9 -327.3L595.2 -328L328.9 -61.7C328.4 -61.9 327.8 -61.8 327.4 -61.4L326.7 -60.7C326.2 -60.7 325.6 -60.5 325.2 -60.1L136.5 128.6C136.1 129 135.9 129.5 135.9 130.1L125.2 140.8C124.9 141.1 124.8 141.5 124.8 141.9L117.8 148.9C117.6 149.1 117.5 149.4 117.5 149.7L-129.4 396.6L-128.7 397.3L118.1 150.5C118.3 150.5 118.4 150.6 118.6 150.6Z"
                fill="#1D6BFC"
              />
            </g>
            <g clip-path="url(#clip1_41299_70617)">
              <path
                d="M836.5 201.9C836.8 201.9 837 201.8 837.2 201.6L844.3 194.5C844.6 194.5 845 194.4 845.2 194.1L846.2 193.1L847.5 191.8L855.9 183.4C855.9 183.4 855.9 183.4 856 183.4C856.5 183.4 857 183.2 857.4 182.8L858.4 181.8L911.2 129C911.6 128.6 911.8 128.1 911.8 127.5L921.2 118.1L931.1 108.2C931.6 107.7 931.6 106.9 931.2 106.4L1339.9 -302.3L1339.2 -303L930.3 105.9C929.9 105.9 929.5 106 929.2 106.3L909.9 125.6C909.4 125.6 908.8 125.8 908.4 126.2L901.1 133.5L854.6 180C854.2 180.4 854 180.9 854 181.5L844.3 191.2L843.3 192.2C843 192.5 842.9 192.8 842.9 193.1L836.8 199.2L835.8 200.2C835.7 200.3 835.6 200.5 835.6 200.6L614.7 421.5L615.4 422.2L836 201.7C836.1 201.8 836.3 201.9 836.5 201.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M1279.27 -363.078L554.705 361.488L555.412 362.195L1279.98 -362.37L1279.27 -363.078Z"
                fill="#1D6BFC"
              />
              <path
                d="M1273.27 -369.079L548.706 355.486L549.413 356.193L1273.98 -368.372L1273.27 -369.079Z"
                fill="#1D6BFC"
              />
              <path
                d="M1267.27 -375.081L542.707 349.484L543.414 350.191L1267.98 -374.374L1267.27 -375.081Z"
                fill="#1D6BFC"
              />
              <path
                d="M1261.27 -381.083L536.708 343.482L537.415 344.189L1261.98 -380.376L1261.27 -381.083Z"
                fill="#1D6BFC"
              />
              <path
                d="M1255.27 -387.085L530.708 337.48L531.415 338.187L1255.98 -386.378L1255.27 -387.085Z"
                fill="#1D6BFC"
              />
              <path
                d="M1249.2 -393.017L524.638 331.549L525.346 332.256L1249.91 -392.31L1249.2 -393.017Z"
                fill="#1D6BFC"
              />
              <path
                d="M1243.2 -399.019L518.639 325.547L519.347 326.254L1243.91 -398.311L1243.2 -399.019Z"
                fill="#1D6BFC"
              />
              <path
                d="M1237.21 -405.021L512.64 319.545L513.347 320.252L1237.91 -404.313L1237.21 -405.021Z"
                fill="#1D6BFC"
              />
              <path
                d="M1231.21 -411.022L506.641 313.543L507.348 314.25L1231.91 -410.315L1231.21 -411.022Z"
                fill="#1D6BFC"
              />
              <path
                d="M1225.21 -417.024L500.642 307.541L501.349 308.248L1225.91 -416.317L1225.21 -417.024Z"
                fill="#1D6BFC"
              />
              <path
                d="M1219.21 -423.027L494.644 301.539L495.351 302.246L1219.92 -422.32L1219.21 -423.027Z"
                fill="#1D6BFC"
              />
              <path
                d="M1213.21 -429.029L488.644 295.537L489.351 296.244L1213.92 -428.321L1213.21 -429.029Z"
                fill="#1D6BFC"
              />
              <path
                d="M1207.21 -435.031L482.645 289.535L483.352 290.242L1207.92 -434.324L1207.21 -435.031Z"
                fill="#1D6BFC"
              />
              <path
                d="M1201.21 -441.033L476.646 283.533L477.353 284.24L1201.92 -440.326L1201.21 -441.033Z"
                fill="#1D6BFC"
              />
              <path
                d="M1195.21 -447.035L470.647 277.53L471.354 278.238L1195.92 -446.328L1195.21 -447.035Z"
                fill="#1D6BFC"
              />
              <path
                d="M1189.21 -453.037L464.648 271.528L465.355 272.236L1189.92 -452.33L1189.21 -453.037Z"
                fill="#1D6BFC"
              />
              <path
                d="M1183.21 -459.039L458.648 265.527L459.356 266.234L1183.92 -458.332L1183.21 -459.039Z"
                fill="#1D6BFC"
              />
              <path
                d="M1177.21 -465.041L452.649 259.525L453.357 260.232L1177.92 -464.334L1177.21 -465.041Z"
                fill="#1D6BFC"
              />
              <path
                d="M1171.22 -471.043L446.65 253.523L447.357 254.23L1171.92 -470.336L1171.22 -471.043Z"
                fill="#1D6BFC"
              />
              <path
                d="M1165.15 -477.115L440.581 247.45L441.288 248.157L1165.85 -476.408L1165.15 -477.115Z"
                fill="#1D6BFC"
              />
              <path
                d="M1159.15 -483.117L434.582 241.448L435.289 242.155L1159.85 -482.41L1159.15 -483.117Z"
                fill="#1D6BFC"
              />
              <path
                d="M1153.15 -489.119L428.582 235.446L429.289 236.153L1153.85 -488.412L1153.15 -489.119Z"
                fill="#1D6BFC"
              />
              <path
                d="M1147.15 -495.121L422.583 229.444L423.29 230.151L1147.86 -494.414L1147.15 -495.121Z"
                fill="#1D6BFC"
              />
              <path
                d="M1141.15 -501.123L416.584 223.442L417.291 224.149L1141.86 -500.416L1141.15 -501.123Z"
                fill="#1D6BFC"
              />
              <path
                d="M1135.15 -507.125L410.585 217.44L411.292 218.147L1135.86 -506.418L1135.15 -507.125Z"
                fill="#1D6BFC"
              />
              <path
                d="M1129.15 -513.127L404.586 211.438L405.293 212.145L1129.86 -512.42L1129.15 -513.127Z"
                fill="#1D6BFC"
              />
              <path
                d="M1123.15 -519.129L398.587 205.436L399.294 206.143L1123.86 -518.422L1123.15 -519.129Z"
                fill="#1D6BFC"
              />
              <path
                d="M1117.15 -525.131L392.588 199.434L393.295 200.141L1117.86 -524.424L1117.15 -525.131Z"
                fill="#1D6BFC"
              />
              <path
                d="M1111.15 -531.133L386.589 193.432L387.296 194.139L1111.86 -530.426L1111.15 -531.133Z"
                fill="#1D6BFC"
              />
              <path
                d="M1105.15 -537.135L380.59 187.43L381.297 188.137L1105.86 -536.428L1105.15 -537.135Z"
                fill="#1D6BFC"
              />
              <path
                d="M1099.16 -543.137L374.59 181.428L375.297 182.135L1099.86 -542.43L1099.16 -543.137Z"
                fill="#1D6BFC"
              />
              <path
                d="M1093.16 -549.139L368.591 175.426L369.298 176.133L1093.86 -548.432L1093.16 -549.139Z"
                fill="#1D6BFC"
              />
              <path
                d="M1087.16 -555.141L362.592 169.424L363.299 170.131L1087.86 -554.434L1087.16 -555.141Z"
                fill="#1D6BFC"
              />
              <path
                d="M1081.16 -561.143L356.593 163.422L357.3 164.129L1081.87 -560.436L1081.16 -561.143Z"
                fill="#1D6BFC"
              />
              <path d="M1783.7 141.463L1059.14 866.029L1059.85 866.736L1784.41 142.17L1783.7 141.463Z" fill="#1D6BFC" />
              <path
                d="M1777.71 135.461L1053.14 860.026L1053.85 860.734L1778.41 136.168L1777.71 135.461Z"
                fill="#1D6BFC"
              />
              <path
                d="M1771.71 129.459L1047.14 854.025L1047.85 854.732L1772.41 130.166L1771.71 129.459Z"
                fill="#1D6BFC"
              />
              <path
                d="M1765.71 123.457L1041.14 848.023L1041.85 848.73L1766.41 124.164L1765.71 123.457Z"
                fill="#1D6BFC"
              />
              <path
                d="M1759.64 117.385L1035.07 841.95L1035.78 842.657L1760.34 118.092L1759.64 117.385Z"
                fill="#1D6BFC"
              />
              <path
                d="M1753.64 111.383L1029.07 835.948L1029.78 836.655L1754.35 112.09L1753.64 111.383Z"
                fill="#1D6BFC"
              />
              <path
                d="M1747.64 105.381L1023.07 829.946L1023.78 830.653L1748.35 106.088L1747.64 105.381Z"
                fill="#1D6BFC"
              />
              <path
                d="M1741.64 99.3791L1017.08 823.944L1017.78 824.651L1742.35 100.086L1741.64 99.3791Z"
                fill="#1D6BFC"
              />
              <path
                d="M1735.64 93.3765L1011.08 817.942L1011.78 818.649L1736.35 94.0836L1735.64 93.3765Z"
                fill="#1D6BFC"
              />
              <path
                d="M1729.64 87.3747L1005.08 811.94L1005.78 812.647L1730.35 88.0818L1729.64 87.3747Z"
                fill="#1D6BFC"
              />
              <path
                d="M1723.64 81.3726L999.078 805.938L999.785 806.645L1724.35 82.0797L1723.64 81.3726Z"
                fill="#1D6BFC"
              />
              <path
                d="M1717.64 75.3708L993.079 799.936L993.786 800.643L1718.35 76.0779L1717.64 75.3708Z"
                fill="#1D6BFC"
              />
              <path
                d="M1711.64 69.3687L987.08 793.934L987.787 794.641L1712.35 70.0758L1711.64 69.3687Z"
                fill="#1D6BFC"
              />
              <path
                d="M1705.65 63.3669L981.081 787.932L981.788 788.639L1706.35 64.074L1705.65 63.3669Z"
                fill="#1D6BFC"
              />
              <path
                d="M1699.65 57.3648L975.082 781.93L975.789 782.637L1700.35 58.0719L1699.65 57.3648Z"
                fill="#1D6BFC"
              />
              <path d="M1693.65 51.363L969.083 775.928L969.79 776.635L1694.35 52.0701L1693.65 51.363Z" fill="#1D6BFC" />
              <path
                d="M1687.65 45.3609L963.083 769.926L963.79 770.633L1688.36 46.068L1687.65 45.3609Z"
                fill="#1D6BFC"
              />
              <path
                d="M1681.65 39.3591L957.084 763.924L957.791 764.631L1682.36 40.0662L1681.65 39.3591Z"
                fill="#1D6BFC"
              />
              <path
                d="M1675.65 33.357L951.085 757.922L951.792 758.629L1676.36 34.0641L1675.65 33.357Z"
                fill="#1D6BFC"
              />
              <path d="M1669.65 27.355L945.086 751.92L945.793 752.627L1670.36 28.0621L1669.65 27.355Z" fill="#1D6BFC" />
              <path
                d="M1663.65 21.3533L939.087 745.919L939.794 746.626L1664.36 22.0604L1663.65 21.3533Z"
                fill="#1D6BFC"
              />
              <path
                d="M1657.65 15.3509L933.088 739.916L933.795 740.623L1658.36 16.058L1657.65 15.3509Z"
                fill="#1D6BFC"
              />
              <path
                d="M1651.65 9.34857L927.089 733.914L927.796 734.621L1652.36 10.0557L1651.65 9.34857Z"
                fill="#1D6BFC"
              />
              <path d="M1645.66 3.3465L921.09 727.912L921.797 728.619L1646.36 4.0536L1645.66 3.3465Z" fill="#1D6BFC" />
              <path
                d="M1639.66 -2.65546L915.091 721.91L915.798 722.617L1640.36 -1.94836L1639.66 -2.65546Z"
                fill="#1D6BFC"
              />
              <path
                d="M1633.66 -8.65741L909.092 715.908L909.799 716.615L1634.36 -7.95031L1633.66 -8.65741Z"
                fill="#1D6BFC"
              />
              <path
                d="M1627.66 -14.6594L903.092 709.906L903.799 710.613L1628.36 -13.9523L1627.66 -14.6594Z"
                fill="#1D6BFC"
              />
              <path
                d="M1621.66 -20.6613L897.093 703.904L897.8 704.611L1622.37 -19.9542L1621.66 -20.6613Z"
                fill="#1D6BFC"
              />
              <path
                d="M1615.66 -26.6633L891.094 697.902L891.801 698.609L1616.37 -25.9562L1615.66 -26.6633Z"
                fill="#1D6BFC"
              />
              <path
                d="M1609.66 -32.6652L885.095 691.9L885.802 692.607L1610.37 -31.9581L1609.66 -32.6652Z"
                fill="#1D6BFC"
              />
              <path
                d="M1238.3 220.3C1238.6 220.3 1238.8 220.2 1239 220L1243.6 215.4C1243.8 215.4 1243.9 215.5 1244.1 215.5C1244.6 215.5 1245.1 215.3 1245.5 214.9L1304.7 155.7C1305.1 155.3 1305.3 154.8 1305.3 154.2L1317.8 141.7C1318.1 141.4 1318.2 141 1318.2 140.6L1325.3 133.5C1325.5 133.3 1325.6 133 1325.5 132.7L1550.3 -92.1L1549.6 -92.8L1324.8 132.1C1324.5 132.1 1324.2 132.1 1324 132.3L1316.9 139.4C1316.5 139.4 1316.1 139.5 1315.8 139.8L1303.3 152.3C1302.8 152.3 1302.2 152.5 1301.8 152.9L1242.6 212.1C1242.1 212.6 1241.9 213.4 1242.1 214L1237.5 218.6C1237.2 218.9 1237.2 219.2 1237.3 219.6L825.1 631.9L825.8 632.6L1238.1 220.3C1238.2 220.3 1238.3 220.3 1238.3 220.3Z"
                fill="#1D6BFC"
              />
              <path
                d="M1228.5 242.1C1228.8 242.1 1229 242 1229.2 241.8L1233.8 237.2C1234 237.2 1234.1 237.3 1234.3 237.3C1234.8 237.3 1235.3 237.1 1235.7 236.7L1303.6 168.8C1304 168.4 1304.2 167.9 1304.2 167.3L1319.9 151.6C1320.2 151.3 1320.3 150.9 1320.3 150.5L1327.4 143.4C1327.6 143.2 1327.7 142.9 1327.6 142.6L1556.3 -86.1L1555.6 -86.8L1326.9 142.1C1326.6 142.1 1326.3 142.1 1326.1 142.3L1319 149.4C1318.6 149.4 1318.2 149.5 1317.9 149.8L1302.2 165.5C1301.7 165.5 1301.1 165.7 1300.7 166.1L1232.8 234C1232.3 234.5 1232.1 235.3 1232.3 235.9L1227.7 240.5C1227.4 240.8 1227.4 241.1 1227.5 241.5L831.1 637.9L831.8 638.6L1228.3 242.1C1228.4 242.1 1228.5 242.1 1228.5 242.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M1094.7 388C1095 388 1095.2 387.9 1095.4 387.7L1100 383.1C1100.2 383.1 1100.3 383.2 1100.5 383.2C1101 383.2 1101.5 383 1101.9 382.6L1303 181.5C1303.4 181.1 1303.6 180.6 1303.6 180L1318.8 164.8C1319.1 164.5 1319.2 164.1 1319.2 163.7L1326.3 156.6C1326.5 156.4 1326.6 156.1 1326.5 155.8L1562.3 -80L1561.6 -80.7L1325.8 155.2C1325.5 155.2 1325.2 155.2 1325 155.4L1317.9 162.5C1317.5 162.5 1317.1 162.6 1316.8 162.9L1301.6 178.1C1301.1 178.1 1300.5 178.3 1300.1 178.7L1099.1 379.8C1098.6 380.3 1098.4 381.1 1098.6 381.7L1094 386.3C1093.7 386.6 1093.7 386.9 1093.8 387.3L837.1 643.9L837.8 644.6L1094.5 387.9C1094.5 387.9 1094.6 388 1094.7 388Z"
                fill="#1D6BFC"
              />
              <path
                d="M1105.7 389C1106 389 1106.2 388.9 1106.4 388.7L1111 384.1C1111.2 384.1 1111.3 384.2 1111.5 384.2C1112 384.2 1112.5 384 1112.9 383.6L1301.5 195C1301.9 194.6 1302.1 194.1 1302.1 193.5L1318.1 177.5C1318.4 177.2 1318.5 176.8 1318.5 176.4L1325.6 169.3C1325.8 169.1 1325.9 168.8 1325.8 168.5L1568.3 -74L1567.6 -74.7L1325.2 167.8C1324.9 167.8 1324.6 167.8 1324.4 168L1317.3 175.1C1316.9 175.1 1316.5 175.2 1316.2 175.5L1300.2 191.5C1299.7 191.5 1299.1 191.7 1298.7 192.1L1110 380.8C1109.5 381.3 1109.3 382.1 1109.5 382.7L1104.9 387.3C1104.6 387.6 1104.6 387.9 1104.7 388.3L843.1 649.9L843.8 650.6L1105.4 389C1105.5 389 1105.6 389 1105.7 389Z"
                fill="#1D6BFC"
              />
              <path
                d="M1135.3 383.4C1135.6 383.4 1135.8 383.3 1136 383.1L1140.6 378.5C1140.8 378.5 1140.9 378.6 1141.1 378.6C1141.6 378.6 1142.1 378.4 1142.5 378L1294.9 225.6C1295.3 225.2 1295.5 224.7 1295.5 224.1L1314.4 205.2C1314.7 204.9 1314.8 204.5 1314.8 204.1L1321.9 197C1322.1 196.8 1322.2 196.5 1322.1 196.2L1580.4 -62.1L1579.7 -62.8L1321.3 195.7C1321 195.7 1320.7 195.7 1320.5 195.9L1313.4 203C1313 203 1312.6 203.1 1312.3 203.4L1293.4 222.3C1292.9 222.3 1292.3 222.5 1291.9 222.9L1139.7 375.2C1139.2 375.7 1139 376.5 1139.2 377.1L1134.6 381.7C1134.3 382 1134.3 382.3 1134.4 382.7L855.1 661.9L855.8 662.6L1135.1 383.3C1135.1 383.4 1135.2 383.4 1135.3 383.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M1153 377.7C1153.3 377.7 1153.5 377.6 1153.7 377.4L1156.7 374.4C1157 374.4 1157.4 374.3 1157.6 374L1158.7 372.9C1159.2 372.9 1159.7 372.7 1160.1 372.3L1288.6 244C1289 243.6 1289.2 243.1 1289.2 242.5L1310.1 221.6C1310.4 221.3 1310.5 220.9 1310.5 220.5L1317.6 213.4C1317.8 213.2 1317.9 212.9 1317.8 212.6L1586.4 -56L1585.7 -56.7L1317 212C1316.7 212 1316.4 212 1316.2 212.2L1309.1 219.3C1308.7 219.3 1308.3 219.4 1308 219.7L1287.1 240.6C1286.6 240.6 1286 240.8 1285.6 241.2L1157.3 369.5C1156.9 369.9 1156.7 370.4 1156.7 371L1155.6 372.1C1155.3 372.4 1155.2 372.7 1155.2 373L1152.2 376C1151.9 376.3 1151.9 376.6 1152 377L861.1 667.9L861.8 668.6L1152.7 377.7C1152.8 377.7 1152.9 377.7 1153 377.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M1195.9 359.1C1196.3 359.1 1196.6 359 1196.9 358.7L1210.9 344.7C1210.9 344.7 1210.9 344.7 1211 344.7C1211.5 344.7 1212 344.5 1212.4 344.1L1258.4 298.1C1258.8 297.7 1259 297.2 1259 296.6L1293.5 262.1C1293.8 261.8 1293.9 261.4 1293.9 261L1301 253.9C1301.2 253.7 1301.3 253.4 1301.2 253.1L1598.4 -44.1L1597.7 -44.8L1300.5 252.5C1300.2 252.5 1299.9 252.5 1299.7 252.7L1292.6 259.8C1292.2 259.8 1291.8 259.9 1291.5 260.2L1257 294.8C1256.5 294.8 1255.9 295 1255.5 295.4L1209.5 341.4C1209.1 341.8 1208.9 342.3 1208.9 342.9L1194.9 356.9C1194.5 357.3 1194.4 357.9 1194.6 358.4L873.1 679.9L873.8 680.6L1195.4 359C1195.6 359.1 1195.7 359.1 1195.9 359.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M1174.3 368.8C1174.7 368.8 1175 368.7 1175.3 368.4L1180.3 363.4C1180.8 363.4 1181.3 363.2 1181.7 362.8L1278.3 266.2C1278.7 265.8 1278.9 265.3 1278.9 264.7L1303.7 239.9C1304 239.6 1304.1 239.2 1304.1 238.8L1311.2 231.7C1311.4 231.5 1311.5 231.2 1311.4 230.9L1592.3 -50L1591.6 -50.7L1310.7 230.3C1310.4 230.3 1310.1 230.3 1309.9 230.5L1302.8 237.6C1302.4 237.6 1302 237.7 1301.7 238L1277 262.8C1276.5 262.8 1275.9 263 1275.5 263.4L1178.9 360C1178.5 360.4 1178.3 360.9 1178.3 361.5L1173.3 366.5C1172.9 366.9 1172.8 367.5 1173 368L867 674L867.7 674.7L1173.7 368.7C1173.9 368.7 1174.1 368.8 1174.3 368.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M1067.1 379.9C1067.5 379.9 1067.8 379.8 1068.1 379.5L1110.3 337.3C1110.7 336.9 1110.8 336.3 1110.6 335.8L1242.5 203.9C1242.6 203.9 1242.7 203.9 1242.7 203.9C1243 203.9 1243.2 203.8 1243.4 203.6L1248 199C1248.2 199 1248.3 199.1 1248.5 199.1C1249 199.1 1249.5 198.9 1249.9 198.5L1302.7 145.7C1303.1 145.3 1303.3 144.8 1303.3 144.2L1315.8 131.7C1316.1 131.4 1316.2 131 1316.2 130.6L1323.3 123.5C1323.5 123.3 1323.6 123 1323.5 122.7L1544.3 -97.9L1543.6 -98.6L1322.8 122.1C1322.5 122.1 1322.2 122.1 1322 122.3L1314.9 129.4C1314.5 129.4 1314.1 129.5 1313.8 129.8L1301.3 142.3C1300.8 142.3 1300.2 142.5 1299.8 142.9L1247 195.7C1246.5 196.2 1246.3 197 1246.5 197.6L1241.9 202.2C1241.6 202.5 1241.6 202.8 1241.7 203.2L1109.9 335.1C1109.4 334.9 1108.8 335 1108.4 335.4L1066.2 377.6C1065.8 378 1065.7 378.6 1065.9 379.1L819.1 625.9L819.8 626.6L1066.6 379.8C1066.8 379.9 1066.9 379.9 1067.1 379.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M1226.6 340.5C1227 340.5 1227.3 340.4 1227.6 340.1L1273.6 294.1C1273.9 293.8 1274 293.4 1274 293L1281.1 285.9C1281.3 285.7 1281.4 285.4 1281.3 285.1L1604.4 -38L1603.7 -38.7L1280.5 284.5C1280.2 284.5 1279.9 284.5 1279.7 284.7L1272.6 291.8C1272.2 291.8 1271.8 291.9 1271.5 292.2L1225.5 338.2C1225.1 338.6 1225 339.2 1225.2 339.7L879.1 685.9L879.8 686.6L1226 340.4C1226.2 340.5 1226.4 340.5 1226.6 340.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M1119.9 386.8C1120.2 386.8 1120.4 386.7 1120.6 386.5L1125.2 381.9C1125.4 381.9 1125.5 382 1125.7 382C1126.2 382 1126.7 381.8 1127.1 381.4L1299.2 209.3C1299.6 208.9 1299.8 208.4 1299.8 207.8L1316.8 190.8C1317.1 190.5 1317.2 190.1 1317.2 189.7L1324.3 182.6C1324.5 182.4 1324.6 182.1 1324.5 181.8L1574.4 -68.1L1573.7 -68.8L1323.7 181.3C1323.4 181.3 1323.1 181.3 1322.9 181.5L1315.8 188.6C1315.4 188.6 1315 188.7 1314.7 189L1297.7 206C1297.2 206 1296.6 206.2 1296.2 206.6L1124.2 378.6C1123.7 379.1 1123.5 379.9 1123.7 380.5L1119.1 385.1C1118.8 385.4 1118.8 385.7 1118.9 386.1L849.1 655.9L849.8 656.6L1119.7 386.7C1119.7 386.8 1119.8 386.8 1119.9 386.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M902.8 339.2C903.1 339.2 903.3 339.1 903.5 338.9L910.6 331.8C910.9 331.8 911.3 331.7 911.5 331.4L922.2 320.7C922.2 320.7 922.2 320.7 922.3 320.7C922.8 320.7 923.3 320.5 923.7 320.1L956.8 287C957.2 286.6 957.4 286.1 957.4 285.5L966.5 276.4C966.9 276 967 275.4 966.8 274.9L1196.4 45.3C1196.5 45.3 1196.6 45.3 1196.6 45.3C1196.9 45.3 1197.1 45.2 1197.3 45L1201.9 40.4C1202.1 40.4 1202.2 40.5 1202.4 40.5C1202.9 40.5 1203.4 40.3 1203.8 39.9L1236.9 6.8C1237.3 6.40001 1237.5 5.90001 1237.5 5.3L1246.1 -3.3C1246.4 -3.60001 1246.5 -4 1246.5 -4.39999L1253.6 -11.5C1253.8 -11.7 1253.9 -12 1253.8 -12.3L1442 -200.3L1441.3 -201L1253 -12.8C1252.7 -12.8 1252.4 -12.8 1252.2 -12.6L1245.1 -5.5C1244.7 -5.5 1244.3 -5.40001 1244 -5.10001L1235.4 3.5C1234.9 3.5 1234.3 3.70001 1233.9 4.10001L1201 37.2C1200.5 37.7 1200.3 38.5 1200.5 39.1L1195.9 43.7C1195.6 44 1195.6 44.3 1195.7 44.7L966 274.2C965.5 274 964.9 274.1 964.5 274.5L955.4 283.6C954.9 283.6 954.3 283.8 953.9 284.2L920.8 317.3C920.4 317.7 920.2 318.2 920.2 318.8L909.5 329.5C909.2 329.8 909.1 330.1 909.1 330.4L902 337.5C901.7 337.8 901.7 338.1 901.8 338.5L716.7 523.6L717.4 524.3L902.5 339.2C902.6 339.2 902.7 339.2 902.8 339.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M835.2 190.7C835.5 190.7 835.7 190.6 835.9 190.4L843 183.3C843.3 183.3 843.7 183.2 843.9 182.9L854.6 172.2C854.6 172.2 854.6 172.2 854.7 172.2C855.2 172.2 855.7 172 856.1 171.6L905.4 122.3L915.3 112.4C915.7 112 915.9 111.5 915.9 110.9L924.8 102L940.6 86.2C941 85.8 941.1 85.2 940.9 84.7L1333.9 -308.3L1333.2 -309L940.2 84C939.7 83.8 939.1 83.9 938.7 84.3L922.9 100L914 109C913.5 109 912.9 109.2 912.5 109.6L853.3 168.8C852.9 169.2 852.7 169.7 852.7 170.3L842 180.9C841.7 181.2 841.6 181.5 841.6 181.8L834.5 188.9C834.2 189.2 834.2 189.5 834.3 189.9L608.7 415.5L609.4 416.2L835 190.6C835.1 190.7 835.2 190.7 835.2 190.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M834.7 179.2C835 179.2 835.2 179.1 835.4 178.9L837 177.3L842.5 171.8C842.8 171.8 843.2 171.7 843.4 171.4L845 169.8L854.1 160.7C854.1 160.7 854.1 160.7 854.2 160.7C854.7 160.7 855.2 160.5 855.6 160.1L857.2 158.5L909.3 106.4L925.1 90.6C925.5 90.2 925.7 89.7 925.7 89.1L1074.5 -59.7C1074.9 -60.1 1075 -60.7 1074.8 -61.2L1327.9 -314.3L1327.2 -315L1074.1 -61.9C1073.6 -62.1 1073 -62 1072.6 -61.6L923.8 87.2C923.3 87.2 922.7 87.4 922.3 87.8L906.5 103.6L854.4 155.7L852.8 157.3C852.4 157.7 852.2 158.2 852.2 158.8L843.1 167.9L841.5 169.5C841.2 169.8 841.1 170.1 841.1 170.4L834 177.5C833.7 177.8 833.7 178.1 833.8 178.5L602.7 409.6L603.4 410.3L834.5 179.2C834.6 179.2 834.6 179.2 834.7 179.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M837 164.9C837.3 164.9 837.5 164.8 837.7 164.6L844.8 157.5C845.1 157.5 845.5 157.4 845.7 157.1L856.4 146.4C856.4 146.4 856.4 146.4 856.5 146.4C857 146.4 857.5 146.2 857.9 145.8L1059 -55.3C1059.4 -55.7 1059.6 -56.2 1059.6 -56.8L1063.6 -60.8C1064 -61.2 1064.1 -61.8 1063.9 -62.3L1322 -320.4L1321.3 -321.1L1063.1 -62.9C1062.6 -63.1 1062 -63 1061.6 -62.6L1057.6 -58.6C1057.1 -58.6 1056.5 -58.4 1056.1 -58L855 143C854.6 143.4 854.4 143.9 854.4 144.5L843.7 155.2C843.4 155.5 843.3 155.8 843.3 156.1L836.2 163.2C835.9 163.5 835.9 163.8 836 164.2L596.7 403.5L597.4 404.2L836.7 164.9C836.8 164.9 836.9 164.9 837 164.9Z"
                fill="#1D6BFC"
              />
              <path
                d="M840.8 137.1C841.1 137.1 841.3 137 841.5 136.8L848.6 129.7C848.9 129.7 849.3 129.6 849.5 129.3L860.2 118.6C860.2 118.6 860.2 118.6 860.3 118.6C860.8 118.6 861.3 118.4 861.7 118L1033.8 -54.1C1034.3 -54.6 1034.5 -55.4 1034.3 -56.1C1034.3 -56.3 1034.3 -56.5 1034.2 -56.6L1309.9 -332.3L1309.2 -333L1033.5 -57.3C1033.3 -57.4 1033.1 -57.4 1033 -57.4C1032.3 -57.6 1031.6 -57.4 1031 -56.9L858.9 115.2C858.5 115.6 858.3 116.1 858.3 116.7L847.6 127.4C847.3 127.7 847.2 128 847.2 128.3L840.1 135.4C839.8 135.7 839.8 136 839.9 136.4L584.7 391.6L585.4 392.3L840.6 137C840.7 137 840.8 137.1 840.8 137.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M845.1 120.8C845.4 120.8 845.6 120.7 845.8 120.5L852.9 113.4C853.2 113.4 853.6 113.3 853.8 113L864.5 102.3C864.5 102.3 864.5 102.3 864.6 102.3C865.1 102.3 865.6 102.1 866 101.7L1018.4 -50.7C1019.1 -51.4 1019.2 -52.4 1018.7 -53.1L1303.9 -338.3L1303.2 -339L1018 -53.8C1017.2 -54.3 1016.2 -54.2 1015.6 -53.5L863.2 98.9C862.8 99.3 862.6 99.8 862.6 100.4L851.9 111C851.6 111.3 851.5 111.6 851.5 111.9L844.4 119C844.1 119.3 844.1 119.6 844.2 120L578.7 385.5L579.4 386.2L844.9 120.7C845 120.7 845 120.8 845.1 120.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M861.9 80.4C862.2 80.4 862.4 80.3 862.6 80.1L869.7 73C870 73 870.4 72.9 870.6 72.6L881.3 61.9C881.3 61.9 881.3 61.9 881.4 61.9C881.9 61.9 882.4 61.7 882.8 61.3L979.4 -35.3C980.1 -36 980.2 -37 979.7 -37.7L1292.2 -350.2L1291.5 -350.9L978.9 -38.3C978.1 -38.8 977.1 -38.7 976.5 -38L879.9 58.6C879.5 59 879.3 59.5 879.3 60.1L868.6 70.8C868.3 71.1 868.2 71.4 868.2 71.7L861.1 78.8C860.8 79.1 860.8 79.4 860.9 79.8L566.9 373.8L567.6 374.5L861.6 80.5C861.7 80.4 861.8 80.4 861.9 80.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M851.4 102.5C851.7 102.5 851.9 102.4 852.1 102.2L859.2 95.1C859.5 95.1 859.9 95 860.1 94.7L870.8 84C870.8 84 870.8 84 870.9 84C871.4 84 871.9 83.8 872.3 83.4L1000.7 -45C1001.4 -45.7 1001.5 -46.7 1001 -47.4L1297.9 -344.3L1297.2 -345L1000.3 -48.1C999.5 -48.6 998.5 -48.5 997.9 -47.8L869.5 80.6C869.1 81 868.9 81.5 868.9 82.1L858.2 92.8C857.9 93.1 857.8 93.4 857.8 93.7L850.7 100.8C850.4 101.1 850.4 101.4 850.5 101.8L572.7 379.6L573.4 380.3L851.2 102.5C851.3 102.4 851.3 102.5 851.4 102.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M909.1 345.5C909.4 345.5 909.6 345.4 909.8 345.2L916.9 338.1C917.2 338.1 917.6 338 917.8 337.7L928.5 327C928.5 327 928.5 327 928.6 327C929.1 327 929.6 326.8 930 326.4L963.2 293.2C963.6 292.8 963.8 292.3 963.8 291.7L972.6 282.9C973 282.5 973.1 281.9 972.9 281.4L1202.5 51.8C1202.6 51.8 1202.7 51.8 1202.7 51.8C1203 51.8 1203.2 51.7 1203.4 51.5L1208 46.9C1208.2 46.9 1208.3 47 1208.5 47C1209 47 1209.5 46.8 1209.9 46.4L1243.1 13.2C1243.5 12.8 1243.7 12.3 1243.7 11.7L1252.3 3.10001C1252.6 2.8 1252.7 2.39999 1252.7 2L1260.1 -5.39999C1260.3 -5.59999 1260.4 -5.89999 1260.3 -6.2L1448.3 -194.2L1447.6 -194.9L1259.6 -6.89999C1259.3 -6.89999 1259 -6.89999 1258.8 -6.7L1251.4 0.699997C1251 0.699997 1250.6 0.800003 1250.3 1.10001L1241.7 9.7C1241.2 9.7 1240.6 9.90001 1240.2 10.3L1207 43.5C1206.5 44 1206.3 44.8 1206.5 45.4L1201.9 50C1201.6 50.3 1201.6 50.6 1201.7 51L972.2 280.7C971.7 280.5 971.1 280.6 970.7 281L961.9 289.8C961.4 289.8 960.8 290 960.4 290.4L927.2 323.6C926.8 324 926.6 324.5 926.6 325.1L915.9 335.8C915.6 336.1 915.5 336.4 915.5 336.7L908.4 343.8C908.1 344.1 908.1 344.4 908.2 344.8L723 529.9L723.7 530.6L908.8 345.5C908.9 345.5 909 345.5 909.1 345.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M915.5 351.2C915.8 351.2 916 351.1 916.2 350.9L923.3 343.8C923.6 343.8 924 343.7 924.2 343.4L934.9 332.7C934.9 332.7 934.9 332.7 935 332.7C935.5 332.7 936 332.5 936.4 332.1L969.6 298.9C970 298.5 970.2 298 970.2 297.4L978.8 288.8C979.2 288.4 979.3 287.8 979.1 287.3L1208.2 58.2C1208.3 58.2 1208.4 58.2 1208.4 58.2C1208.7 58.2 1208.9 58.1 1209.1 57.9L1213.7 53.3C1213.9 53.3 1214 53.4 1214.2 53.4C1214.7 53.4 1215.2 53.2 1215.6 52.8L1248.8 19.6C1249.2 19.2 1249.4 18.7 1249.4 18.1L1258.3 9.19998C1258.6 8.89998 1258.7 8.49998 1258.7 8.09999L1265.8 0.999985C1266 0.799988 1266.1 0.499985 1266 0.199982L1454.3 -188L1453.6 -188.7L1265.3 -0.400009C1265 -0.400009 1264.7 -0.400009 1264.5 -0.200012L1257.4 6.89998C1257 6.89998 1256.6 6.99998 1256.3 7.29999L1247.4 16.2C1246.9 16.2 1246.3 16.4 1245.9 16.8L1212.8 50C1212.3 50.5 1212.1 51.3 1212.3 51.9L1207.7 56.5C1207.4 56.8 1207.4 57.1 1207.5 57.5L978.3 286.5C977.8 286.3 977.2 286.4 976.8 286.8L968.2 295.4C967.7 295.4 967.1 295.6 966.7 296L933.5 329.2C933.1 329.6 932.9 330.1 932.9 330.7L922.2 341.4C921.9 341.7 921.8 342 921.8 342.3L914.7 349.4C914.4 349.7 914.4 350 914.5 350.4L729 535.9L729.7 536.6L915.2 351.1C915.3 351.1 915.4 351.2 915.5 351.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M922.1 356.5C922.4 356.5 922.6 356.4 922.8 356.2L929.9 349.1C930.2 349.1 930.6 349 930.8 348.7L941.5 338C941.5 338 941.5 338 941.6 338C942.1 338 942.6 337.8 943 337.4L976.5 303.9C976.9 303.5 977.1 303 977.1 302.4L985.2 294.3C985.6 293.9 985.7 293.3 985.5 292.8L1213.3 65C1213.4 65 1213.5 65 1213.5 65C1213.8 65 1214 64.9 1214.2 64.7L1218.8 60.1C1219 60.1 1219.1 60.2 1219.3 60.2C1219.8 60.2 1220.3 60 1220.7 59.6L1254.2 26.1C1254.6 25.7 1254.8 25.2 1254.8 24.6L1264 15.4C1264.3 15.1 1264.4 14.7 1264.4 14.3L1271.5 7.19998C1271.7 6.99998 1271.8 6.69998 1271.7 6.39998L1460.3 -182L1459.6 -182.7L1271 5.89998C1270.7 5.89998 1270.4 5.89999 1270.2 6.09999L1263.1 13.2C1262.7 13.2 1262.3 13.3 1262 13.6L1252.8 22.8C1252.3 22.8 1251.7 23 1251.3 23.4L1217.8 56.9C1217.3 57.4 1217.1 58.2 1217.3 58.8L1212.7 63.4C1212.4 63.7 1212.4 64 1212.5 64.4L984.7 292.2C984.2 292 983.6 292.1 983.2 292.5L975.1 300.6C974.6 300.6 974 300.8 973.6 301.2L940.1 334.7C939.7 335.1 939.5 335.6 939.5 336.2L928.8 346.9C928.5 347.2 928.4 347.5 928.4 347.8L921.3 354.9C921 355.2 921 355.5 921.1 355.9L735 541.9L735.7 542.6L921.8 356.5C921.9 356.5 922 356.5 922.1 356.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M928.9 361.7C929.2 361.7 929.4 361.6 929.6 361.4L936.7 354.3C937 354.3 937.4 354.2 937.6 353.9L948.3 343.2C948.3 343.2 948.3 343.2 948.4 343.2C948.9 343.2 949.4 343 949.8 342.6L983.4 309C983.8 308.6 984 308.1 984 307.5L992.1 299.4C992.5 299 992.6 298.4 992.4 297.9L1218.6 71.7C1218.7 71.7 1218.8 71.7 1218.8 71.7C1219.1 71.7 1219.3 71.6 1219.5 71.4L1224.1 66.8C1224.3 66.8 1224.4 66.9 1224.6 66.9C1225.1 66.9 1225.6 66.7 1226 66.3L1259.6 32.7C1260 32.3 1260.2 31.8 1260.2 31.2L1269.5 21.9C1269.8 21.6 1269.9 21.2 1269.9 20.8L1277 13.7C1277.2 13.5 1277.3 13.2 1277.2 12.9L1466.3 -176L1465.6 -176.7L1276.3 12.6C1276 12.6 1275.7 12.6 1275.5 12.8L1268.4 19.9C1268 19.9 1267.6 20 1267.3 20.3L1258 29.6C1257.5 29.6 1256.9 29.8 1256.5 30.2L1223 63.7C1222.5 64.2 1222.3 65 1222.5 65.6L1217.9 70.2C1217.6 70.5 1217.6 70.8 1217.7 71.2L991.6 297.3C991.1 297.1 990.5 297.2 990.1 297.6L982 305.7C981.5 305.7 980.9 305.9 980.5 306.3L946.9 339.9C946.5 340.3 946.3 340.8 946.3 341.4L935.7 352C935.4 352.3 935.3 352.6 935.3 352.9L928.2 360C927.9 360.3 927.9 360.6 928 361L741 547.9L741.7 548.6L928.6 361.7C928.7 361.7 928.8 361.7 928.9 361.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M935.9 366.8C936.2 366.8 936.4 366.7 936.6 366.5L943.7 359.4C944 359.4 944.4 359.3 944.6 359L955.3 348.3C955.3 348.3 955.3 348.3 955.4 348.3C955.9 348.3 956.4 348.1 956.8 347.7L990.6 313.9C991 313.5 991.2 313 991.2 312.4L999 304.6C999.4 304.2 999.5 303.6 999.3 303.1L1223.4 79C1223.5 79 1223.6 79 1223.6 79C1223.9 79 1224.1 78.9 1224.3 78.7L1228.9 74.1C1229.1 74.1 1229.2 74.2 1229.4 74.2C1229.9 74.2 1230.4 74 1230.8 73.6L1264.6 39.8C1265 39.4 1265.2 38.9 1265.2 38.3L1274.8 28.7C1275.1 28.4 1275.2 28 1275.2 27.6L1282.3 20.5C1282.5 20.3 1282.6 20 1282.5 19.7L1472.5 -170.3L1471.8 -171L1281.8 19C1281.5 19 1281.2 19 1281 19.2L1273.9 26.3C1273.5 26.3 1273.1 26.4 1272.8 26.7L1263.2 36.3C1262.7 36.3 1262.1 36.5 1261.7 36.9L1227.9 70.7C1227.4 71.2 1227.2 72 1227.4 72.6L1222.8 77.2C1222.5 77.5 1222.5 77.8 1222.6 78.2L998.4 302.5C997.9 302.3 997.3 302.4 996.9 302.8L989.1 310.6C988.6 310.6 988 310.8 987.6 311.2L953.8 345C953.4 345.4 953.2 345.9 953.2 346.5L942.7 357C942.4 357.3 942.3 357.6 942.3 357.9L935.2 365C934.9 365.3 934.9 365.6 935 366L747 553.9L747.7 554.6L935.6 366.7C935.7 366.7 935.8 366.8 935.9 366.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M943.1 371.5C943.4 371.5 943.6 371.4 943.8 371.2L950.9 364.1C951.2 364.1 951.6 364 951.8 363.7L962.5 353C962.5 353 962.5 353 962.6 353C963.1 353 963.6 352.8 964 352.4L998.2 318.2C998.6 317.8 998.8 317.3 998.8 316.7L1006.1 309.4C1006.5 309 1006.6 308.4 1006.4 307.9L1227.6 86.7C1227.7 86.7 1227.8 86.7 1227.8 86.7C1228.1 86.7 1228.3 86.6 1228.5 86.4L1233.1 81.8C1233.3 81.8 1233.4 81.9 1233.6 81.9C1234.1 81.9 1234.6 81.7 1235 81.3L1269.2 47.1C1269.6 46.7 1269.8 46.2 1269.8 45.6L1279.6 35.8C1279.9 35.5 1280 35.1 1280 34.7L1287.1 27.6C1287.3 27.4 1287.4 27.1 1287.3 26.8L1478.3 -164.2L1477.6 -164.9L1286.6 26.1C1286.3 26.1 1286 26.1 1285.8 26.3L1278.7 33.4C1278.3 33.4 1277.9 33.5 1277.6 33.8L1267.8 43.6C1267.3 43.6 1266.7 43.8 1266.3 44.2L1232.1 78.4C1231.6 78.9 1231.4 79.7 1231.6 80.3L1227 84.9C1226.7 85.2 1226.7 85.5 1226.8 85.9L1005.6 307.3C1005.1 307.1 1004.5 307.2 1004.1 307.6L996.8 314.9C996.3 314.9 995.7 315.1 995.3 315.5L961.1 349.7C960.7 350.1 960.5 350.6 960.5 351.2L949.8 361.9C949.5 362.2 949.4 362.5 949.4 362.8L942.3 369.9C942 370.2 942 370.5 942.1 370.9L753 559.9L753.7 560.6L942.8 371.5C942.9 371.5 943 371.5 943.1 371.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M950.6 376.1C950.9 376.1 951.1 376 951.3 375.8L958.4 368.7C958.7 368.7 959.1 368.6 959.3 368.3L970 357.6C970.5 357.6 971 357.4 971.4 357L1006 322.4C1006.4 322 1006.6 321.5 1006.6 320.9L1013.7 313.8C1014.1 313.4 1014.2 312.8 1014 312.3L1231.7 94.6C1231.8 94.6 1231.9 94.6 1231.9 94.6C1232.2 94.6 1232.4 94.5 1232.6 94.3L1237.2 89.7C1237.4 89.7 1237.5 89.8 1237.7 89.8C1238.2 89.8 1238.7 89.6 1239.1 89.2L1273.7 54.6C1274.1 54.2 1274.3 53.7 1274.3 53.1L1284.3 43.1C1284.6 42.8 1284.7 42.4 1284.7 42L1291.8 34.9C1292 34.7 1292.1 34.4 1292 34.1L1484.3 -158L1483.6 -158.7L1291.4 33.6C1291.1 33.6 1290.8 33.6 1290.6 33.8L1283.5 40.9C1283.1 40.9 1282.7 41 1282.4 41.3L1272.4 51.3C1271.9 51.3 1271.3 51.5 1270.9 51.9L1236.3 86.5C1235.8 87 1235.6 87.8 1235.8 88.4L1231.2 93C1230.9 93.3 1230.9 93.6 1231 94L1013.3 311.6C1012.8 311.4 1012.2 311.5 1011.8 311.9L1004.7 319C1004.2 319 1003.6 319.2 1003.2 319.6L968.6 354.2C968.2 354.6 968 355.1 968 355.7L957.3 366.4C957 366.7 956.9 367 956.9 367.3L949.8 374.4C949.5 374.7 949.5 375 949.6 375.4L759 565.9L759.7 566.6L950.3 376C950.4 376 950.5 376.1 950.6 376.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M958.3 380.4C958.6 380.4 958.8 380.3 959 380.1L966.1 373C966.4 373 966.8 372.9 967 372.6L977.7 361.9C978.2 361.9 978.7 361.7 979.1 361.3L1014.2 326.2C1014.6 325.8 1014.8 325.3 1014.8 324.7L1021.6 317.9C1022 317.5 1022.1 316.9 1021.9 316.4L1235.6 102.7C1235.7 102.7 1235.8 102.7 1235.8 102.7C1236.1 102.7 1236.3 102.6 1236.5 102.4L1241.1 97.8C1241.3 97.8 1241.4 97.9 1241.6 97.9C1242.1 97.9 1242.6 97.7 1243 97.3L1278.1 62.2C1278.5 61.8 1278.7 61.3 1278.7 60.7L1289 50.4C1289.3 50.1 1289.4 49.7 1289.4 49.3L1296.5 42.2C1296.7 42 1296.8 41.7 1296.7 41.4L1490.3 -152L1489.6 -152.7L1295.9 41C1295.6 41 1295.3 41 1295.1 41.2L1288 48.3C1287.6 48.3 1287.2 48.4 1286.9 48.7L1276.7 59C1276.2 59 1275.6 59.2 1275.2 59.6L1240.1 94.7C1239.6 95.2 1239.4 96 1239.6 96.6L1235 101.2C1234.7 101.5 1234.7 101.8 1234.8 102.2L1021.2 315.8C1020.7 315.6 1020.1 315.7 1019.7 316.1L1012.9 322.9C1012.4 322.9 1011.8 323.1 1011.4 323.5L976.3 358.6C975.9 359 975.7 359.5 975.7 360.1L965 370.8C964.7 371.1 964.6 371.4 964.6 371.7L957.5 378.8C957.2 379.1 957.2 379.4 957.3 379.8L765 571.9L765.7 572.6L958 380.3C958.1 380.3 958.2 380.4 958.3 380.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M966.2 384.4C966.5 384.4 966.7 384.3 966.9 384.1L974 377C974.3 377 974.7 376.9 974.9 376.6L985.6 365.9C985.6 365.9 985.6 365.9 985.7 365.9C986.2 365.9 986.7 365.7 987.1 365.3L1023.1 329.3C1023.5 328.9 1023.7 328.4 1023.7 327.8L1029.8 321.7C1030.2 321.3 1030.3 320.7 1030.1 320.2L1238.8 111.5C1238.9 111.5 1239 111.5 1239 111.5C1239.3 111.5 1239.5 111.4 1239.7 111.2L1244.3 106.6C1244.5 106.6 1244.6 106.7 1244.8 106.7C1245.3 106.7 1245.8 106.5 1246.2 106.1L1282.2 70.1C1282.6 69.7 1282.8 69.2 1282.8 68.6L1293.3 58.1C1293.6 57.8 1293.7 57.4 1293.7 57L1300.8 49.9C1301 49.7 1301.1 49.4 1301 49.1L1496.3 -146L1495.6 -146.7L1300.2 48.7C1299.9 48.7 1299.6 48.7 1299.4 48.9L1292.3 56C1291.9 56 1291.5 56.1 1291.2 56.4L1280.7 67C1280.2 67 1279.6 67.2 1279.2 67.6L1243.2 103.6C1242.7 104.1 1242.5 104.9 1242.7 105.5L1238.1 110.1C1237.8 110.4 1237.8 110.7 1237.9 111.1L1029.3 319.6C1028.8 319.4 1028.2 319.5 1027.8 319.9L1021.7 326C1021.2 326 1020.6 326.2 1020.2 326.6L984.2 362.6C983.8 363 983.6 363.5 983.6 364.1L973 374.7C972.7 375 972.6 375.3 972.6 375.6L965.5 382.7C965.2 383 965.2 383.3 965.3 383.7L771 577.9L771.7 578.6L966 384.4C966.1 384.4 966.1 384.4 966.2 384.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M974.4 388.2C974.7 388.2 974.9 388.1 975.1 387.9L982.2 380.8C982.5 380.8 982.9 380.7 983.1 380.4L993.8 369.7C993.8 369.7 993.8 369.7 993.9 369.7C994.4 369.7 994.9 369.5 995.3 369.1L1032 332.4C1032.4 332 1032.6 331.5 1032.6 330.9L1038.6 324.9C1039 324.5 1039.1 323.9 1038.9 323.4L1241.8 120.5C1241.9 120.5 1242 120.5 1242 120.5C1242.3 120.5 1242.5 120.4 1242.7 120.2L1247.3 115.6C1247.5 115.6 1247.6 115.7 1247.8 115.7C1248.3 115.7 1248.8 115.5 1249.2 115.1L1285.9 78.4C1286.3 78 1286.5 77.5 1286.5 76.9L1297.3 66.1C1297.6 65.8 1297.7 65.4 1297.7 65L1304.8 57.9C1305 57.7 1305.1 57.4 1305 57.1L1502.3 -140L1501.6 -140.7L1304.2 56.7C1303.9 56.7 1303.6 56.7 1303.4 56.9L1296.3 64C1295.9 64 1295.5 64.1 1295.2 64.4L1284.4 75.2C1283.9 75.2 1283.3 75.4 1282.9 75.8L1246.2 112.5C1245.7 113 1245.5 113.8 1245.7 114.4L1241.1 119C1240.8 119.3 1240.8 119.6 1240.9 120L1038.2 322.7C1037.7 322.5 1037.1 322.6 1036.7 323L1030.7 329C1030.2 329 1029.6 329.2 1029.2 329.6L992.5 366.3C992.1 366.7 991.9 367.2 991.9 367.8L981.2 378.5C980.9 378.8 980.8 379.1 980.8 379.4L973.7 386.5C973.4 386.8 973.4 387.1 973.5 387.5L777 583.9L777.7 584.6L974.2 388.1C974.3 388.2 974.4 388.2 974.4 388.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M982.9 391.7C983.2 391.7 983.4 391.6 983.6 391.4L990.7 384.3C991 384.3 991.4 384.2 991.6 383.9L1002.3 373.2C1002.8 373.2 1003.3 373 1003.7 372.6L1041.1 335.2C1041.5 334.8 1041.7 334.3 1041.7 333.7L1047.4 328C1047.8 327.6 1047.9 327 1047.7 326.5L1244.4 129.8C1244.5 129.8 1244.6 129.8 1244.6 129.8C1244.9 129.8 1245.1 129.7 1245.3 129.5L1249.9 124.9C1250.1 124.9 1250.2 125 1250.4 125C1250.9 125 1251.4 124.8 1251.8 124.4L1289.2 87C1289.6 86.6 1289.8 86.1 1289.8 85.5L1300.9 74.4C1301.2 74.1 1301.3 73.7 1301.3 73.3L1308.4 66.2C1308.6 66 1308.7 65.7 1308.6 65.4L1508.3 -134L1507.6 -134.7L1308 64.9C1307.7 64.9 1307.4 64.9 1307.2 65.1L1300.1 72.2C1299.7 72.2 1299.3 72.3 1299 72.6L1288 83.7C1287.5 83.7 1286.9 83.9 1286.5 84.3L1249.1 121.7C1248.6 122.2 1248.4 123 1248.6 123.6L1244 128.2C1243.7 128.5 1243.7 128.8 1243.8 129.2L1047.1 325.8C1046.6 325.6 1046 325.7 1045.6 326.1L1039.9 331.8C1039.4 331.8 1038.8 332 1038.4 332.4L1001 369.8C1000.6 370.2 1000.4 370.7 1000.4 371.3L989.7 382C989.4 382.3 989.3 382.6 989.3 382.9L982.2 390C981.9 390.3 981.9 390.6 982 391L783 589.9L783.7 590.6L982.6 391.7C982.8 391.7 982.9 391.7 982.9 391.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M992.9 393.8C993.2 393.8 993.4 393.7 993.6 393.5L1000.7 386.4C1001 386.4 1001.4 386.3 1001.6 386L1012.3 375.3C1012.3 375.3 1012.3 375.3 1012.4 375.3C1012.9 375.3 1013.4 375.1 1013.8 374.7L1050.7 337.8C1051.1 337.4 1051.3 336.9 1051.3 336.3L1056.8 330.8C1057.2 330.4 1057.3 329.8 1057.1 329.3L1245.5 140.9C1245.6 140.9 1245.7 140.9 1245.7 140.9C1246 140.9 1246.2 140.8 1246.4 140.6L1251 136C1251.2 136 1251.3 136.1 1251.5 136.1C1252 136.1 1252.5 135.9 1252.9 135.5L1291.5 96.9C1291.9 96.5 1292.1 96 1292.1 95.4L1304.6 82.9C1304.9 82.6 1305 82.2 1305 81.8L1312.1 74.7C1312.3 74.5 1312.4 74.2 1312.3 73.9L1514.3 -128L1513.6 -128.7L1311.5 73.4C1311.2 73.4 1310.9 73.4 1310.7 73.6L1303.6 80.7C1303.2 80.7 1302.8 80.8 1302.5 81.1L1290 93.6C1289.5 93.6 1288.9 93.8 1288.5 94.2L1249.9 132.8C1249.4 133.3 1249.2 134.1 1249.4 134.7L1244.8 139.3C1244.5 139.6 1244.5 139.9 1244.6 140.3L1056.4 328.6C1055.9 328.4 1055.3 328.5 1054.9 328.9L1049.4 334.4C1048.9 334.4 1048.3 334.6 1047.9 335L1011 371.9C1010.6 372.3 1010.4 372.8 1010.4 373.4L999.7 384C999.4 384.3 999.3 384.6 999.3 384.9L992.2 392C991.9 392.3 991.9 392.6 992 393L789 595.9L789.7 596.6L992.6 393.7C992.7 393.8 992.8 393.8 992.9 393.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M1002.2 396.4C1002.5 396.4 1002.7 396.3 1002.9 396.1L1010 389C1010.3 389 1010.7 388.9 1010.9 388.6L1021.6 377.9C1021.6 377.9 1021.6 377.9 1021.7 377.9C1022.2 377.9 1022.7 377.7 1023.1 377.3L1060.8 339.6C1061.2 339.2 1061.4 338.7 1061.4 338.1L1066.3 333.2C1066.7 332.8 1066.8 332.2 1066.6 331.7L1246.8 151.5C1246.9 151.5 1247 151.5 1247 151.5C1247.3 151.5 1247.5 151.4 1247.7 151.2L1252.3 146.6C1252.5 146.6 1252.6 146.7 1252.8 146.7C1253.3 146.7 1253.8 146.5 1254.2 146.1L1294.2 106.1C1294.6 105.7 1294.8 105.2 1294.8 104.6L1306.7 92.7C1307 92.4 1307.1 92 1307.1 91.6L1314.2 84.5C1314.4 84.3 1314.5 84 1314.4 83.7L1520.4 -122.3L1519.7 -123L1313.7 83C1313.4 83 1313.1 83 1312.9 83.2L1305.8 90.3C1305.4 90.3 1305 90.4 1304.7 90.7L1292.8 102.6C1292.3 102.6 1291.7 102.8 1291.3 103.2L1251.3 143.2C1250.8 143.7 1250.6 144.5 1250.8 145.1L1246.2 149.7C1245.9 150 1245.9 150.3 1246 150.7L1065.8 331.1C1065.3 330.9 1064.7 331 1064.3 331.4L1059.4 336.3C1058.9 336.3 1058.3 336.5 1057.9 336.9L1020.2 374.6C1019.8 375 1019.6 375.5 1019.6 376.1L1009 386.7C1008.7 387 1008.6 387.3 1008.6 387.6L1001.5 394.7C1001.2 395 1001.2 395.3 1001.3 395.7L795 601.9L795.7 602.6L1002 396.4C1002 396.4 1002.1 396.4 1002.2 396.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M1011.8 398.8C1012.1 398.8 1012.3 398.7 1012.5 398.5L1019.6 391.4C1019.9 391.4 1020.3 391.3 1020.5 391L1031.2 380.3C1031.2 380.3 1031.2 380.3 1031.3 380.3C1031.8 380.3 1032.3 380.1 1032.7 379.7L1071.8 340.6C1072.2 340.2 1072.4 339.7 1072.4 339.1L1076.3 335.2C1076.7 334.8 1076.8 334.2 1076.6 333.7L1247.3 163C1247.4 163 1247.5 163 1247.5 163C1247.8 163 1248 162.9 1248.2 162.7L1252.8 158.1C1253 158.1 1253.1 158.2 1253.3 158.2C1253.8 158.2 1254.3 158 1254.7 157.6L1296.5 115.8C1296.9 115.4 1297.1 114.9 1297.1 114.3L1309.3 102.1C1309.6 101.8 1309.7 101.4 1309.7 101L1316.8 93.9C1317 93.7 1317.1 93.4 1317 93.1L1526.3 -116L1525.6 -116.7L1316.3 92.7C1316 92.7 1315.7 92.7 1315.5 92.9L1308.4 100C1308 100 1307.6 100.1 1307.3 100.4L1295.1 112.6C1294.6 112.6 1294 112.8 1293.6 113.2L1251.8 155C1251.3 155.5 1251.1 156.3 1251.3 156.9L1246.7 161.5C1246.4 161.8 1246.4 162.1 1246.5 162.5L1075.9 333C1075.4 332.8 1074.8 332.9 1074.4 333.3L1070.5 337.2C1070 337.2 1069.4 337.4 1069 337.8L1029.9 376.9C1029.5 377.3 1029.3 377.8 1029.3 378.4L1018.6 389.1C1018.3 389.4 1018.2 389.7 1018.2 390L1011.1 397.1C1010.8 397.4 1010.8 397.7 1010.9 398.1L801 607.9L801.7 608.6L1011.5 398.8C1011.6 398.8 1011.7 398.8 1011.8 398.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M1021.7 401C1022 401 1022.2 400.9 1022.4 400.7L1029.5 393.6C1029.8 393.6 1030.2 393.5 1030.4 393.2L1041.1 382.5C1041.1 382.5 1041.1 382.5 1041.2 382.5C1041.7 382.5 1042.2 382.3 1042.6 381.9L1083.2 341.3C1083.6 340.9 1083.8 340.4 1083.8 339.8L1087.4 336.2C1087.8 335.8 1087.9 335.2 1087.7 334.7L1246.9 175.5C1247 175.5 1247.1 175.5 1247.1 175.5C1247.4 175.5 1247.6 175.4 1247.8 175.2L1252.4 170.6C1252.6 170.6 1252.7 170.7 1252.9 170.7C1253.4 170.7 1253.9 170.5 1254.3 170.1L1298.7 125.7C1299.1 125.3 1299.3 124.8 1299.3 124.2L1311.8 111.7C1312.1 111.4 1312.2 111 1312.2 110.6L1319.3 103.5C1319.5 103.3 1319.6 103 1319.5 102.7L1532.3 -110L1531.6 -110.7L1318.7 102.3C1318.4 102.3 1318.1 102.3 1317.9 102.5L1310.8 109.6C1310.4 109.6 1310 109.7 1309.7 110L1297.2 122.5C1296.7 122.5 1296.1 122.7 1295.7 123.1L1251.3 167.5C1250.8 168 1250.6 168.8 1250.8 169.4L1246.2 174C1245.9 174.3 1245.9 174.6 1246 175L1086.9 334C1086.4 333.8 1085.8 333.9 1085.4 334.3L1081.8 337.9C1081.3 337.9 1080.7 338.1 1080.3 338.5L1039.7 379.1C1039.3 379.5 1039.1 380 1039.1 380.6L1028.4 391.3C1028.1 391.6 1028 391.9 1028 392.2L1020.9 399.3C1020.6 399.6 1020.6 399.9 1020.7 400.3L807.1 613.9L807.8 614.6L1021.5 400.9C1021.5 400.9 1021.6 401 1021.7 401Z"
                fill="#1D6BFC"
              />
              <path
                d="M1031.7 403C1032 403 1032.2 402.9 1032.4 402.7L1039.5 395.6C1039.8 395.6 1040.2 395.5 1040.4 395.2L1051.1 384.5C1051.1 384.5 1051.1 384.5 1051.2 384.5C1051.7 384.5 1052.2 384.3 1052.6 383.9L1094.8 341.7C1095.2 341.3 1095.4 340.8 1095.4 340.2L1098.8 336.8C1099.2 336.4 1099.3 335.8 1099.1 335.3L1245.4 189C1245.5 189 1245.6 189 1245.6 189C1245.9 189 1246.1 188.9 1246.3 188.7L1250.9 184.1C1251.1 184.1 1251.2 184.2 1251.4 184.2C1251.9 184.2 1252.4 184 1252.8 183.6L1300.7 135.7C1301.1 135.3 1301.3 134.8 1301.3 134.2L1313.8 121.7C1314.1 121.4 1314.2 121 1314.2 120.6L1321.3 113.5C1321.5 113.3 1321.6 113 1321.5 112.7L1538.3 -103.9L1537.6 -104.6L1320.8 112.2C1320.5 112.2 1320.2 112.2 1320 112.4L1312.9 119.5C1312.5 119.5 1312.1 119.6 1311.8 119.9L1299.3 132.4C1298.8 132.4 1298.2 132.6 1297.8 133L1250 180.9C1249.5 181.4 1249.3 182.2 1249.5 182.8L1244.9 187.4C1244.6 187.7 1244.6 188 1244.7 188.4L1098.3 334.6C1097.8 334.4 1097.2 334.5 1096.8 334.9L1093.4 338.3C1092.9 338.3 1092.3 338.5 1091.9 338.9L1049.7 381.1C1049.3 381.5 1049.1 382 1049.1 382.6L1038.4 393.3C1038.1 393.6 1038 393.9 1038 394.2L1030.9 401.3C1030.6 401.6 1030.6 401.9 1030.7 402.3L813.1 619.9L813.8 620.6L1031.5 402.9C1031.5 403 1031.6 403 1031.7 403Z"
                fill="#1D6BFC"
              />
              <path
                d="M850.6 259.4C850.9 259.4 851.1 259.3 851.3 259.1L858.4 252C858.7 252 859.1 251.9 859.3 251.6L870 240.9C870 240.9 870 240.9 870.1 240.9C870.6 240.9 871.1 240.7 871.5 240.3L908.9 202.9C909.3 202.5 909.5 202 909.5 201.4L923.5 187.4C923.9 187 924 186.4 923.8 185.9L1112.2 -2.49997C1112.3 -2.49997 1112.4 -2.49997 1112.4 -2.49997C1112.7 -2.49997 1112.9 -2.59998 1113.1 -2.79999L1117.7 -7.39999C1117.9 -7.39999 1118 -7.29999 1118.2 -7.29999C1118.7 -7.29999 1119.2 -7.5 1119.6 -7.89999L1157 -45.3C1157.4 -45.7 1157.6 -46.2 1157.6 -46.8L1162.3 -51.5C1162.6 -51.8 1162.7 -52.2 1162.7 -52.6L1169.8 -59.7C1170 -59.9 1170.1 -60.2 1170 -60.5L1376 -266.5L1375.3 -267.2L1169.3 -61.2C1169 -61.2 1168.7 -61.2 1168.5 -61L1161.4 -53.9C1161 -53.9 1160.6 -53.8 1160.3 -53.5L1155.6 -48.8C1155.1 -48.8 1154.5 -48.6 1154.1 -48.2L1116.7 -10.8C1116.2 -10.3 1116 -9.5 1116.2 -8.89999L1111.6 -4.29999C1111.3 -4 1111.3 -3.69998 1111.4 -3.29999L923.1 185.2C922.6 185 922 185.1 921.6 185.5L907.6 199.5C907.1 199.5 906.5 199.7 906.1 200.1L868.7 237.5C868.3 237.9 868.1 238.4 868.1 239L857.4 249.7C857.1 250 857 250.3 857 250.6L849.9 257.7C849.6 258 849.6 258.3 849.7 258.7L650.7 457.5L651.4 458.2L850.3 259.3C850.4 259.3 850.5 259.4 850.6 259.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M896.8 333.2C897.1 333.2 897.3 333.1 897.5 332.9L904.6 325.8C904.9 325.8 905.3 325.7 905.5 325.4L916.2 314.7C916.2 314.7 916.2 314.7 916.3 314.7C916.8 314.7 917.3 314.5 917.7 314.1L950.9 280.9C951.3 280.5 951.5 280 951.5 279.4L960.8 270.1C961.2 269.7 961.3 269.1 961.1 268.6L1190.2 39.5C1190.3 39.5 1190.4 39.5 1190.4 39.5C1190.7 39.5 1190.9 39.4 1191.1 39.2L1195.7 34.6C1195.9 34.6 1196 34.7 1196.2 34.7C1196.7 34.7 1197.2 34.5 1197.6 34.1L1230.8 0.899994C1231.2 0.5 1231.4 0 1231.4 -0.600006L1239.6 -8.8C1239.9 -9.10001 1240 -9.5 1240 -9.89999L1247.1 -17C1247.3 -17.2 1247.4 -17.5 1247.3 -17.8L1436 -206.3L1435.3 -207L1246.7 -18.4C1246.4 -18.4 1246.1 -18.4 1245.9 -18.2L1238.8 -11.1C1238.4 -11.1 1238 -11 1237.7 -10.7L1229.5 -2.5C1229 -2.5 1228.4 -2.29999 1228 -1.89999L1194.8 31.3C1194.3 31.8 1194.1 32.6 1194.3 33.2L1189.7 37.8C1189.4 38.1 1189.4 38.4 1189.5 38.8L960.4 267.9C959.9 267.7 959.3 267.8 958.9 268.2L949.6 277.5C949.1 277.5 948.5 277.7 948.1 278.1L914.9 311.3C914.5 311.7 914.3 312.2 914.3 312.8L903.6 323.5C903.3 323.8 903.2 324.1 903.2 324.4L896.1 331.5C895.8 331.8 895.8 332.1 895.9 332.5L710.7 517.6L711.4 518.3L896.5 333.2C896.6 333.2 896.7 333.2 896.8 333.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M891.1 326.8C891.4 326.8 891.6 326.7 891.8 326.5L898.9 319.4C899.2 319.4 899.6 319.3 899.8 319L910.5 308.3C910.5 308.3 910.5 308.3 910.6 308.3C911.1 308.3 911.6 308.1 912 307.7L945.2 274.5C945.6 274.1 945.8 273.6 945.8 273L955.6 263.2C956 262.8 956.1 262.2 955.9 261.7L1183.8 33.8C1183.9 33.8 1184 33.8 1184 33.8C1184.3 33.8 1184.5 33.7 1184.7 33.5L1189.3 28.9C1189.5 28.9 1189.6 29 1189.8 29C1190.3 29 1190.8 28.8 1191.2 28.4L1224.4 -4.8C1224.8 -5.2 1225 -5.7 1225 -6.3L1232.9 -14.2C1233.2 -14.5 1233.3 -14.9 1233.3 -15.3L1240.4 -22.4C1240.6 -22.6 1240.7 -22.9 1240.6 -23.2L1430 -212.3L1429.3 -213L1240 -23.8C1239.7 -23.8 1239.4 -23.8 1239.2 -23.6L1232.1 -16.5C1231.7 -16.5 1231.3 -16.4 1231 -16.1L1223.1 -8.2C1222.6 -8.2 1222 -8 1221.6 -7.60001L1188.4 25.6C1187.9 26.1 1187.7 26.9 1187.9 27.5L1183.3 32.1C1183 32.4 1183 32.7 1183.1 33.1L955.3 261C954.8 260.8 954.2 260.9 953.8 261.3L944 271.1C943.5 271.1 942.9 271.3 942.5 271.7L909.3 304.9C908.9 305.3 908.7 305.8 908.7 306.4L898 317.1C897.7 317.4 897.6 317.7 897.6 318L890.5 325.1C890.2 325.4 890.2 325.7 890.3 326.1L704.7 511.6L705.4 512.3L890.9 326.8C891 326.8 891.1 326.8 891.1 326.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M885.8 320.2C886.1 320.2 886.3 320.1 886.5 319.9L893.6 312.8C893.9 312.8 894.3 312.7 894.5 312.4L905.2 301.7C905.2 301.7 905.2 301.7 905.3 301.7C905.8 301.7 906.3 301.5 906.7 301.1L940.2 267.6C940.6 267.2 940.8 266.7 940.8 266.1L950.6 256.3C951 255.9 951.1 255.3 950.9 254.8L1177 28.7C1177.1 28.7 1177.2 28.7 1177.2 28.7C1177.5 28.7 1177.7 28.6 1177.9 28.4L1182.5 23.8C1182.7 23.8 1182.8 23.9 1183 23.9C1183.5 23.9 1184 23.7 1184.4 23.3L1217.9 -10.2C1218.3 -10.6 1218.5 -11.1 1218.5 -11.7L1226.3 -19.5C1226.6 -19.8 1226.7 -20.2 1226.7 -20.6L1233.8 -27.7C1234 -27.9 1234.1 -28.2 1234 -28.5L1424 -218.5L1423.3 -219.2L1233.3 -29.2C1233 -29.2 1232.7 -29.2 1232.5 -29L1225.4 -21.9C1225 -21.9 1224.6 -21.8 1224.3 -21.5L1216.5 -13.7C1216 -13.7 1215.4 -13.5 1215 -13.1L1181.5 20.4C1181 20.9 1180.8 21.7 1181 22.3L1176.4 26.9C1176.1 27.2 1176.1 27.5 1176.2 27.9L950.1 254.2C949.6 254 949 254.1 948.6 254.5L938.8 264.3C938.3 264.3 937.7 264.5 937.3 264.9L903.8 298.4C903.4 298.8 903.2 299.3 903.2 299.9L892.5 310.6C892.2 310.9 892.1 311.2 892.1 311.5L885 318.6C884.7 318.9 884.7 319.2 884.8 319.6L698.7 505.6L699.4 506.3L885.5 320.2C885.6 320.2 885.7 320.2 885.8 320.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M880.5 313.4C880.8 313.4 881 313.3 881.2 313.1L888.3 306C888.6 306 889 305.9 889.2 305.6L899.9 294.9C899.9 294.9 899.9 294.9 900 294.9C900.5 294.9 901 294.7 901.4 294.3L935 260.7C935.4 260.3 935.6 259.8 935.6 259.2L945.7 249.1C946.1 248.7 946.2 248.1 946 247.6L1170.1 23.5C1170.2 23.5 1170.3 23.5 1170.3 23.5C1170.6 23.5 1170.8 23.4 1171 23.2L1175.6 18.6C1175.8 18.6 1175.9 18.7 1176.1 18.7C1176.6 18.7 1177.1 18.5 1177.5 18.1L1211.1 -15.5C1211.5 -15.9 1211.7 -16.4 1211.7 -17L1219.3 -24.6C1219.6 -24.9 1219.7 -25.3 1219.7 -25.7L1226.8 -32.8C1227 -33 1227.1 -33.3 1227 -33.6L1418 -224.6L1417.3 -225.3L1226.3 -34.3C1226 -34.3 1225.7 -34.3 1225.5 -34.1L1218.4 -27C1218 -27 1217.6 -26.9 1217.3 -26.6L1209.7 -19C1209.2 -19 1208.6 -18.8 1208.2 -18.4L1174.6 15.2C1174.1 15.7 1173.9 16.5 1174.1 17.1L1169.5 21.7C1169.2 22 1169.2 22.3 1169.3 22.7L945.3 247C944.8 246.8 944.2 246.9 943.8 247.3L933.7 257.4C933.2 257.4 932.6 257.6 932.2 258L898.6 291.6C898.2 292 898 292.5 898 293.1L887.3 303.8C887 304.1 886.9 304.4 886.9 304.7L879.8 311.8C879.5 312.1 879.5 312.4 879.6 312.8L692.7 499.6L693.4 500.3L880.3 313.4C880.4 313.4 880.5 313.4 880.5 313.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M875.5 306.4C875.8 306.4 876 306.3 876.2 306.1L883.3 299C883.6 299 884 298.9 884.2 298.6L894.9 287.9C894.9 287.9 894.9 287.9 895 287.9C895.5 287.9 896 287.7 896.4 287.3L930.2 253.5C930.6 253.1 930.8 252.6 930.8 252L941.4 241.4C941.8 241 941.9 240.4 941.7 239.9L1162.9 18.7C1163 18.7 1163.1 18.7 1163.1 18.7C1163.4 18.7 1163.6 18.6 1163.8 18.4L1168.4 13.8C1168.6 13.8 1168.7 13.9 1168.9 13.9C1169.4 13.9 1169.9 13.7 1170.3 13.3L1204.1 -20.5C1204.5 -20.9 1204.7 -21.4 1204.7 -22L1212 -29.3C1212.3 -29.6 1212.4 -30 1212.4 -30.4L1219.5 -37.5C1219.7 -37.7 1219.8 -38 1219.7 -38.3L1412 -230.3L1411.3 -231L1219 -38.8C1218.7 -38.8 1218.4 -38.8 1218.2 -38.6L1211.1 -31.5C1210.7 -31.5 1210.3 -31.4 1210 -31.1L1202.7 -23.8C1202.2 -23.8 1201.6 -23.6 1201.2 -23.2L1167.4 10.6C1166.9 11.1 1166.7 11.9 1166.9 12.5L1162.3 17.1C1162 17.4 1162 17.7 1162.1 18.1L941 239.3C940.5 239.1 939.9 239.2 939.5 239.6L928.9 250.2C928.4 250.2 927.8 250.4 927.4 250.8L893.6 284.6C893.2 285 893 285.5 893 286.1L882.3 296.8C882 297.1 881.9 297.4 881.9 297.7L874.8 304.8C874.5 305.1 874.5 305.4 874.6 305.8L686.7 493.6L687.4 494.3L875.3 306.4C875.4 306.4 875.5 306.4 875.5 306.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M870.8 299.2C871.1 299.2 871.3 299.1 871.5 298.9L878.6 291.8C878.9 291.8 879.3 291.7 879.5 291.4L890.2 280.7C890.2 280.7 890.2 280.7 890.3 280.7C890.8 280.7 891.3 280.5 891.7 280.1L925.9 245.9C926.3 245.5 926.5 245 926.5 244.4L937.3 233.6C937.7 233.2 937.8 232.6 937.6 232.1L1155.3 14.4C1155.4 14.4 1155.5 14.4 1155.5 14.4C1155.8 14.4 1156 14.3 1156.2 14.1L1160.8 9.5C1161 9.5 1161.1 9.60001 1161.3 9.60001C1161.8 9.60001 1162.3 9.39999 1162.7 9L1196.9 -25.2C1197.3 -25.6 1197.5 -26.1 1197.5 -26.7L1204.6 -33.8C1204.9 -34.1 1205 -34.5 1205 -34.9L1212.1 -42C1212.3 -42.2 1212.4 -42.5 1212.3 -42.8L1406 -236.3L1405.3 -237L1211.6 -43.3C1211.3 -43.3 1211 -43.3 1210.8 -43.1L1203.7 -36C1203.3 -36 1202.9 -35.9 1202.6 -35.6L1195.5 -28.5C1195 -28.5 1194.4 -28.3 1194 -27.9L1159.8 6.3C1159.3 6.8 1159.1 7.59999 1159.3 8.2L1154.7 12.8C1154.4 13.1 1154.4 13.4 1154.5 13.8L936.8 231.4C936.3 231.2 935.7 231.3 935.3 231.7L924.5 242.5C924 242.5 923.4 242.7 923 243.1L888.8 277.3C888.4 277.7 888.2 278.2 888.2 278.8L877.5 289.5C877.2 289.8 877.1 290.1 877.1 290.4L870 297.5C869.7 297.8 869.7 298.1 869.8 298.5L680.7 487.6L681.4 488.3L870.5 299.2C870.6 299.2 870.7 299.2 870.8 299.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M866.2 291.7C866.5 291.7 866.7 291.6 866.9 291.4L874 284.3C874.3 284.3 874.7 284.2 874.9 283.9L885.6 273.2C885.6 273.2 885.6 273.2 885.7 273.2C886.2 273.2 886.7 273 887.1 272.6L921.7 238C922.1 237.6 922.3 237.1 922.3 236.5L933.4 225.4C933.8 225 933.9 224.4 933.7 223.9L1147.4 10.2C1147.5 10.2 1147.6 10.2 1147.6 10.2C1147.9 10.2 1148.1 10.1 1148.3 9.89999L1152.9 5.3C1153.1 5.3 1153.2 5.39999 1153.4 5.39999C1153.9 5.39999 1154.4 5.2 1154.8 4.8L1189.4 -29.8C1189.8 -30.2 1190 -30.7 1190 -31.3L1196.9 -38.2C1197.2 -38.5 1197.3 -38.9 1197.3 -39.3L1204.4 -46.4C1204.6 -46.6 1204.7 -46.9 1204.6 -47.2L1400 -242.3L1399.3 -243L1203.9 -47.6C1203.6 -47.6 1203.3 -47.6 1203.1 -47.4L1196 -40.3C1195.6 -40.3 1195.2 -40.2 1194.9 -39.9L1188 -33C1187.5 -33 1186.9 -32.8 1186.5 -32.4L1152 2.10001C1151.5 2.60001 1151.3 3.39999 1151.5 4L1146.9 8.60001C1146.6 8.90001 1146.6 9.20001 1146.7 9.60001L933 223.3C932.5 223.1 931.9 223.2 931.5 223.6L920.4 234.7C919.9 234.7 919.3 234.9 918.9 235.3L884.3 269.9C883.9 270.3 883.7 270.8 883.7 271.4L873 282C872.7 282.3 872.6 282.6 872.6 282.9L865.5 290C865.2 290.3 865.2 290.6 865.3 291L674.7 481.6L675.4 482.3L866 291.7C866.1 291.7 866.1 291.7 866.2 291.7Z"
                fill="#1D6BFC"
              />
              <path
                d="M861.9 284C862.2 284 862.4 283.9 862.6 283.7L869.7 276.6C870 276.6 870.4 276.5 870.6 276.2L881.3 265.5C881.8 265.5 882.3 265.3 882.7 264.9L917.8 229.8C918.2 229.4 918.4 228.9 918.4 228.3L930.2 216.5C930.6 216.1 930.7 215.5 930.5 215L1139.2 6.3C1139.3 6.3 1139.4 6.3 1139.4 6.3C1139.7 6.3 1139.9 6.2 1140.1 6L1144.7 1.39999C1144.9 1.39999 1145 1.5 1145.2 1.5C1145.7 1.5 1146.2 1.29999 1146.6 0.899994L1181.7 -34.2C1182.1 -34.6 1182.3 -35.1 1182.3 -35.7L1188.9 -42.3C1189.2 -42.6 1189.3 -43 1189.3 -43.4L1196.4 -50.5C1196.6 -50.7 1196.7 -51 1196.6 -51.3L1394 -248.3L1393.3 -249L1195.9 -51.7C1195.6 -51.7 1195.3 -51.7 1195.1 -51.5L1188 -44.4C1187.6 -44.4 1187.2 -44.3 1186.9 -44L1180.3 -37.4C1179.8 -37.4 1179.2 -37.2 1178.8 -36.8L1143.7 -1.7C1143.2 -1.2 1143 -0.400009 1143.2 0.199997L1138.6 4.8C1138.3 5.10001 1138.3 5.40001 1138.4 5.8L929.9 214.4C929.4 214.2 928.8 214.3 928.4 214.7L916.6 226.5C916.1 226.5 915.5 226.7 915.1 227.1L880 262.1C879.6 262.5 879.4 263 879.4 263.6L868.7 274.3C868.4 274.6 868.3 274.9 868.3 275.2L861.2 282.3C860.9 282.6 860.9 282.9 861 283.3L668.7 475.5L669.4 476.2L861.7 284C861.8 284 861.8 284 861.9 284Z"
                fill="#1D6BFC"
              />
              <path
                d="M857.9 276.1C858.2 276.1 858.4 276 858.6 275.8L865.7 268.7C866 268.7 866.4 268.6 866.6 268.3L877.3 257.6C877.3 257.6 877.3 257.6 877.4 257.6C877.9 257.6 878.4 257.4 878.8 257L914.8 221C915.2 220.6 915.4 220.1 915.4 219.5L927.3 207.6C927.7 207.2 927.8 206.6 927.6 206.1L1130.5 3.20001C1130.6 3.20001 1130.7 3.20001 1130.7 3.20001C1131 3.20001 1131.2 3.10001 1131.4 2.89999L1136 -1.7C1136.2 -1.7 1136.3 -1.60001 1136.5 -1.60001C1137 -1.60001 1137.5 -1.8 1137.9 -2.2L1173.9 -38.2C1174.3 -38.6 1174.5 -39.1 1174.5 -39.7L1180.8 -46C1181.1 -46.3 1181.2 -46.7 1181.2 -47.1L1188.3 -54.2C1188.5 -54.4 1188.6 -54.7 1188.5 -55L1388 -254.3L1387.3 -255L1187.7 -55.4C1187.4 -55.4 1187.1 -55.4 1186.9 -55.2L1179.8 -48.1C1179.4 -48.1 1179 -48 1178.7 -47.7L1172.4 -41.4C1171.9 -41.4 1171.3 -41.2 1170.9 -40.8L1134.9 -4.8C1134.4 -4.3 1134.2 -3.5 1134.4 -2.89999L1129.8 1.70001C1129.5 2 1129.5 2.30002 1129.6 2.70001L926.8 205.5C926.3 205.3 925.7 205.4 925.3 205.8L913.4 217.7C912.9 217.7 912.3 217.9 911.9 218.3L875.9 254.3C875.5 254.7 875.3 255.2 875.3 255.8L864.6 266.5C864.3 266.8 864.2 267.1 864.2 267.4L857.1 274.5C856.8 274.8 856.8 275.1 856.9 275.5L662.7 469.5L663.4 470.2L857.6 276C857.7 276 857.8 276.1 857.9 276.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M854.1 267.8C854.4 267.8 854.6 267.7 854.8 267.5L861.9 260.4C862.2 260.4 862.6 260.3 862.8 260L873.5 249.3C873.5 249.3 873.5 249.3 873.6 249.3C874.1 249.3 874.6 249.1 875 248.7L911.7 212C912.1 211.6 912.3 211.1 912.3 210.5L924.5 198.3C924.9 197.9 925 197.3 924.8 196.8L1121.5 0.100006C1121.6 0.100006 1121.7 0.100006 1121.7 0.100006C1122 0.100006 1122.2 0 1122.4 -0.200012L1127 -4.79999C1127.2 -4.79999 1127.3 -4.70001 1127.5 -4.70001C1128 -4.70001 1128.5 -4.89999 1128.9 -5.3L1165.6 -42C1166 -42.4 1166.2 -42.9 1166.2 -43.5L1172.3 -49.6C1172.6 -49.9 1172.7 -50.3 1172.7 -50.7L1179.8 -57.8C1180 -58 1180.1 -58.3 1180 -58.6L1382 -260.3L1381.3 -261L1179.2 -58.9C1178.9 -58.9 1178.6 -58.9 1178.4 -58.7L1171.3 -51.6C1170.9 -51.6 1170.5 -51.5 1170.2 -51.2L1164.1 -45.1C1163.6 -45.1 1163 -44.9 1162.6 -44.5L1126 -7.89999C1125.5 -7.39999 1125.3 -6.60001 1125.5 -6L1120.9 -1.39999C1120.6 -1.10001 1120.6 -0.799988 1120.7 -0.399994L924 196.2C923.5 196 922.9 196.1 922.5 196.5L910.3 208.7C909.8 208.7 909.2 208.9 908.8 209.3L872.1 246C871.7 246.4 871.5 246.9 871.5 247.5L860.8 258.2C860.5 258.5 860.4 258.8 860.4 259.1L853.3 266.2C853 266.5 853 266.8 853.1 267.2L656.7 463.5L657.4 464.2L853.9 267.7C853.9 267.8 854 267.8 854.1 267.8Z"
                fill="#1D6BFC"
              />
              <path
                d="M848.5 249.4C848.8 249.4 849 249.3 849.2 249.1L856.3 242C856.6 242 857 241.9 857.2 241.6L867.9 230.9C868.4 230.9 868.9 230.7 869.3 230.3L907.9 191.7C908.3 191.3 908.5 190.8 908.5 190.2L922.2 176.5C922.6 176.1 922.7 175.5 922.5 175L1102.7 -5.20001C1102.8 -5.20001 1102.9 -5.20001 1102.9 -5.20001C1103.2 -5.20001 1103.4 -5.29999 1103.6 -5.5L1108.2 -10.1C1108.4 -10.1 1108.5 -10 1108.7 -10C1109.2 -10 1109.7 -10.2 1110.1 -10.6L1147 -47.5C1147.4 -47.9 1147.6 -48.4 1147.6 -49L1152.8 -54.2C1153.1 -54.5 1153.2 -54.9 1153.2 -55.3L1160.3 -62.4C1160.5 -62.6 1160.6 -62.9 1160.5 -63.2L1370 -272.3L1369.3 -273L1159.9 -63.7C1159.6 -63.7 1159.3 -63.7 1159.1 -63.5L1152 -56.4C1151.6 -56.4 1151.2 -56.3 1150.9 -56L1145.7 -50.8C1145.2 -50.8 1144.6 -50.6 1144.2 -50.2L1107.3 -13.3C1106.8 -12.8 1106.6 -12 1106.8 -11.4L1102.2 -6.79999C1101.9 -6.5 1101.9 -6.19998 1102 -5.79999L921.9 174.4C921.4 174.2 920.8 174.3 920.4 174.7L906.7 188.4C906.2 188.4 905.6 188.6 905.2 189L866.6 227.6C866.2 228 866 228.5 866 229.1L855.3 239.8C855 240.1 854.9 240.4 854.9 240.7L847.8 247.8C847.5 248.1 847.5 248.4 847.6 248.8L644.7 451.5L645.4 452.2L848.3 249.3C848.3 249.4 848.4 249.4 848.5 249.4Z"
                fill="#1D6BFC"
              />
              <path
                d="M845.9 240.1C846.2 240.1 846.4 240 846.6 239.8L853.7 232.7C854 232.7 854.4 232.6 854.6 232.3L865.3 221.6C865.3 221.6 865.3 221.6 865.4 221.6C865.9 221.6 866.4 221.4 866.8 221L906.8 181C907.2 180.6 907.4 180.1 907.4 179.5L921.7 165.2C922.1 164.8 922.2 164.2 922 163.7L1092.7 -7C1092.8 -7 1092.9 -7 1092.9 -7C1093.2 -7 1093.4 -7.09998 1093.6 -7.29999L1098.2 -11.9C1098.4 -11.9 1098.5 -11.8 1098.7 -11.8C1099.2 -11.8 1099.7 -12 1100.1 -12.4L1137.8 -50.1C1138.2 -50.5 1138.4 -51 1138.4 -51.6L1143.4 -56.6C1143.7 -56.9 1143.8 -57.3 1143.8 -57.7L1150.9 -64.8C1151.1 -65 1151.2 -65.3 1151.1 -65.6L1364 -278.3L1363.3 -279L1150.3 -66.1C1150 -66.1 1149.7 -66.1 1149.5 -65.9L1142.4 -58.8C1142 -58.8 1141.6 -58.7 1141.3 -58.4L1136.3 -53.4C1135.8 -53.4 1135.2 -53.2 1134.8 -52.8L1097.1 -15.1C1096.6 -14.6 1096.4 -13.8 1096.6 -13.2L1092 -8.60001C1091.7 -8.30002 1091.7 -8 1091.8 -7.60001L921.2 163C920.7 162.8 920.1 162.9 919.7 163.3L905.4 177.6C904.9 177.6 904.3 177.8 903.9 178.2L863.9 218.2C863.5 218.6 863.3 219.1 863.3 219.7L852.6 230.4C852.3 230.7 852.2 231 852.2 231.3L845.1 238.4C844.8 238.7 844.8 239 844.9 239.4L638.7 445.5L639.4 446.2L845.6 240C845.7 240.1 845.8 240.1 845.9 240.1Z"
                fill="#1D6BFC"
              />
              <path
                d="M843.5 230.5C843.8 230.5 844 230.4 844.2 230.2L851.3 223.1C851.6 223.1 852 223 852.2 222.7L862.9 212C862.9 212 862.9 212 863 212C863.5 212 864 211.8 864.4 211.4L906.2 169.6C906.6 169.2 906.8 168.7 906.8 168.1L922.2 152.7C922.6 152.3 922.7 151.7 922.5 151.2L1081.6 -8C1081.7 -8 1081.8 -8 1081.8 -8C1082.1 -8 1082.3 -8.09998 1082.5 -8.29999L1087.1 -12.9C1087.3 -12.9 1087.4 -12.8 1087.6 -12.8C1088.1 -12.8 1088.6 -13 1089 -13.4L1128.1 -52.5C1128.5 -52.9 1128.7 -53.4 1128.7 -54L1133.4 -58.7C1133.7 -59 1133.8 -59.4 1133.8 -59.8L1140.9 -66.9C1141.1 -67.1 1141.2 -67.4 1141.1 -67.7L1358 -284.3L1357.3 -285L1140.4 -68.2C1140.1 -68.2 1139.8 -68.2 1139.6 -68L1132.5 -60.9C1132.1 -60.9 1131.7 -60.8 1131.4 -60.5L1126.7 -55.8C1126.2 -55.8 1125.6 -55.6 1125.2 -55.2L1086.1 -16.1C1085.6 -15.6 1085.4 -14.8 1085.6 -14.2L1081 -9.60001C1080.7 -9.30002 1080.7 -9 1080.8 -8.60001L921.7 150.5C921.2 150.3 920.6 150.4 920.2 150.8L904.8 166.2C904.3 166.2 903.7 166.4 903.3 166.8L861.5 208.6C861.1 209 860.9 209.5 860.9 210.1L850.2 220.8C849.9 221.1 849.8 221.4 849.8 221.7L842.7 228.8C842.4 229.1 842.4 229.4 842.5 229.8L632.7 439.5L633.4 440.2L843.2 230.4C843.3 230.5 843.4 230.5 843.5 230.5Z"
                fill="#1D6BFC"
              />
              <path
                d="M841.3 220.6C841.6 220.6 841.8 220.5 842 220.3L849.1 213.2C849.4 213.2 849.8 213.1 850 212.8L860.7 202.1C860.7 202.1 860.7 202.1 860.8 202.1C861.3 202.1 861.8 201.9 862.2 201.5L906.6 157.1C907 156.7 907.2 156.2 907.2 155.6L923.6 139.2C924 138.8 924.1 138.2 923.9 137.7L1070.2 -8.60001C1070.3 -8.60001 1070.4 -8.60001 1070.4 -8.60001C1070.7 -8.60001 1070.9 -8.69998 1071.1 -8.89999L1075.7 -13.5C1075.9 -13.5 1076 -13.4 1076.2 -13.4C1076.7 -13.4 1077.2 -13.6 1077.6 -14L1118.2 -54.6C1118.6 -55 1118.8 -55.5 1118.8 -56.1L1123.4 -60.7C1123.7 -61 1123.8 -61.4 1123.8 -61.8L1130.9 -68.9C1131.1 -69.1 1131.2 -69.4 1131.1 -69.7L1352 -290.3L1351.3 -291L1130.5 -70.2C1130.2 -70.2 1129.9 -70.2 1129.7 -70L1122.6 -62.9C1122.2 -62.9 1121.8 -62.8 1121.5 -62.5L1116.9 -57.9C1116.4 -57.9 1115.8 -57.7 1115.4 -57.3L1074.8 -16.7C1074.3 -16.2 1074.1 -15.4 1074.3 -14.8L1069.7 -10.2C1069.4 -9.90002 1069.4 -9.60001 1069.5 -9.20001L923.2 137C922.7 136.8 922.1 136.9 921.7 137.3L905.3 153.7C904.8 153.7 904.2 153.9 903.8 154.3L859.4 198.7C859 199.1 858.8 199.6 858.8 200.2L848.1 210.9C847.8 211.2 847.7 211.5 847.7 211.8L840.6 218.9C840.3 219.2 840.3 219.5 840.4 219.9L626.7 433.5L627.4 434.2L841.1 220.5C841.2 220.6 841.3 220.6 841.3 220.6Z"
                fill="#1D6BFC"
              />
              <path
                d="M839.3 210.6C839.6 210.6 839.8 210.5 840 210.3L847.1 203.2C847.4 203.2 847.8 203.1 848 202.8L858.7 192.1C859.2 192.1 859.7 191.9 860.1 191.5L908 143.6C908.4 143.2 908.6 142.7 908.6 142.1L926.5 124.2C926.9 123.8 927 123.2 926.8 122.7L1058.7 -9C1058.8 -9 1058.9 -9 1058.9 -9C1059.2 -9 1059.4 -9.09998 1059.6 -9.29999L1064.2 -13.9C1064.4 -13.9 1064.5 -13.8 1064.7 -13.8C1065.2 -13.8 1065.7 -14 1066.1 -14.4L1108.3 -56.6C1109 -57.3 1109.1 -58.3 1108.6 -59L1346 -296.3L1345.3 -297L1107.9 -59.7C1107.1 -60.2 1106.1 -60.1 1105.5 -59.4L1063.3 -17.2C1062.8 -16.7 1062.6 -15.9 1062.8 -15.3L1058.2 -10.7C1057.9 -10.4 1057.9 -10.1 1058 -9.70001L926.1 122.1C925.6 121.9 925 122 924.6 122.4L917.3 129.7L906.7 140.3C906.2 140.3 905.6 140.5 905.2 140.9L857.3 188.8C856.9 189.2 856.7 189.7 856.7 190.3L846 201C845.7 201.3 845.6 201.6 845.6 201.9L838.5 209C838.2 209.3 838.2 209.6 838.3 210L620.7 427.5L621.4 428.2L839.1 210.5C839.1 210.6 839.2 210.6 839.3 210.6Z"
                fill="#1D6BFC"
              />
              <path
                d="M881.6 48.2C881.9 48.2 882.1 48.1 882.3 47.9L889.4 40.8C889.7 40.8 890.1 40.7 890.3 40.4L901 29.7C901 29.7 901 29.7 901.1 29.7C901.6 29.7 902.1 29.5 902.5 29.1L948.5 -16.9C949.2 -17.6 949.3 -18.6 948.8 -19.3L1285.9 -356.4L1285.2 -357.1L948.1 -19.9C947.3 -20.4 946.3 -20.3 945.7 -19.6L899.7 26.4C899.3 26.8 899.1 27.3 899.1 27.9L888.4 38.6C888.1 38.9 888 39.2 888 39.5L880.9 46.6C880.6 46.9 880.6 47.2 880.7 47.6L560.7 367.6L561.4 368.3L881.4 48.3C881.5 48.2 881.6 48.2 881.6 48.2Z"
                fill="#1D6BFC"
              />
              <path
                d="M838.6 151.6C838.9 151.6 839.1 151.5 839.3 151.3L846.5 144C846.7 143.9 847 143.8 847.2 143.7L857.9 133C857.9 133 857.9 133 858 133C858.5 133 859 132.8 859.4 132.4L1048 -56.3C1048.4 -56.7 1048.6 -57.2 1048.6 -57.8L1049.3 -58.5C1049.7 -58.9 1049.8 -59.5 1049.6 -60L1315.9 -326.3L1315.2 -327L1048.9 -60.7C1048.4 -60.9 1047.8 -60.8 1047.4 -60.4L1046.7 -59.7C1046.2 -59.7 1045.6 -59.5 1045.2 -59.1L856.5 129.6C856.1 130 855.9 130.5 855.9 131.1L845.2 141.8C844.9 142.1 844.8 142.5 844.8 142.9L837.8 149.9C837.6 150.1 837.5 150.4 837.5 150.7L590.6 397.6L591.3 398.3L838.1 151.5C838.3 151.5 838.4 151.6 838.6 151.6Z"
                fill="#1D6BFC"
              />
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_41299_70617"
            x1="0"
            y1="73.5"
            x2="1100"
            y2="73.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#431554" />
            <stop offset="0.5" stop-color="#452667" />
            <stop offset="1" stop-color="#2B1B52" />
          </linearGradient>
          <clipPath id="clip0_41299_70617">
            <rect width="720" height="720" fill="white" transform="translate(0 -198)" />
          </clipPath>
          <clipPath id="clip1_41299_70617">
            <rect width="720" height="720" fill="white" transform="translate(720 -197)" />
          </clipPath>
        </defs>
      </svg>
    `)
