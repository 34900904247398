import _get from 'lodash/get'

import { Instance } from '@popperjs/core'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { BrainIcon, ForwardIcon } from '@opswat/react-icon'
import { Box, Button, ListItem, Tooltip, Typography } from '@opswat/react-ui'

interface IProps {
  propsData: any
}

const AcademyOngoingCourse = ({ propsData }: IProps) => {
  const { totalLesson, totalLessonComplete, percentComplete } = propsData
  const percentCompleteNumber = totalLesson ? ((totalLessonComplete / totalLesson) * 100).toFixed(0) : 0

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  })
  const popperRef = useRef<Instance>(null)
  const progressRef = useRef<HTMLDivElement>(null)

  const handleUpdateTooltip = () => {
    if (progressRef.current) {
      const progressRect = progressRef.current.getBoundingClientRect()
      positionRef.current = {
        x: progressRect.x + (progressRect.width * Number(percentCompleteNumber) * 0.5) / 100,
        y: progressRect.y
      }
      if (popperRef.current) {
        popperRef.current.update()
      }
    }
  }

  useEffect(() => {
    handleUpdateTooltip()
  }, [])

  return (
    <ListItem
      component={Link}
      to={_get(propsData, 'courseUrl')}
      target="_blank"
      sx={{
        width: '100%',
        height: '100%',
        transition: 'transform 0.75s',
        padding: '16px 20px 10px 20px',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <img src={_get(propsData, 'courseIconBadgeUrl')} alt="academy course badge" width={70} height={96} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '10px',
          height: '100%'
        }}
      >
        <Typography variant="h3" lineHeight="20px" color="#141E2F">
          {_get(propsData, 'courseName')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', height: '20px' }}>
            <BrainIcon size={16} color="#1B273C" />
            <Typography variant="subtitle2" color="#485161">
              {totalLessonComplete}/{totalLesson} lessons
            </Typography>
          </Box>
          <Tooltip
            arrow
            title={percentComplete}
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB',
                  whiteSpace: 'pre-line'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }}
            slotProps={{
              popper: {
                popperRef,
                anchorEl: {
                  getBoundingClientRect: () => {
                    return new DOMRect(positionRef.current.x, positionRef.current.y, 0, 0)
                  }
                }
              }
            }}
          >
            <Box
              ref={progressRef}
              onMouseMove={handleUpdateTooltip}
              onMouseOut={handleUpdateTooltip}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box sx={{ height: '4px', background: '#1D6BFC', width: `${percentCompleteNumber}%` }} />
              <Box sx={{ height: '4px', background: '#E1E9FE', width: `${100 - Number(percentCompleteNumber)}%` }} />
            </Box>
          </Tooltip>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="text"
              color="primary"
              sx={{
                alignItems: 'center',
                gap: '8px'
              }}
            >
              Continue <ForwardIcon color="#1D6BFC" size={16} />
            </Button>
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export default AcademyOngoingCourse
