import { AlertTitle, Typography, TypographyLink } from '@opswat/react-ui'
import { useDismissSystemNotificationMutation } from 'myopswat-web/src/api/system-noti'
import { enqueueSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo } from 'react'

interface IProps {
  data: any
}

const DialogSystemNotification: FC<IProps> = ({ data }) => {
  const [dismissNoti] = useDismissSystemNotificationMutation()

  const responseSystemNotification = useCallback(
    async (systemNotificationId: string) => {
      try {
        await dismissNoti(systemNotificationId)
          .unwrap()
          .then()
          .catch(err => {
            console.error(err)
          })
      } catch (error) {
        console.error(error)
      }
    },
    [dismissNoti]
  )

  const snackbarInfo = useMemo(() => {
    if (data) {
      const settings = data.other_settings
      return (
        <>
          <AlertTitle>{data.title}</AlertTitle>
          <Typography variant="body2">{data.content}</Typography>
          {data.detail_url && (
            <TypographyLink variant="body2" color="primary" href={data.detail_url} target="_blank">
              {settings?.detailUrlLabel}
            </TypographyLink>
          )}
        </>
      )
    }
    return undefined
  }, [data])

  useEffect(() => {
    if (snackbarInfo) {
      enqueueSnackbar(snackbarInfo, {
        variant: data.type,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        onClose: () => {
          responseSystemNotification(data.id)
        },
        SnackbarProps: {
          style: {
            width: '400px'
          }
        },
        ...(data.other_settings?.snackbarOptions || {})
      })
    }
  }, [snackbarInfo, data])

  return <></>
}

export default DialogSystemNotification
