import { Grid, TypographyDivider } from '@opswat/react-ui'
import { Helmet } from 'react-helmet'

import AcademyProvider from 'myopswat-web/src/pages/AcademyPage/context'

const AcademyContainer = () => {
  const breadcrumbs = [
    {
      label: 'OPSWAT Academy'
    }
  ]

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'OPSWAT Academy'}
        </title>
      </Helmet>{' '}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TypographyDivider breadcrumbsArray={breadcrumbs} isDivider={false} />
        </Grid>
        <Grid item container xs={12}>
          <AcademyProvider />
        </Grid>
      </Grid>
    </>
  )
}

export default AcademyContainer
