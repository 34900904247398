import { useModal } from '@ebay/nice-modal-react'
import { formatDatetime } from '@opswat/react-core'
import { DataTable, TableLoading, TemplateSection, Typography, TypographyNoData } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useOrganizationUsersQuery } from 'myopswat-web/src/api/organization'
import { OrganizationUserFilterInput } from 'myopswat-web/src/api/organization/types'
import { PORTAL_ROLES_DEFAULT } from 'myopswat-web/src/constants'
import { useTypedSelector } from 'myopswat-web/src/store'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useHasPermissions from '../../../hooks/useHasPermissions'
import UserAction from './actions'
import ChangeRoleModal from './ChangeRole/ChangeRoleModal'
import CreateSubOrganizationModel from './CreateSubOrganization/CreateSubOrganizationModel'
import InviteUserModal from './InviteUser/InviteUserModal'
import RemoveUserModal from './RemoveUser/RemoveUserModal'
import SetSuperAdminModal from './SetSuperAdmin/SetSuperAdminModal'
import UserFilter from './UserFilter'

const renderActive = (active: null | boolean) => {
  return (
    <Typography
      sx={{
        color: active ? 'success.dark' : 'warning.dark'
      }}
      variant="body2"
    >
      {active ? 'Active' : 'Pending'}
    </Typography>
  )
}

const renderFullName = (fullName: string) => {
  return (
    <Typography variant="body2" textTransform="capitalize">
      {fullName}
    </Typography>
  )
}

const defaultQuery: OrganizationUserFilterInput = {
  q: '',
  status: [],
  portalRoles: []
}

interface IProps {
  organization: any
  isFetching: boolean
  permissions: string[]
}

const UserTab = ({ organization, isFetching, permissions }: IProps) => {
  const hasFullOrgUsersPerm = useHasPermissions({
    targetPerms: ['full_my_organizations_users'],
    userPerms: permissions
  })
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const { state } = useLocation()
  const [query, setQuery] = useState<OrganizationUserFilterInput>(defaultQuery)
  const {
    data,
    isFetching: isFetchingUsers,
    refetch: refetchOrganizationUsers
  } = useOrganizationUsersQuery(query, {
    refetchOnMountOrArgChange: true
  })

  // Modals
  const inviteModal = useModal(InviteUserModal)
  const removeModal = useModal(RemoveUserModal)
  const changeRoleModal = useModal(ChangeRoleModal)
  const setSuperAdminModel = useModal(SetSuperAdminModal)
  const createSubOrganization = useModal(CreateSubOrganizationModel)

  const tableData = useMemo(() => {
    if (_isEmpty(data)) return []

    return data.organizationUsers.map((organizationUser: any) => ({
      id: organizationUser.id,
      name: organizationUser.fullName,
      email: organizationUser.email || organizationUser.userEmail,
      groups: '',
      roles: organizationUser.roleIds,
      lastLogin: formatDatetime(organizationUser.lastLogin, undefined, _get(profile, 'timezone')) || '--',
      isActive: organizationUser.isActive,
      userId: organizationUser.userId,
      userSsoId: organizationUser.userSsoId,
      invitationId: organizationUser.invitationId,
      roleIds: organizationUser.roleIds,
      isSuperadmin: organizationUser.isSuperadmin,
      portalRoleIds: organizationUser.portalRoleIds
    }))
  }, [data])

  const onCreateSubOrganization = () => {
    createSubOrganization.show({ organization }).then(async () => {
      await refetchOrganizationUsers()
    })
  }
  const disabledCreateSubOrg = _get(_get(organization, 'ssoMeta', {}), 'rank') == 5
  const roles = useMemo(() => {
    if (_isEmpty(data)) return []

    return data.portalRoles.organizationPortalRoles
  }, [data])

  const roleMap = useMemo(() => {
    return roles.reduce((obj: any, role: any) => ((obj[role.id] = role), obj), {})
  }, [data])

  const nonSuperAdminRoleMap = useMemo(() => {
    return Object.fromEntries(Object.entries(roleMap).filter((entry: any[]) => entry[1].name !== 'Super Admin'))
  }, [roleMap])

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Full Name',
        size: 150,
        Cell: ({ cell }: { cell: any }) => renderFullName(cell.getValue())
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150
      },
      {
        accessorKey: 'isActive',
        header: 'Active',
        size: 80,
        Cell: ({ cell }: { cell: any }) => renderActive(cell.getValue())
      },
      // {
      //   accessorKey: 'groups',
      //   header: 'Groups',
      //   size: 100
      // },
      {
        accessorKey: 'portalRoleIds',
        header: 'Role',
        size: 100,
        Cell: ({ cell }: { cell: any }) => {
          return cell
            .getValue()
            .map((role: string) => _get(roleMap, role, '').name)
            .join(', ')
        }
      },
      {
        accessorKey: 'lastLogin',
        header: 'Last Login',
        size: 150
      }
    ]
  }, [roleMap])

  const handleSearch = (searchData: OrganizationUserFilterInput) => {
    setQuery(prev => ({ ...prev, ...searchData }))
  }

  const onInviteUser = () => {
    inviteModal.show({ roleMap: nonSuperAdminRoleMap, organization, isSingleOrg: true }).then(async () => {
      await refetchOrganizationUsers()
    })
  }

  const onRemoveUser = (orgUser: any) => () => {
    removeModal
      .show({
        orgUser: orgUser
      })
      .then(async () => {
        await refetchOrganizationUsers()
      })
  }

  const onChangeRole = (user: any) => () => {
    changeRoleModal
      .show({
        user: user,
        roleMap: nonSuperAdminRoleMap
      })
      .then(async () => {
        await refetchOrganizationUsers()
      })
  }

  const onSetSuperAdmin = (orgUser: any) => () => {
    setSuperAdminModel.show({ orgUser })
  }

  const isLoading = useMemo(() => {
    return isFetching || isFetchingUsers
  }, [isFetching, isFetchingUsers])

  useEffect(() => {
    if (state?.portalRoles) setQuery(prev => ({ ...prev, portalRoles: state.portalRoles }))
  }, [JSON.stringify(state)])

  useEffect(() => {
    refetchOrganizationUsers()
  }, [organization])

  const isAdmin = (portalRoleIds: string[]) => {
    if (!portalRoleIds) return false
    return _get(roleMap, portalRoleIds[0], '').name === `${PORTAL_ROLES_DEFAULT.ADMIN}`
  }

  const isSuperAdmin = (portalRoleIds: string[]) => {
    if (!portalRoleIds) return false
    return _get(roleMap, portalRoleIds[0], '').name === `${PORTAL_ROLES_DEFAULT.SUPER_ADMIN}`
  }

  const isProfileSuperAdmin = useMemo(() => {
    const profilePortalRoles = _get(profile, 'portalRoleIds', [])
    return _get(roleMap, profilePortalRoles[0], '').name === `${PORTAL_ROLES_DEFAULT.SUPER_ADMIN}`
  }, [profile, roleMap])

  return (
    <TemplateSection spacing={2}>
      <UserFilter
        roles={roles}
        onSearch={handleSearch}
        onCreateSubOrganization={onCreateSubOrganization}
        query={query}
        onInviteUser={onInviteUser}
        disabledCreateSubOrg={disabledCreateSubOrg}
      />

      {isLoading ? (
        <TableLoading />
      ) : tableData && tableData.length > 0 ? (
        <DataTable
          enableRowActions={hasFullOrgUsersPerm}
          columns={columns}
          data={tableData}
          positionActionsColumn={'last'}
          renderRowActions={({ row }: { row: any }) => {
            if (row.original.userId === _get(profile, 'id')) return undefined

            const isRowAdmin = isAdmin(row.original.portalRoleIds)
            const isRowSuperAdmin = isSuperAdmin(row.original.portalRoleIds)
            // If current user is super admin, allow all actions
            if (isRowSuperAdmin) return <></>
            if (isProfileSuperAdmin)
              return (
                <UserAction
                  isActive={row.original.isActive}
                  onChangeRole={onChangeRole(row.original)}
                  onRemoveUser={onRemoveUser(row.original)}
                  onSetSuperAdmin={isRowAdmin ? onSetSuperAdmin(row.original) : undefined}
                />
              )

            // If current row is not admin, show actions
            return (
              <UserAction
                isActive={row.original.isActive}
                onChangeRole={onChangeRole(row.original)}
                onRemoveUser={onRemoveUser(row.original)}
              />
            )
          }}
        />
      ) : (
        <TypographyNoData />
      )}
    </TemplateSection>
  )
}

export default UserTab
