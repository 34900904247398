export const courseBackground = encodeURIComponent(
  `<svg width="227" height="160" viewBox="0 0 227 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_42311_4269)">
      <rect width="1197.28" height="160" transform="translate(-819)" fill="url(#paint0_linear_42311_4269)" />
      <g opacity="0.1">
        <mask
          id="mask0_42311_4269"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="-819"
          y="0"
          width="1198"
          height="160"
        >
          <rect x="-819" width="1197.28" height="160" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_42311_4269)">
          <g clip-path="url(#clip1_42311_4269)">
            <path
              d="M91.476 219.755C91.8025 219.755 92.0202 219.646 92.2379 219.429L99.9658 211.701C100.292 211.701 100.728 211.592 100.945 211.265L102.034 210.177L103.449 208.762L112.592 199.619C112.592 199.619 112.592 199.619 112.7 199.619C113.245 199.619 113.789 199.401 114.224 198.966L115.313 197.878L172.782 140.408C173.217 139.973 173.435 139.429 173.435 138.775L183.666 128.544L194.442 117.769C194.986 117.224 194.986 116.354 194.551 115.81L639.394 -329.034L638.632 -329.796L193.571 115.265C193.136 115.265 192.7 115.374 192.374 115.701L171.367 136.707C170.823 136.707 170.17 136.925 169.734 137.361L161.789 145.306L111.177 195.918C110.741 196.354 110.524 196.898 110.524 197.551L99.9658 208.109L98.8773 209.197C98.5508 209.524 98.442 209.85 98.442 210.177L91.8025 216.816L90.7141 217.905C90.6052 218.014 90.4964 218.231 90.4964 218.34L-149.939 458.775L-149.177 459.537L90.9318 219.537C91.0406 219.646 91.2583 219.755 91.476 219.755Z"
              fill="#1D6BFC"
            />
            <path
              d="M573.402 -395.186L-215.24 393.456L-214.471 394.226L574.172 -394.417L573.402 -395.186Z"
              fill="#1D6BFC"
            />
            <path
              d="M566.873 -401.719L-221.77 386.923L-221 387.693L567.643 -400.949L566.873 -401.719Z"
              fill="#1D6BFC"
            />
            <path
              d="M560.343 -408.252L-228.299 380.391L-227.53 381.16L561.113 -407.482L560.343 -408.252Z"
              fill="#1D6BFC"
            />
            <path
              d="M553.813 -414.785L-234.829 373.858L-234.059 374.628L554.583 -414.015L553.813 -414.785Z"
              fill="#1D6BFC"
            />
            <path
              d="M547.284 -421.317L-241.358 367.325L-240.589 368.095L548.054 -420.548L547.284 -421.317Z"
              fill="#1D6BFC"
            />
            <path
              d="M540.677 -427.773L-247.965 360.869L-247.196 361.639L541.447 -427.003L540.677 -427.773Z"
              fill="#1D6BFC"
            />
            <path
              d="M534.147 -434.306L-254.495 354.337L-253.725 355.106L534.917 -433.536L534.147 -434.306Z"
              fill="#1D6BFC"
            />
            <path
              d="M527.618 -440.839L-261.024 347.804L-260.255 348.574L528.388 -440.069L527.618 -440.839Z"
              fill="#1D6BFC"
            />
            <path
              d="M521.088 -447.371L-267.554 341.271L-266.785 342.041L521.858 -446.602L521.088 -447.371Z"
              fill="#1D6BFC"
            />
            <path
              d="M514.559 -453.904L-274.083 334.739L-273.314 335.508L515.329 -453.134L514.559 -453.904Z"
              fill="#1D6BFC"
            />
            <path
              d="M508.03 -460.437L-280.613 328.206L-279.843 328.975L508.799 -459.667L508.03 -460.437Z"
              fill="#1D6BFC"
            />
            <path d="M501.5 -466.97L-287.143 321.673L-286.373 322.442L502.269 -466.2L501.5 -466.97Z" fill="#1D6BFC" />
            <path
              d="M494.97 -473.503L-293.672 315.14L-292.903 315.909L495.74 -472.733L494.97 -473.503Z"
              fill="#1D6BFC"
            />
            <path
              d="M488.44 -480.035L-300.202 308.607L-299.433 309.377L489.21 -479.266L488.44 -480.035Z"
              fill="#1D6BFC"
            />
            <path
              d="M481.911 -486.569L-306.732 302.074L-305.962 302.844L482.68 -485.799L481.911 -486.569Z"
              fill="#1D6BFC"
            />
            <path
              d="M475.381 -493.101L-313.261 295.541L-312.492 296.311L476.151 -492.332L475.381 -493.101Z"
              fill="#1D6BFC"
            />
            <path
              d="M468.851 -499.634L-319.791 289.009L-319.021 289.778L469.621 -498.864L468.851 -499.634Z"
              fill="#1D6BFC"
            />
            <path
              d="M462.322 -506.167L-326.321 282.476L-325.551 283.245L463.091 -505.397L462.322 -506.167Z"
              fill="#1D6BFC"
            />
            <path d="M455.792 -512.7L-332.85 275.943L-332.08 276.713L456.562 -511.93L455.792 -512.7Z" fill="#1D6BFC" />
            <path
              d="M449.185 -519.309L-339.457 269.333L-338.687 270.103L449.955 -518.54L449.185 -519.309Z"
              fill="#1D6BFC"
            />
            <path
              d="M442.656 -525.842L-345.986 262.801L-345.217 263.57L443.426 -525.072L442.656 -525.842Z"
              fill="#1D6BFC"
            />
            <path
              d="M436.126 -532.375L-352.516 256.268L-351.746 257.037L436.896 -531.605L436.126 -532.375Z"
              fill="#1D6BFC"
            />
            <path
              d="M429.597 -538.907L-359.046 249.735L-358.276 250.505L430.366 -538.138L429.597 -538.907Z"
              fill="#1D6BFC"
            />
            <path
              d="M423.067 -545.44L-365.575 243.202L-364.806 243.972L423.837 -544.671L423.067 -545.44Z"
              fill="#1D6BFC"
            />
            <path
              d="M416.537 -551.973L-372.105 236.669L-371.335 237.439L417.307 -551.203L416.537 -551.973Z"
              fill="#1D6BFC"
            />
            <path
              d="M410.008 -558.506L-378.635 230.137L-377.865 230.906L410.777 -557.736L410.008 -558.506Z"
              fill="#1D6BFC"
            />
            <path
              d="M403.478 -565.038L-385.164 223.604L-384.394 224.374L404.248 -564.269L403.478 -565.038Z"
              fill="#1D6BFC"
            />
            <path
              d="M396.949 -571.571L-391.694 217.071L-390.924 217.841L397.718 -570.802L396.949 -571.571Z"
              fill="#1D6BFC"
            />
            <path
              d="M390.419 -578.104L-398.224 210.538L-397.454 211.308L391.188 -577.334L390.419 -578.104Z"
              fill="#1D6BFC"
            />
            <path
              d="M383.889 -584.637L-404.753 204.006L-403.983 204.775L384.659 -583.867L383.889 -584.637Z"
              fill="#1D6BFC"
            />
            <path d="M377.36 -591.17L-411.283 197.473L-410.513 198.243L378.129 -590.4L377.36 -591.17Z" fill="#1D6BFC" />
            <path d="M370.83 -597.702L-417.812 190.94L-417.043 191.71L371.6 -596.933L370.83 -597.702Z" fill="#1D6BFC" />
            <path
              d="M364.3 -604.235L-424.342 184.407L-423.573 185.177L365.07 -603.465L364.3 -604.235Z"
              fill="#1D6BFC"
            />
            <path
              d="M357.771 -610.768L-430.872 177.875L-430.102 178.644L358.54 -609.998L357.771 -610.768Z"
              fill="#1D6BFC"
            />
            <path d="M1011.37 42.84L222.724 831.482L223.494 832.252L1012.14 43.6096L1011.37 42.84Z" fill="#1D6BFC" />
            <path d="M1004.84 36.3071L216.194 824.95L216.964 825.719L1005.61 37.0768L1004.84 36.3071Z" fill="#1D6BFC" />
            <path d="M998.307 29.7744L209.665 818.417L210.434 819.187L999.077 30.544L998.307 29.7744Z" fill="#1D6BFC" />
            <path
              d="M991.778 23.2417L203.135 811.884L203.905 812.654L992.547 24.0113L991.778 23.2417Z"
              fill="#1D6BFC"
            />
            <path
              d="M985.249 16.7086L196.606 805.351L197.376 806.121L986.018 17.4782L985.249 16.7086Z"
              fill="#1D6BFC"
            />
            <path
              d="M978.719 10.1754L190.076 798.818L190.846 799.588L979.488 10.9451L978.719 10.1754Z"
              fill="#1D6BFC"
            />
            <path
              d="M972.189 3.64273L183.546 792.285L184.316 793.055L972.958 4.41236L972.189 3.64273Z"
              fill="#1D6BFC"
            />
            <path
              d="M965.659 -2.89007L177.017 785.752L177.786 786.522L966.429 -2.12044L965.659 -2.89007Z"
              fill="#1D6BFC"
            />
            <path
              d="M959.13 -9.42286L170.487 779.22L171.257 779.989L959.899 -8.65322L959.13 -9.42286Z"
              fill="#1D6BFC"
            />
            <path d="M952.6 -15.9557L163.958 772.687L164.727 773.456L953.37 -15.186L952.6 -15.9557Z" fill="#1D6BFC" />
            <path
              d="M946.07 -22.4884L157.428 766.154L158.197 766.924L946.84 -21.7187L946.07 -22.4884Z"
              fill="#1D6BFC"
            />
            <path
              d="M939.541 -29.0212L150.898 759.621L151.668 760.391L940.311 -28.2515L939.541 -29.0212Z"
              fill="#1D6BFC"
            />
            <path
              d="M933.011 -35.5539L144.369 753.089L145.138 753.858L933.781 -34.7843L933.011 -35.5539Z"
              fill="#1D6BFC"
            />
            <path
              d="M528.809 239.782C529.136 239.782 529.354 239.674 529.571 239.456L534.578 234.449C534.796 234.449 534.905 234.558 535.122 234.558C535.667 234.558 536.211 234.34 536.646 233.905L601.081 169.469C601.517 169.034 601.735 168.49 601.735 167.837L615.34 154.231C615.667 153.905 615.775 153.469 615.775 153.034L623.503 145.306C623.721 145.089 623.83 144.762 623.721 144.435L868.401 -100.245L867.639 -101.007L622.959 143.782C622.633 143.782 622.306 143.782 622.088 144L614.36 151.728C613.925 151.728 613.49 151.837 613.163 152.163L599.558 165.769C599.013 165.769 598.36 165.986 597.925 166.422L533.49 230.857C532.945 231.401 532.728 232.272 532.945 232.925L527.939 237.932C527.612 238.259 527.612 238.585 527.721 239.021L79.0679 687.782L79.8298 688.544L528.592 239.782C528.701 239.782 528.809 239.782 528.809 239.782Z"
              fill="#1D6BFC"
            />
            <path
              d="M518.142 263.51C518.469 263.51 518.687 263.401 518.904 263.184L523.911 258.177C524.129 258.177 524.238 258.286 524.455 258.286C525 258.286 525.544 258.068 525.979 257.633L599.884 183.728C600.319 183.293 600.537 182.748 600.537 182.095L617.625 165.007C617.952 164.68 618.061 164.245 618.061 163.81L625.789 156.082C626.006 155.864 626.115 155.538 626.006 155.211L874.932 -93.7142L874.17 -94.4761L625.244 154.667C624.918 154.667 624.591 154.667 624.374 154.884L616.646 162.612C616.21 162.612 615.775 162.721 615.449 163.048L598.36 180.136C597.816 180.136 597.163 180.354 596.727 180.789L522.823 254.694C522.278 255.238 522.061 256.109 522.278 256.762L517.272 261.769C516.945 262.095 516.945 262.422 517.054 262.857L85.5981 694.313L86.3601 695.075L517.925 263.51C518.034 263.51 518.142 263.51 518.142 263.51Z"
              fill="#1D6BFC"
            />
            <path
              d="M372.51 422.313C372.836 422.313 373.054 422.204 373.272 421.986L378.279 416.98C378.496 416.98 378.605 417.089 378.823 417.089C379.367 417.089 379.911 416.871 380.347 416.435L599.231 197.551C599.666 197.116 599.884 196.571 599.884 195.918L616.428 179.374C616.755 179.048 616.864 178.612 616.864 178.177L624.591 170.449C624.809 170.231 624.918 169.905 624.809 169.578L881.462 -87.0748L880.7 -87.8367L624.047 168.925C623.721 168.925 623.394 168.925 623.176 169.143L615.449 176.871C615.013 176.871 614.578 176.98 614.251 177.306L597.707 193.85C597.163 193.85 596.51 194.068 596.074 194.503L377.299 413.388C376.755 413.932 376.537 414.803 376.755 415.456L371.748 420.463C371.421 420.789 371.421 421.116 371.53 421.551L92.1289 700.844L92.8908 701.605L372.292 422.204C372.292 422.204 372.401 422.313 372.51 422.313Z"
              fill="#1D6BFC"
            />
            <path
              d="M384.483 423.401C384.809 423.401 385.027 423.293 385.245 423.075L390.251 418.068C390.469 418.068 390.578 418.177 390.796 418.177C391.34 418.177 391.884 417.959 392.32 417.524L597.598 212.245C598.034 211.81 598.251 211.265 598.251 210.612L615.666 193.197C615.993 192.871 616.102 192.435 616.102 192L623.83 184.272C624.047 184.054 624.156 183.728 624.047 183.401L887.993 -80.5441L887.231 -81.306L623.394 182.64C623.068 182.64 622.741 182.64 622.524 182.857L614.796 190.585C614.36 190.585 613.925 190.694 613.598 191.02L596.184 208.435C595.639 208.435 594.986 208.653 594.551 209.089L389.163 414.476C388.619 415.02 388.401 415.891 388.619 416.544L383.612 421.551C383.286 421.878 383.286 422.204 383.394 422.639L98.6597 707.374L99.4216 708.136L384.156 423.401C384.265 423.401 384.374 423.401 384.483 423.401Z"
              fill="#1D6BFC"
            />
            <path
              d="M416.7 417.306C417.027 417.306 417.245 417.197 417.462 416.98L422.469 411.973C422.687 411.973 422.796 412.082 423.013 412.082C423.557 412.082 424.102 411.864 424.537 411.429L590.415 245.551C590.85 245.116 591.068 244.572 591.068 243.919L611.639 223.347C611.966 223.021 612.074 222.585 612.074 222.15L619.802 214.422C620.02 214.204 620.129 213.878 620.02 213.551L901.163 -67.5916L900.401 -68.3535L619.149 213.007C618.823 213.007 618.496 213.007 618.279 213.225L610.551 220.953C610.115 220.953 609.68 221.061 609.353 221.388L588.782 241.959C588.238 241.959 587.585 242.177 587.149 242.612L421.489 408.381C420.945 408.925 420.728 409.796 420.945 410.449L415.938 415.456C415.612 415.783 415.612 416.109 415.721 416.544L111.721 720.436L112.483 721.197L416.483 417.197C416.483 417.306 416.591 417.306 416.7 417.306Z"
              fill="#1D6BFC"
            />
            <path
              d="M435.966 411.102C436.292 411.102 436.51 410.993 436.728 410.776L439.993 407.51C440.319 407.51 440.755 407.402 440.973 407.075L442.17 405.878C442.714 405.878 443.258 405.66 443.694 405.225L583.558 265.578C583.993 265.143 584.211 264.599 584.211 263.946L606.959 241.197C607.285 240.871 607.394 240.436 607.394 240L615.122 232.272C615.34 232.055 615.449 231.728 615.34 231.401L907.694 -60.9522L906.932 -61.7141L614.469 230.748C614.143 230.748 613.816 230.748 613.598 230.966L605.87 238.694C605.435 238.694 605 238.803 604.673 239.129L581.925 261.878C581.381 261.878 580.728 262.095 580.292 262.531L440.646 402.177C440.211 402.612 439.993 403.157 439.993 403.81L438.796 405.007C438.469 405.333 438.36 405.66 438.36 405.987L435.095 409.252C434.768 409.578 434.768 409.905 434.877 410.34L118.251 726.966L119.013 727.728L435.639 411.102C435.748 411.102 435.857 411.102 435.966 411.102Z"
              fill="#1D6BFC"
            />
            <path
              d="M482.659 390.857C483.095 390.857 483.421 390.749 483.748 390.422L498.986 375.184C498.986 375.184 498.986 375.184 499.095 375.184C499.639 375.184 500.183 374.966 500.619 374.531L550.687 324.463C551.122 324.027 551.34 323.483 551.34 322.83L588.891 285.279C589.217 284.953 589.326 284.517 589.326 284.082L597.054 276.354C597.272 276.136 597.38 275.81 597.272 275.483L920.755 -47.9998L919.993 -48.7617L596.51 274.83C596.183 274.83 595.857 274.83 595.639 275.048L587.911 282.776C587.476 282.776 587.04 282.885 586.714 283.211L549.163 320.871C548.619 320.871 547.966 321.089 547.53 321.524L497.462 371.592C497.027 372.027 496.809 372.572 496.809 373.225L481.571 388.463C481.136 388.898 481.027 389.551 481.244 390.095L131.312 740.027L132.074 740.789L482.115 390.748C482.333 390.857 482.442 390.857 482.659 390.857Z"
              fill="#1D6BFC"
            />
            <path
              d="M459.149 401.415C459.584 401.415 459.911 401.306 460.237 400.98L465.68 395.538C466.224 395.538 466.768 395.32 467.203 394.884L572.346 289.742C572.782 289.306 572.999 288.762 572.999 288.109L599.993 261.116C600.319 260.789 600.428 260.354 600.428 259.918L608.156 252.191C608.374 251.973 608.482 251.646 608.374 251.32L914.115 -54.4217L913.353 -55.1836L607.612 250.667C607.285 250.667 606.959 250.667 606.741 250.884L599.013 258.612C598.578 258.612 598.142 258.721 597.816 259.048L570.931 286.041C570.387 286.041 569.734 286.259 569.299 286.694L464.156 391.837C463.72 392.272 463.503 392.816 463.503 393.469L458.061 398.912C457.625 399.347 457.516 400 457.734 400.544L124.673 733.605L125.435 734.367L458.496 401.306C458.714 401.306 458.931 401.415 459.149 401.415Z"
              fill="#1D6BFC"
            />
            <path
              d="M342.469 413.497C342.904 413.497 343.231 413.388 343.557 413.061L389.489 367.129C389.925 366.694 390.034 366.041 389.816 365.497L533.381 221.932C533.489 221.932 533.598 221.932 533.598 221.932C533.925 221.932 534.143 221.823 534.36 221.606L539.367 216.599C539.585 216.599 539.694 216.708 539.911 216.708C540.455 216.708 541 216.49 541.435 216.055L598.904 158.585C599.34 158.15 599.557 157.606 599.557 156.953L613.163 143.347C613.489 143.021 613.598 142.585 613.598 142.15L621.326 134.422C621.544 134.204 621.653 133.878 621.544 133.551L861.87 -106.558L861.108 -107.32L620.782 132.898C620.455 132.898 620.129 132.898 619.911 133.116L612.183 140.844C611.748 140.844 611.313 140.952 610.986 141.279L597.381 154.884C596.836 154.884 596.183 155.102 595.748 155.538L538.279 213.007C537.734 213.551 537.517 214.422 537.734 215.075L532.728 220.082C532.401 220.408 532.401 220.735 532.51 221.17L389.054 364.735C388.51 364.517 387.857 364.626 387.421 365.061L341.489 410.993C341.054 411.429 340.945 412.082 341.163 412.626L72.5371 681.252L73.299 682.014L341.925 413.388C342.143 413.497 342.251 413.497 342.469 413.497Z"
              fill="#1D6BFC"
            />
            <path
              d="M516.075 370.612C516.51 370.612 516.836 370.503 517.163 370.177L567.231 320.109C567.558 319.782 567.666 319.347 567.666 318.912L575.394 311.184C575.612 310.966 575.721 310.64 575.612 310.313L927.286 -41.3604L926.524 -42.1223L574.741 309.66C574.415 309.66 574.088 309.66 573.87 309.878L566.143 317.606C565.707 317.606 565.272 317.714 564.945 318.041L514.877 368.109C514.442 368.544 514.333 369.197 514.551 369.742L137.843 746.558L138.605 747.32L515.422 370.504C515.639 370.612 515.857 370.612 516.075 370.612Z"
              fill="#1D6BFC"
            />
            <path
              d="M399.938 421.007C400.265 421.007 400.482 420.898 400.7 420.68L405.707 415.674C405.925 415.674 406.033 415.782 406.251 415.782C406.795 415.782 407.34 415.565 407.775 415.129L595.095 227.81C595.53 227.374 595.748 226.83 595.748 226.177L614.251 207.673C614.578 207.347 614.687 206.912 614.687 206.476L622.414 198.748C622.632 198.531 622.741 198.204 622.632 197.878L894.632 -74.1224L893.87 -74.8843L621.761 197.333C621.435 197.333 621.108 197.333 620.891 197.551L613.163 205.279C612.727 205.279 612.292 205.388 611.965 205.714L593.462 224.218C592.918 224.218 592.265 224.435 591.829 224.871L404.618 412.082C404.074 412.626 403.857 413.497 404.074 414.15L399.067 419.156C398.741 419.483 398.741 419.81 398.85 420.245L105.19 713.905L105.952 714.667L399.721 420.898C399.721 421.007 399.829 421.007 399.938 421.007Z"
              fill="#1D6BFC"
            />
            <path
              d="M163.639 369.197C163.965 369.197 164.183 369.089 164.401 368.871L172.129 361.143C172.455 361.143 172.891 361.034 173.108 360.708L184.755 349.061C184.755 349.061 184.755 349.061 184.863 349.061C185.408 349.061 185.952 348.844 186.387 348.408L222.414 312.381C222.85 311.946 223.067 311.401 223.067 310.748L232.972 300.844C233.408 300.408 233.516 299.755 233.299 299.211L483.203 49.3062C483.312 49.3062 483.421 49.3062 483.421 49.3062C483.748 49.3062 483.965 49.1974 484.183 48.9797L489.19 43.9729C489.408 43.9729 489.516 44.0818 489.734 44.0818C490.278 44.0818 490.823 43.8641 491.258 43.4287L527.285 7.40149C527.72 6.96611 527.938 6.42191 527.938 5.76884L537.299 -3.59172C537.625 -3.91824 537.734 -4.35361 537.734 -4.78899L545.462 -12.5169C545.68 -12.7346 545.788 -13.0611 545.68 -13.3876L750.523 -218.013L749.761 -218.775L544.809 -13.9319C544.482 -13.9319 544.156 -13.9319 543.938 -13.7142L536.21 -5.98627C535.775 -5.98627 535.339 -5.87743 535.013 -5.5509L525.652 3.80965C525.108 3.80965 524.455 4.02734 524.02 4.46272L488.21 40.4899C487.666 41.0341 487.448 41.9049 487.666 42.558L482.659 47.5648C482.333 47.8913 482.333 48.2178 482.442 48.6532L232.428 298.449C231.884 298.231 231.231 298.34 230.795 298.776L220.891 308.68C220.346 308.68 219.693 308.898 219.258 309.333L183.231 345.361C182.795 345.796 182.578 346.34 182.578 346.993L170.931 358.64C170.605 358.966 170.496 359.293 170.496 359.619L162.768 367.347C162.442 367.674 162.442 368 162.55 368.435L-38.9189 569.905L-38.157 570.667L163.312 369.197C163.421 369.197 163.53 369.197 163.639 369.197Z"
              fill="#1D6BFC"
            />
            <path
              d="M90.0609 207.565C90.3874 207.565 90.6051 207.456 90.8228 207.238L98.5507 199.51C98.8772 199.51 99.3126 199.401 99.5303 199.075L111.177 187.429C111.177 187.429 111.177 187.429 111.285 187.429C111.83 187.429 112.374 187.211 112.809 186.776L166.469 133.116L177.245 122.34C177.68 121.905 177.898 121.361 177.898 120.708L187.585 111.021L204.782 93.8233C205.217 93.3879 205.326 92.7348 205.109 92.1906L632.864 -335.565L632.102 -336.326L204.347 91.4287C203.802 91.211 203.149 91.3199 202.714 91.7552L185.517 108.844L175.83 118.64C175.285 118.64 174.632 118.857 174.197 119.293L109.762 183.728C109.326 184.163 109.109 184.708 109.109 185.361L97.4623 196.898C97.1357 197.225 97.0269 197.551 97.0269 197.878L89.299 205.606C88.9725 205.932 88.9725 206.259 89.0813 206.694L-156.47 452.245L-155.708 453.007L89.8432 207.456C89.9521 207.565 90.0609 207.565 90.0609 207.565Z"
              fill="#1D6BFC"
            />
            <path
              d="M89.5165 195.048C89.843 195.048 90.0607 194.939 90.2784 194.721L92.0199 192.98L98.0063 186.993C98.3328 186.993 98.7682 186.884 98.9859 186.558L100.727 184.816L110.632 174.912C110.632 174.912 110.632 174.912 110.741 174.912C111.285 174.912 111.829 174.694 112.265 174.259L114.006 172.517L170.714 115.81L187.911 98.6123C188.346 98.1769 188.564 97.6327 188.564 96.9796L350.523 -64.9795C350.959 -65.4149 351.067 -66.068 350.85 -66.6122L626.333 -342.095L625.571 -342.857L350.088 -67.3741C349.544 -67.5918 348.891 -67.4829 348.455 -67.0475L186.496 94.9116C185.952 94.9116 185.299 95.1293 184.863 95.5647L167.666 112.762L110.959 169.469L109.217 171.211C108.782 171.646 108.564 172.19 108.564 172.844L98.6594 182.748L96.9179 184.49C96.5913 184.816 96.4825 185.143 96.4825 185.469L88.7546 193.197C88.4281 193.524 88.4281 193.85 88.5369 194.286L-163 445.823L-162.239 446.585L89.2988 195.048C89.4077 195.048 89.4077 195.048 89.5165 195.048Z"
              fill="#1D6BFC"
            />
            <path
              d="M92.0203 179.483C92.3468 179.483 92.5645 179.374 92.7822 179.156L100.51 171.429C100.837 171.429 101.272 171.32 101.49 170.993L113.136 159.347C113.136 159.347 113.136 159.347 113.245 159.347C113.789 159.347 114.333 159.129 114.769 158.694L333.653 -60.1905C334.088 -60.6259 334.306 -61.1701 334.306 -61.8232L338.66 -66.1769C339.095 -66.6123 339.204 -67.2653 338.986 -67.8095L619.911 -348.735L619.15 -349.497L338.116 -68.4626C337.571 -68.6803 336.918 -68.5714 336.483 -68.136L332.129 -63.7823C331.585 -63.7823 330.932 -63.5646 330.496 -63.1292L111.612 155.646C111.177 156.082 110.959 156.626 110.959 157.279L99.3128 168.925C98.9863 169.252 98.8774 169.578 98.8774 169.905L91.1495 177.633C90.823 177.959 90.823 178.286 90.9318 178.721L-169.531 439.184L-168.769 439.945L91.6937 179.483C91.8026 179.483 91.9114 179.483 92.0203 179.483Z"
              fill="#1D6BFC"
            />
            <path
              d="M96.156 149.225C96.4826 149.225 96.7002 149.116 96.9179 148.898L104.646 141.17C104.972 141.17 105.408 141.061 105.625 140.735L117.272 129.089C117.272 129.089 117.272 129.089 117.381 129.089C117.925 129.089 118.469 128.871 118.904 128.435L306.224 -58.8842C306.768 -59.4285 306.986 -60.2992 306.768 -61.0611C306.768 -61.2788 306.768 -61.4965 306.659 -61.6053L606.741 -361.687L605.979 -362.449L305.898 -62.3672C305.68 -62.4761 305.462 -62.4761 305.353 -62.4761C304.591 -62.6938 303.829 -62.4761 303.176 -61.9319L115.857 125.388C115.421 125.823 115.204 126.367 115.204 127.021L103.557 138.667C103.231 138.993 103.122 139.32 103.122 139.646L95.3941 147.374C95.0676 147.701 95.0676 148.027 95.1765 148.463L-182.592 426.231L-181.83 426.993L95.9383 149.116C96.0472 149.116 96.156 149.225 96.156 149.225Z"
              fill="#1D6BFC"
            />
            <path
              d="M100.837 131.483C101.163 131.483 101.381 131.374 101.599 131.157L109.326 123.429C109.653 123.429 110.088 123.32 110.306 122.993L121.952 111.347C121.952 111.347 121.952 111.347 122.061 111.347C122.605 111.347 123.15 111.129 123.585 110.694L289.462 -55.1836C290.224 -55.9455 290.333 -57.0339 289.789 -57.7958L600.211 -368.218L599.449 -368.979L289.027 -58.5577C288.156 -59.102 287.068 -58.9931 286.415 -58.2312L120.537 107.646C120.102 108.082 119.884 108.626 119.884 109.279L108.238 120.816C107.911 121.143 107.803 121.469 107.803 121.796L100.075 129.524C99.7482 129.85 99.7482 130.177 99.857 130.612L-189.123 419.592L-188.361 420.354L100.619 131.374C100.728 131.374 100.728 131.483 100.837 131.483Z"
              fill="#1D6BFC"
            />
            <path
              d="M119.122 87.5103C119.449 87.5103 119.666 87.4014 119.884 87.1837L127.612 79.4558C127.938 79.4558 128.374 79.347 128.591 79.0204L140.238 67.3742C140.238 67.3742 140.238 67.3742 140.347 67.3742C140.891 67.3742 141.435 67.1565 141.87 66.7211L247.013 -38.4217C247.775 -39.1836 247.884 -40.272 247.34 -41.0339L587.476 -381.17L586.714 -381.932L246.469 -41.687C245.598 -42.2313 244.51 -42.1224 243.857 -41.3605L138.714 63.7824C138.279 64.2177 138.061 64.762 138.061 65.415L126.415 77.0612C126.088 77.3878 125.979 77.7144 125.979 78.0409L118.251 85.7687C117.925 86.0953 117.925 86.4218 118.034 86.8572L-201.966 406.857L-201.204 407.619L118.796 87.6191C118.904 87.5103 119.013 87.5103 119.122 87.5103Z"
              fill="#1D6BFC"
            />
            <path
              d="M107.694 111.565C108.02 111.565 108.238 111.456 108.456 111.238L116.183 103.51C116.51 103.51 116.945 103.401 117.163 103.075L128.809 91.4286C128.809 91.4286 128.809 91.4286 128.918 91.4286C129.462 91.4286 130.007 91.2109 130.442 90.7755L270.197 -48.9796C270.959 -49.7415 271.068 -50.8299 270.524 -51.5918L593.68 -374.748L592.918 -375.51L269.762 -52.3537C268.891 -52.8979 267.802 -52.7891 267.149 -52.0272L127.394 87.7279C126.959 88.1633 126.741 88.7075 126.741 89.3606L115.095 101.007C114.768 101.333 114.66 101.66 114.66 101.986L106.932 109.714C106.605 110.041 106.605 110.367 106.714 110.803L-195.653 413.17L-194.891 413.932L107.476 111.565C107.585 111.456 107.585 111.565 107.694 111.565Z"
              fill="#1D6BFC"
            />
            <path
              d="M170.496 376.055C170.823 376.055 171.041 375.946 171.258 375.728L178.986 368C179.313 368 179.748 367.891 179.966 367.565L191.612 355.918C191.612 355.918 191.612 355.918 191.721 355.918C192.265 355.918 192.809 355.701 193.245 355.265L229.381 319.129C229.816 318.694 230.034 318.15 230.034 317.497L239.612 307.918C240.047 307.483 240.156 306.83 239.938 306.286L489.843 56.381C489.952 56.381 490.061 56.381 490.061 56.381C490.387 56.381 490.605 56.2722 490.823 56.0545L495.83 51.0477C496.047 51.0477 496.156 51.1565 496.374 51.1565C496.918 51.1565 497.462 50.9388 497.898 50.5035L534.034 14.3674C534.469 13.932 534.687 13.3878 534.687 12.7348L544.047 3.37422C544.374 3.0477 544.483 2.61232 544.483 2.17694L552.537 -5.87747C552.755 -6.09517 552.864 -6.42169 552.755 -6.74823L757.381 -211.374L756.619 -212.136L551.993 -7.51013C551.666 -7.51013 551.34 -7.51013 551.122 -7.29245L543.068 0.761963C542.632 0.761963 542.197 0.870819 541.87 1.19736L532.51 10.5579C531.966 10.5579 531.313 10.7756 530.877 11.211L494.741 47.347C494.197 47.8912 493.979 48.762 494.197 49.415L489.19 54.4218C488.864 54.7484 488.864 55.0749 488.972 55.5103L239.177 305.524C238.632 305.306 237.979 305.415 237.544 305.85L227.966 315.429C227.421 315.429 226.768 315.646 226.333 316.082L190.197 352.218C189.762 352.653 189.544 353.197 189.544 353.85L177.898 365.497C177.571 365.823 177.462 366.15 177.462 366.476L169.734 374.204C169.408 374.531 169.408 374.857 169.517 375.293L-32.0615 576.762L-31.2996 577.524L170.17 376.055C170.279 376.055 170.387 376.055 170.496 376.055Z"
              fill="#1D6BFC"
            />
            <path
              d="M177.462 382.259C177.789 382.259 178.007 382.15 178.224 381.932L185.952 374.204C186.279 374.204 186.714 374.095 186.932 373.769L198.578 362.122C198.578 362.122 198.578 362.122 198.687 362.122C199.231 362.122 199.775 361.905 200.211 361.469L236.347 325.333C236.782 324.898 237 324.354 237 323.701L246.36 314.34C246.796 313.905 246.905 313.252 246.687 312.708L496.047 63.347C496.156 63.347 496.265 63.347 496.265 63.347C496.592 63.347 496.809 63.2381 497.027 63.0204L502.034 58.0136C502.252 58.0136 502.36 58.1225 502.578 58.1225C503.122 58.1225 503.667 57.9048 504.102 57.4694L540.238 21.3334C540.673 20.898 540.891 20.3538 540.891 19.7007L550.578 10.0136C550.905 9.68712 551.013 9.25175 551.013 8.81638L558.741 1.08847C558.959 0.870789 559.068 0.54425 558.959 0.217728L763.911 -204.626L763.15 -205.388L558.197 -0.435333C557.871 -0.435333 557.544 -0.435333 557.326 -0.217636L549.599 7.51024C549.163 7.51024 548.728 7.61909 548.401 7.94562L538.714 17.6327C538.17 17.6327 537.517 17.8504 537.082 18.2858L501.054 54.4218C500.51 54.966 500.292 55.8368 500.51 56.4898L495.503 61.4966C495.177 61.8232 495.177 62.1497 495.286 62.5851L245.816 311.837C245.272 311.619 244.619 311.728 244.184 312.163L234.823 321.524C234.279 321.524 233.626 321.742 233.19 322.177L197.054 358.313C196.619 358.748 196.401 359.293 196.401 359.946L184.755 371.592C184.428 371.918 184.32 372.245 184.32 372.571L176.592 380.299C176.265 380.626 176.265 380.952 176.374 381.388L-25.5308 583.293L-24.7689 584.054L177.136 382.15C177.245 382.15 177.354 382.259 177.462 382.259Z"
              fill="#1D6BFC"
            />
            <path
              d="M184.646 388.027C184.972 388.027 185.19 387.918 185.408 387.701L193.136 379.973C193.462 379.973 193.897 379.864 194.115 379.537L205.761 367.891C205.761 367.891 205.761 367.891 205.87 367.891C206.414 367.891 206.959 367.674 207.394 367.238L243.857 330.776C244.292 330.34 244.51 329.796 244.51 329.143L253.326 320.327C253.761 319.891 253.87 319.238 253.653 318.694L501.598 70.7484C501.707 70.7484 501.816 70.7484 501.816 70.7484C502.142 70.7484 502.36 70.6395 502.578 70.4218L507.584 65.415C507.802 65.415 507.911 65.5239 508.129 65.5239C508.673 65.5239 509.217 65.3062 509.653 64.8708L546.115 28.4082C546.55 27.9729 546.768 27.4286 546.768 26.7756L556.782 16.762C557.108 16.4354 557.217 16.0001 557.217 15.5647L564.945 7.83681C565.163 7.61911 565.272 7.29257 565.163 6.96605L770.442 -198.095L769.68 -198.857L564.401 6.42183C564.074 6.42183 563.748 6.42184 563.53 6.63953L555.802 14.3674C555.367 14.3674 554.931 14.4763 554.605 14.8028L544.591 24.8164C544.047 24.8164 543.394 25.0341 542.959 25.4695L506.496 61.932C505.952 62.4763 505.734 63.347 505.952 64.0001L500.945 69.0069C500.619 69.3334 500.619 69.6599 500.727 70.0953L252.782 318.041C252.238 317.823 251.585 317.932 251.149 318.367L242.333 327.184C241.789 327.184 241.136 327.401 240.7 327.837L204.238 364.299C203.802 364.735 203.585 365.279 203.585 365.932L191.938 377.578C191.612 377.905 191.503 378.231 191.503 378.558L183.775 386.286C183.448 386.612 183.448 386.939 183.557 387.374L-19.0005 589.823L-18.2386 590.585L184.319 388.027C184.428 388.027 184.537 388.027 184.646 388.027Z"
              fill="#1D6BFC"
            />
            <path
              d="M192.047 393.687C192.374 393.687 192.592 393.578 192.809 393.361L200.537 385.633C200.864 385.633 201.299 385.524 201.517 385.197L213.163 373.551C213.163 373.551 213.163 373.551 213.272 373.551C213.816 373.551 214.36 373.333 214.796 372.898L251.367 336.327C251.802 335.891 252.02 335.347 252.02 334.694L260.836 325.878C261.272 325.442 261.381 324.789 261.163 324.245L507.367 78.0409C507.476 78.0409 507.585 78.0409 507.585 78.0409C507.911 78.0409 508.129 77.9321 508.347 77.7144L513.353 72.7076C513.571 72.7076 513.68 72.8164 513.898 72.8164C514.442 72.8164 514.986 72.5987 515.421 72.1633L551.993 35.5919C552.428 35.1566 552.646 34.6123 552.646 33.9593L562.768 23.8368C563.095 23.5103 563.204 23.0749 563.204 22.6396L570.932 14.9117C571.149 14.694 571.258 14.3674 571.149 14.0409L776.972 -191.565L776.211 -192.326L570.17 13.7144C569.843 13.7144 569.517 13.7144 569.299 13.9321L561.571 21.66C561.136 21.66 560.7 21.7688 560.374 22.0953L550.251 32.2178C549.707 32.2178 549.054 32.4355 548.619 32.8708L512.156 69.3334C511.612 69.8777 511.394 70.7484 511.612 71.4015L506.605 76.4083C506.279 76.7348 506.279 77.0613 506.387 77.4967L260.292 323.592C259.748 323.374 259.095 323.483 258.66 323.918L249.843 332.735C249.299 332.735 248.646 332.952 248.211 333.388L211.639 369.959C211.204 370.395 210.986 370.939 210.986 371.592L199.449 383.129C199.122 383.456 199.013 383.782 199.013 384.109L191.285 391.837C190.959 392.163 190.959 392.49 191.068 392.925L-12.4697 596.354L-11.7078 597.116L191.721 393.687C191.83 393.687 191.938 393.687 192.047 393.687Z"
              fill="#1D6BFC"
            />
            <path
              d="M199.666 399.238C199.993 399.238 200.211 399.129 200.428 398.912L208.156 391.184C208.483 391.184 208.918 391.075 209.136 390.748L220.782 379.102C220.782 379.102 220.782 379.102 220.891 379.102C221.435 379.102 221.979 378.885 222.415 378.449L259.204 341.66C259.639 341.225 259.857 340.68 259.857 340.027L268.347 331.538C268.782 331.102 268.891 330.449 268.673 329.905L512.592 85.9865C512.701 85.9865 512.809 85.9865 512.809 85.9865C513.136 85.9865 513.354 85.8777 513.571 85.66L518.578 80.6532C518.796 80.6532 518.905 80.7621 519.122 80.7621C519.667 80.7621 520.211 80.5444 520.646 80.109L557.435 43.3199C557.871 42.8845 558.088 42.3403 558.088 41.6872L568.537 31.2382C568.864 30.9117 568.973 30.4763 568.973 30.041L576.701 22.3131C576.918 22.0954 577.027 21.7688 576.918 21.4423L783.721 -185.36L782.959 -186.122L576.156 20.6804C575.83 20.6804 575.503 20.6804 575.286 20.8981L567.558 28.626C567.122 28.626 566.687 28.7348 566.36 29.0614L555.911 39.5103C555.367 39.5103 554.714 39.728 554.279 40.1634L517.49 76.9525C516.945 77.4968 516.728 78.3675 516.945 79.0206L511.939 84.0274C511.612 84.3539 511.612 84.6804 511.721 85.1158L267.694 329.252C267.149 329.034 266.496 329.143 266.061 329.578L257.571 338.068C257.027 338.068 256.374 338.286 255.939 338.721L219.149 375.51C218.714 375.946 218.496 376.49 218.496 377.143L207.068 388.572C206.741 388.898 206.632 389.225 206.632 389.551L198.905 397.279C198.578 397.606 198.578 397.932 198.687 398.367L-5.93896 602.885L-5.17706 603.646L199.34 399.129C199.449 399.129 199.558 399.238 199.666 399.238Z"
              fill="#1D6BFC"
            />
            <path
              d="M207.503 404.354C207.829 404.354 208.047 404.245 208.265 404.027L215.993 396.299C216.319 396.299 216.755 396.191 216.972 395.864L228.618 384.218C228.618 384.218 228.619 384.218 228.727 384.218C229.272 384.218 229.816 384 230.251 383.565L267.476 346.34C267.911 345.905 268.129 345.361 268.129 344.708L276.074 336.762C276.51 336.327 276.618 335.674 276.401 335.129L517.163 94.3674C517.272 94.3674 517.38 94.3674 517.38 94.3674C517.707 94.3674 517.925 94.2586 518.142 94.0409L523.149 89.0341C523.367 89.0341 523.476 89.1429 523.693 89.1429C524.237 89.1429 524.782 88.9252 525.217 88.4899L562.442 51.2654C562.877 50.83 563.095 50.2858 563.095 49.6328L573.761 38.9661C574.088 38.6396 574.197 38.2042 574.197 37.7688L581.925 30.0409C582.142 29.8232 582.251 29.4967 582.142 29.1702L790.033 -178.721L789.271 -179.483L581.38 28.4082C581.054 28.4082 580.727 28.4083 580.51 28.626L572.782 36.3538C572.346 36.3538 571.911 36.4627 571.584 36.7892L560.918 47.4559C560.374 47.4559 559.721 47.6736 559.285 48.1089L522.061 85.3334C521.516 85.8776 521.299 86.7484 521.516 87.4014L516.51 92.4082C516.183 92.7348 516.183 93.0613 516.292 93.4967L275.53 334.476C274.986 334.259 274.333 334.367 273.897 334.803L265.952 342.748C265.408 342.748 264.755 342.966 264.319 343.401L227.095 380.626C226.659 381.061 226.442 381.606 226.442 382.259L214.795 393.905C214.469 394.231 214.36 394.558 214.36 394.884L206.632 402.612C206.306 402.939 206.306 403.265 206.414 403.701L0.591309 609.415L1.35321 610.177L207.176 404.354C207.285 404.354 207.394 404.354 207.503 404.354Z"
              fill="#1D6BFC"
            />
            <path
              d="M215.666 409.361C215.993 409.361 216.21 409.252 216.428 409.034L224.156 401.306C224.483 401.306 224.918 401.197 225.136 400.871L236.782 389.224C237.326 389.224 237.87 389.007 238.306 388.571L275.966 350.912C276.401 350.476 276.619 349.932 276.619 349.279L284.347 341.551C284.782 341.116 284.891 340.463 284.673 339.918L521.625 102.966C521.734 102.966 521.843 102.966 521.843 102.966C522.17 102.966 522.387 102.857 522.605 102.639L527.612 97.6327C527.83 97.6327 527.938 97.7415 528.156 97.7415C528.7 97.7415 529.244 97.5239 529.68 97.0885L567.34 59.4286C567.775 58.9933 567.993 58.449 567.993 57.796L578.877 46.9116C579.204 46.5851 579.312 46.1497 579.312 45.7143L587.04 37.9865C587.258 37.7688 587.367 37.4422 587.258 37.1157L796.564 -171.973L795.802 -172.735L586.605 36.5715C586.279 36.5715 585.952 36.5715 585.734 36.7892L578.006 44.5171C577.571 44.5171 577.136 44.6259 576.809 44.9524L565.925 55.8368C565.381 55.8368 564.727 56.0545 564.292 56.4898L526.632 94.1497C526.088 94.6939 525.87 95.5647 526.088 96.2177L521.081 101.225C520.755 101.551 520.755 101.878 520.864 102.313L283.911 339.157C283.367 338.939 282.714 339.048 282.279 339.483L274.551 347.211C274.006 347.211 273.353 347.429 272.918 347.864L235.258 385.524C234.823 385.959 234.605 386.503 234.605 387.156L222.959 398.803C222.632 399.129 222.523 399.456 222.523 399.782L214.796 407.51C214.469 407.837 214.469 408.163 214.578 408.599L7.12207 615.946L7.88398 616.707L215.34 409.252C215.449 409.252 215.557 409.361 215.666 409.361Z"
              fill="#1D6BFC"
            />
            <path
              d="M224.047 414.041C224.374 414.041 224.592 413.932 224.809 413.714L232.537 405.987C232.864 405.987 233.299 405.878 233.517 405.551L245.163 393.905C245.707 393.905 246.251 393.687 246.687 393.252L284.891 355.048C285.326 354.612 285.544 354.068 285.544 353.415L292.945 346.014C293.381 345.578 293.49 344.925 293.272 344.381L525.87 111.782C525.979 111.782 526.088 111.782 526.088 111.782C526.415 111.782 526.632 111.674 526.85 111.456L531.857 106.449C532.075 106.449 532.183 106.558 532.401 106.558C532.945 106.558 533.49 106.34 533.925 105.905L572.129 67.7008C572.564 67.2654 572.782 66.7212 572.782 66.0681L583.993 54.8572C584.319 54.5307 584.428 54.0954 584.428 53.66L592.156 45.9321C592.374 45.7144 592.483 45.3878 592.374 45.0613L803.095 -165.442L802.333 -166.204L591.503 44.626C591.177 44.626 590.85 44.6259 590.632 44.8436L582.904 52.5715C582.469 52.5715 582.034 52.6804 581.707 53.0069L570.605 64.2178C570.061 64.2178 569.408 64.4355 568.973 64.8709L530.768 103.075C530.224 103.619 530.007 104.49 530.224 105.143L525.217 110.15C524.891 110.476 524.891 110.803 525 111.238L292.51 343.728C291.966 343.51 291.313 343.619 290.877 344.055L283.476 351.456C282.932 351.456 282.279 351.674 281.843 352.109L243.639 390.313C243.204 390.748 242.986 391.293 242.986 391.946L231.34 403.592C231.013 403.919 230.905 404.245 230.905 404.572L223.177 412.299C222.85 412.626 222.85 412.953 222.959 413.388L13.6528 622.476L14.4147 623.238L223.721 413.932C223.83 413.932 223.939 414.041 224.047 414.041Z"
              fill="#1D6BFC"
            />
            <path
              d="M232.646 418.395C232.972 418.395 233.19 418.286 233.408 418.068L241.135 410.34C241.462 410.34 241.897 410.231 242.115 409.905L253.761 398.259C253.761 398.259 253.761 398.259 253.87 398.259C254.414 398.259 254.959 398.041 255.394 397.606L294.578 358.422C295.013 357.987 295.231 357.442 295.231 356.789L301.87 350.15C302.306 349.714 302.414 349.061 302.197 348.517L529.353 121.361C529.462 121.361 529.571 121.361 529.571 121.361C529.897 121.361 530.115 121.252 530.333 121.034L535.34 116.027C535.557 116.027 535.666 116.136 535.884 116.136C536.428 116.136 536.972 115.918 537.408 115.483L576.591 76.2994C577.027 75.8641 577.244 75.3198 577.244 74.6668L588.673 63.2382C588.999 62.9117 589.108 62.4763 589.108 62.0409L596.836 54.313C597.054 54.0953 597.163 53.7688 597.054 53.4423L809.625 -158.911L808.863 -159.673L596.183 53.0069C595.857 53.0069 595.53 53.0069 595.312 53.2246L587.584 60.9525C587.149 60.9525 586.714 61.0613 586.387 61.3879L574.959 72.9253C574.414 72.9253 573.761 73.143 573.326 73.5784L534.142 112.762C533.598 113.306 533.38 114.177 533.598 114.83L528.591 119.837C528.265 120.163 528.265 120.49 528.374 120.925L301.326 347.864C300.782 347.646 300.129 347.755 299.693 348.191L293.054 354.83C292.51 354.83 291.857 355.048 291.421 355.483L252.238 394.667C251.802 395.102 251.584 395.646 251.584 396.299L240.047 407.837C239.721 408.163 239.612 408.49 239.612 408.816L231.884 416.544C231.557 416.871 231.557 417.197 231.666 417.633L20.1831 629.007L20.945 629.769L232.428 418.395C232.537 418.395 232.537 418.395 232.646 418.395Z"
              fill="#1D6BFC"
            />
            <path
              d="M241.571 422.531C241.898 422.531 242.115 422.422 242.333 422.204L250.061 414.476C250.387 414.476 250.823 414.367 251.04 414.041L262.687 402.395C262.687 402.395 262.687 402.395 262.795 402.395C263.34 402.395 263.884 402.177 264.319 401.742L304.265 361.796C304.7 361.361 304.918 360.816 304.918 360.163L311.449 353.633C311.884 353.197 311.993 352.544 311.775 352L532.619 131.157C532.727 131.157 532.836 131.157 532.836 131.157C533.163 131.157 533.381 131.048 533.598 130.83L538.605 125.823C538.823 125.823 538.932 125.932 539.149 125.932C539.693 125.932 540.238 125.714 540.673 125.279L580.619 85.3334C581.054 84.898 581.272 84.3538 581.272 83.7007L593.027 71.9456C593.353 71.6191 593.462 71.1837 593.462 70.7483L601.19 63.0204C601.408 62.8027 601.517 62.4762 601.408 62.1497L816.156 -152.381L815.394 -153.143L600.537 61.7143C600.21 61.7143 599.884 61.7143 599.666 61.932L591.938 69.6599C591.503 69.6599 591.068 69.7687 590.741 70.0953L578.986 81.8504C578.442 81.8504 577.789 82.0681 577.353 82.5034L537.408 122.449C536.863 122.993 536.646 123.864 536.863 124.517L531.857 129.524C531.53 129.85 531.53 130.177 531.639 130.612L311.013 351.238C310.469 351.02 309.816 351.129 309.381 351.565L302.85 358.095C302.306 358.095 301.653 358.313 301.217 358.748L261.272 398.694C260.836 399.129 260.619 399.674 260.619 400.327L248.972 411.973C248.646 412.299 248.537 412.626 248.537 412.952L240.809 420.68C240.483 421.007 240.483 421.333 240.591 421.769L26.7139 635.538L27.4758 636.299L241.353 422.422C241.462 422.531 241.571 422.531 241.571 422.531Z"
              fill="#1D6BFC"
            />
            <path
              d="M250.823 426.34C251.149 426.34 251.367 426.231 251.585 426.014L259.313 418.286C259.639 418.286 260.075 418.177 260.292 417.85L271.939 406.204C272.483 406.204 273.027 405.986 273.462 405.551L314.17 364.844C314.605 364.408 314.823 363.864 314.823 363.211L321.027 357.007C321.462 356.571 321.571 355.918 321.353 355.374L535.449 141.279C535.558 141.279 535.666 141.279 535.666 141.279C535.993 141.279 536.211 141.17 536.428 140.952L541.435 135.946C541.653 135.946 541.762 136.054 541.979 136.054C542.524 136.054 543.068 135.837 543.503 135.401L584.211 94.6939C584.646 94.2585 584.864 93.7143 584.864 93.0613L596.945 80.9796C597.272 80.6531 597.381 80.2177 597.381 79.7823L605.109 72.0545C605.326 71.8368 605.435 71.5102 605.326 71.1837L822.687 -145.85L821.925 -146.612L604.673 70.6395C604.347 70.6395 604.02 70.6395 603.802 70.8572L596.075 78.5851C595.639 78.5851 595.204 78.6939 594.877 79.0205L582.905 91.1021C582.36 91.1021 581.707 91.3198 581.272 91.7551L540.564 132.463C540.02 133.007 539.802 133.878 540.02 134.531L535.013 139.537C534.687 139.864 534.687 140.191 534.796 140.626L320.7 354.612C320.156 354.395 319.503 354.503 319.068 354.939L312.864 361.143C312.319 361.143 311.666 361.361 311.231 361.796L270.524 402.503C270.088 402.939 269.87 403.483 269.87 404.136L258.224 415.782C257.898 416.109 257.789 416.435 257.789 416.762L250.061 424.49C249.734 424.816 249.734 425.143 249.843 425.578L33.2446 642.068L34.0065 642.83L250.496 426.34C250.714 426.34 250.823 426.34 250.823 426.34Z"
              fill="#1D6BFC"
            />
            <path
              d="M261.707 428.626C262.034 428.626 262.252 428.517 262.469 428.299L270.197 420.572C270.524 420.572 270.959 420.463 271.177 420.136L282.823 408.49C282.823 408.49 282.823 408.49 282.932 408.49C283.476 408.49 284.02 408.272 284.456 407.837L324.619 367.674C325.054 367.238 325.272 366.694 325.272 366.041L331.258 360.054C331.694 359.619 331.803 358.966 331.585 358.422L536.646 153.361C536.755 153.361 536.864 153.361 536.864 153.361C537.19 153.361 537.408 153.252 537.626 153.034L542.633 148.027C542.85 148.027 542.959 148.136 543.177 148.136C543.721 148.136 544.265 147.918 544.701 147.483L586.714 105.469C587.149 105.034 587.367 104.49 587.367 103.837L600.973 90.2314C601.299 89.9048 601.408 89.4694 601.408 89.0341L609.136 81.3062C609.354 81.0885 609.462 80.762 609.354 80.4354L829.217 -139.32L828.456 -140.082L608.483 79.8912C608.156 79.8912 607.83 79.8912 607.612 80.1089L599.884 87.8368C599.449 87.8368 599.013 87.9456 598.687 88.2722L585.081 101.878C584.537 101.878 583.884 102.095 583.449 102.531L541.435 144.544C540.891 145.089 540.673 145.959 540.891 146.612L535.884 151.619C535.558 151.946 535.558 152.272 535.666 152.708L330.823 357.66C330.279 357.442 329.626 357.551 329.19 357.986L323.204 363.973C322.66 363.973 322.007 364.191 321.571 364.626L281.408 404.789C280.973 405.225 280.755 405.769 280.755 406.422L269.109 417.959C268.782 418.286 268.673 418.612 268.673 418.939L260.945 426.667C260.619 426.993 260.619 427.32 260.728 427.755L39.7754 648.599L40.5373 649.361L261.381 428.517C261.49 428.626 261.598 428.626 261.707 428.626Z"
              fill="#1D6BFC"
            />
            <path
              d="M271.829 431.456C272.156 431.456 272.374 431.347 272.591 431.129L280.319 423.401C280.646 423.401 281.081 423.293 281.299 422.966L292.945 411.32C292.945 411.32 292.945 411.32 293.054 411.32C293.598 411.32 294.142 411.102 294.578 410.667L335.612 369.633C336.047 369.197 336.265 368.653 336.265 368L341.598 362.667C342.034 362.231 342.142 361.578 341.925 361.034L538.061 164.898C538.17 164.898 538.278 164.898 538.278 164.898C538.605 164.898 538.823 164.789 539.04 164.572L544.047 159.565C544.265 159.565 544.374 159.674 544.591 159.674C545.136 159.674 545.68 159.456 546.115 159.021L589.653 115.483C590.088 115.048 590.306 114.504 590.306 113.85L603.258 100.898C603.585 100.572 603.693 100.136 603.693 99.7008L611.421 91.9729C611.639 91.7552 611.748 91.4287 611.639 91.1022L835.857 -133.116L835.095 -133.877L610.877 90.3403C610.551 90.3403 610.224 90.3403 610.006 90.558L602.278 98.2858C601.843 98.2858 601.408 98.3947 601.081 98.7212L588.129 111.674C587.585 111.674 586.932 111.891 586.496 112.327L542.959 155.864C542.415 156.408 542.197 157.279 542.415 157.932L537.408 162.939C537.081 163.265 537.081 163.592 537.19 164.027L341.054 360.381C340.51 360.163 339.857 360.272 339.421 360.708L334.088 366.041C333.544 366.041 332.891 366.259 332.455 366.694L291.421 407.728C290.986 408.163 290.768 408.708 290.768 409.361L279.231 420.898C278.904 421.225 278.795 421.551 278.795 421.878L271.068 429.606C270.741 429.932 270.741 430.259 270.85 430.694L46.3057 655.129L47.0676 655.891L271.612 431.456C271.612 431.456 271.721 431.456 271.829 431.456Z"
              fill="#1D6BFC"
            />
            <path
              d="M282.279 434.068C282.605 434.068 282.823 433.959 283.04 433.742L290.768 426.014C291.095 426.014 291.53 425.905 291.748 425.578L303.394 413.932C303.394 413.932 303.394 413.932 303.503 413.932C304.047 413.932 304.592 413.714 305.027 413.279L347.585 370.721C348.02 370.286 348.238 369.742 348.238 369.089L352.483 364.844C352.918 364.408 353.027 363.755 352.809 363.211L538.605 177.415C538.714 177.415 538.823 177.415 538.823 177.415C539.149 177.415 539.367 177.306 539.585 177.089L544.591 172.082C544.809 172.082 544.918 172.191 545.136 172.191C545.68 172.191 546.224 171.973 546.66 171.538L592.156 126.041C592.591 125.606 592.809 125.061 592.809 124.408L606.088 111.129C606.415 110.803 606.523 110.367 606.523 109.932L614.251 102.204C614.469 101.987 614.578 101.66 614.469 101.333L842.279 -126.258L841.517 -127.02L613.707 100.898C613.381 100.898 613.054 100.898 612.836 101.116L605.109 108.844C604.673 108.844 604.238 108.952 603.911 109.279L590.632 122.558C590.088 122.558 589.435 122.776 589 123.211L543.503 168.708C542.959 169.252 542.741 170.123 542.959 170.776L537.952 175.782C537.626 176.109 537.626 176.436 537.734 176.871L352.047 362.449C351.503 362.231 350.85 362.34 350.415 362.776L346.17 367.021C345.626 367.021 344.972 367.238 344.537 367.674L301.979 410.231C301.544 410.667 301.326 411.211 301.326 411.864L289.68 423.51C289.353 423.837 289.245 424.163 289.245 424.49L281.517 432.218C281.19 432.544 281.19 432.871 281.299 433.306L52.8364 661.66L53.5983 662.422L281.952 434.068C282.061 434.068 282.17 434.068 282.279 434.068Z"
              fill="#1D6BFC"
            />
            <path
              d="M293.054 436.463C293.381 436.463 293.598 436.354 293.816 436.136L301.544 428.408C301.871 428.408 302.306 428.299 302.524 427.973L314.17 416.327C314.17 416.327 314.17 416.327 314.279 416.327C314.823 416.327 315.367 416.109 315.803 415.674L359.993 371.483C360.428 371.048 360.646 370.503 360.646 369.85L364.564 365.932C365 365.497 365.109 364.844 364.891 364.299L538.17 191.02C538.279 191.02 538.388 191.02 538.388 191.02C538.714 191.02 538.932 190.912 539.149 190.694L544.156 185.687C544.374 185.687 544.483 185.796 544.701 185.796C545.245 185.796 545.789 185.578 546.224 185.143L594.551 136.816C594.986 136.381 595.204 135.837 595.204 135.184L608.809 121.578C609.136 121.252 609.245 120.816 609.245 120.381L616.973 112.653C617.19 112.435 617.299 112.109 617.19 111.782L848.809 -119.728L848.047 -120.49L616.32 111.347C615.993 111.347 615.667 111.347 615.449 111.565L607.721 119.293C607.286 119.293 606.85 119.401 606.524 119.728L592.918 133.333C592.374 133.333 591.721 133.551 591.286 133.986L542.959 182.313C542.415 182.857 542.197 183.728 542.415 184.381L537.408 189.388C537.081 189.714 537.081 190.041 537.19 190.476L364.02 363.537C363.476 363.32 362.823 363.429 362.388 363.864L358.469 367.782C357.925 367.782 357.272 368 356.837 368.435L312.646 412.626C312.211 413.061 311.993 413.605 311.993 414.258L300.347 425.905C300.02 426.231 299.911 426.558 299.911 426.884L292.184 434.612C291.857 434.939 291.857 435.265 291.966 435.701L59.4761 668.19L60.238 668.952L292.837 436.354C292.837 436.354 292.945 436.463 293.054 436.463Z"
              fill="#1D6BFC"
            />
            <path
              d="M303.938 438.639C304.265 438.639 304.483 438.531 304.7 438.313L312.428 430.585C312.755 430.585 313.19 430.476 313.408 430.15L325.054 418.503C325.054 418.503 325.054 418.503 325.163 418.503C325.707 418.503 326.251 418.286 326.687 417.85L372.619 371.918C373.054 371.483 373.272 370.939 373.272 370.286L376.972 366.585C377.408 366.15 377.517 365.497 377.299 364.952L536.537 205.714C536.646 205.714 536.755 205.714 536.755 205.714C537.081 205.714 537.299 205.605 537.517 205.388L542.523 200.381C542.741 200.381 542.85 200.49 543.068 200.49C543.612 200.49 544.156 200.272 544.591 199.837L596.727 147.701C597.163 147.265 597.38 146.721 597.38 146.068L610.986 132.463C611.312 132.136 611.421 131.701 611.421 131.265L619.149 123.537C619.367 123.32 619.476 122.993 619.367 122.667L855.34 -113.088L854.578 -113.85L618.605 122.122C618.278 122.122 617.952 122.122 617.734 122.34L610.006 130.068C609.571 130.068 609.136 130.177 608.809 130.503L595.204 144.109C594.659 144.109 594.006 144.327 593.571 144.762L541.544 196.898C541 197.442 540.782 198.313 541 198.966L535.993 203.973C535.666 204.299 535.666 204.626 535.775 205.061L376.428 364.19C375.884 363.973 375.231 364.082 374.795 364.517L371.095 368.218C370.551 368.218 369.897 368.435 369.462 368.871L323.53 414.803C323.095 415.238 322.877 415.782 322.877 416.435L311.231 428.082C310.904 428.408 310.795 428.735 310.795 429.061L303.068 436.789C302.741 437.116 302.741 437.442 302.85 437.878L66.0063 674.721L66.7683 675.483L303.721 438.531C303.721 438.64 303.829 438.639 303.938 438.639Z"
              fill="#1D6BFC"
            />
            <path
              d="M106.823 282.34C107.149 282.34 107.367 282.231 107.585 282.014L115.313 274.286C115.639 274.286 116.075 274.177 116.292 273.85L127.938 262.204C127.938 262.204 127.938 262.204 128.047 262.204C128.592 262.204 129.136 261.986 129.571 261.551L170.279 220.844C170.714 220.408 170.932 219.864 170.932 219.211L186.17 203.973C186.605 203.537 186.714 202.884 186.496 202.34L391.558 -2.72101C391.666 -2.72101 391.775 -2.72101 391.775 -2.72101C392.102 -2.72101 392.319 -2.82986 392.537 -3.04755L397.544 -8.05435C397.762 -8.05435 397.87 -7.9455 398.088 -7.9455C398.632 -7.9455 399.177 -8.16321 399.612 -8.59857L440.319 -49.306C440.755 -49.7414 440.973 -50.2856 440.973 -50.9387L446.088 -56.0544C446.415 -56.3809 446.523 -56.8163 446.523 -57.2516L454.251 -64.9795C454.469 -65.1972 454.578 -65.5237 454.469 -65.8503L678.687 -290.068L677.925 -290.83L453.707 -66.6122C453.381 -66.6122 453.054 -66.6122 452.836 -66.3945L445.109 -58.6666C444.673 -58.6666 444.238 -58.5577 443.911 -58.2312L438.796 -53.1156C438.251 -53.1156 437.598 -52.8979 437.163 -52.4625L396.455 -11.755C395.911 -11.2108 395.694 -10.3401 395.911 -9.68701L390.904 -4.68021C390.578 -4.35367 390.578 -4.02713 390.687 -3.59177L185.734 201.578C185.19 201.361 184.537 201.469 184.102 201.905L168.864 217.143C168.319 217.143 167.666 217.361 167.231 217.796L126.524 258.503C126.088 258.939 125.87 259.483 125.87 260.136L114.224 271.782C113.898 272.109 113.789 272.435 113.789 272.762L106.061 280.49C105.734 280.816 105.734 281.143 105.843 281.578L-110.755 497.959L-109.993 498.721L106.496 282.231C106.605 282.231 106.714 282.34 106.823 282.34Z"
              fill="#1D6BFC"
            />
            <path
              d="M157.109 362.667C157.435 362.667 157.653 362.558 157.87 362.34L165.598 354.612C165.925 354.612 166.36 354.503 166.578 354.177L178.224 342.531C178.224 342.531 178.224 342.531 178.333 342.531C178.877 342.531 179.422 342.313 179.857 341.878L215.993 305.742C216.428 305.306 216.646 304.762 216.646 304.109L226.768 293.986C227.204 293.551 227.313 292.898 227.095 292.354L476.456 42.9933C476.564 42.9933 476.673 42.9933 476.673 42.9933C477 42.9933 477.217 42.8844 477.435 42.6667L482.442 37.6599C482.66 37.6599 482.768 37.7688 482.986 37.7688C483.53 37.7688 484.075 37.5511 484.51 37.1157L520.646 0.97966C521.081 0.544281 521.299 6.10352e-05 521.299 -0.653L530.224 -9.57816C530.551 -9.90469 530.66 -10.3401 530.66 -10.7754L538.387 -18.5033C538.605 -18.721 538.714 -19.0475 538.605 -19.3741L743.993 -224.544L743.231 -225.306L537.952 -20.0271C537.626 -20.0271 537.299 -20.0271 537.081 -19.8094L529.353 -12.0816C528.918 -12.0816 528.483 -11.9727 528.156 -11.6462L519.231 -2.72101C518.687 -2.72101 518.034 -2.50333 517.598 -2.06795L481.462 34.0681C480.918 34.6123 480.7 35.4831 480.918 36.1361L475.911 41.1429C475.585 41.4695 475.585 41.796 475.694 42.2314L226.333 291.592C225.789 291.374 225.136 291.483 224.7 291.918L214.578 302.041C214.034 302.041 213.381 302.259 212.945 302.694L176.809 338.83C176.374 339.265 176.156 339.81 176.156 340.463L164.51 352.109C164.183 352.435 164.075 352.762 164.075 353.089L156.347 360.816C156.02 361.143 156.02 361.469 156.129 361.905L-45.4492 563.374L-44.6873 564.136L156.782 362.667C156.891 362.667 157 362.667 157.109 362.667Z"
              fill="#1D6BFC"
            />
            <path
              d="M150.904 355.701C151.231 355.701 151.449 355.592 151.666 355.374L159.394 347.646C159.721 347.646 160.156 347.537 160.374 347.211L172.02 335.565C172.02 335.565 172.02 335.565 172.129 335.565C172.673 335.565 173.217 335.347 173.653 334.912L209.789 298.776C210.224 298.34 210.442 297.796 210.442 297.143L221.108 286.476C221.544 286.041 221.653 285.388 221.435 284.844L469.489 36.7892C469.598 36.7892 469.707 36.7892 469.707 36.7892C470.034 36.7892 470.251 36.6803 470.469 36.4626L475.476 31.4558C475.693 31.4558 475.802 31.5647 476.02 31.5647C476.564 31.5647 477.108 31.347 477.544 30.9116L513.68 -5.22444C514.115 -5.65982 514.333 -6.20404 514.333 -6.8571L522.932 -15.4557C523.258 -15.7823 523.367 -16.2176 523.367 -16.653L531.095 -24.3809C531.313 -24.5986 531.421 -24.9251 531.312 -25.2516L737.462 -231.075L736.7 -231.837L530.659 -25.9047C530.333 -25.9047 530.006 -25.9047 529.789 -25.687L522.061 -17.9591C521.625 -17.9591 521.19 -17.8503 520.864 -17.5238L512.265 -8.92513C511.721 -8.92513 511.068 -8.70744 510.632 -8.27206L474.496 27.864C473.952 28.4082 473.734 29.279 473.952 29.932L468.945 34.9388C468.619 35.2654 468.619 35.5919 468.728 36.0273L220.782 284.082C220.238 283.864 219.585 283.973 219.149 284.408L208.483 295.075C207.938 295.075 207.285 295.293 206.85 295.728L170.714 331.864C170.279 332.299 170.061 332.844 170.061 333.497L158.415 345.143C158.088 345.469 157.979 345.796 157.979 346.122L150.251 353.85C149.925 354.177 149.925 354.503 150.034 354.939L-51.98 556.844L-51.2181 557.605L150.687 355.701C150.796 355.701 150.904 355.701 150.904 355.701Z"
              fill="#1D6BFC"
            />
            <path
              d="M145.136 348.517C145.462 348.517 145.68 348.408 145.897 348.191L153.625 340.463C153.952 340.463 154.387 340.354 154.605 340.027L166.251 328.381C166.251 328.381 166.251 328.381 166.36 328.381C166.904 328.381 167.448 328.163 167.884 327.728L204.346 291.265C204.782 290.83 204.999 290.286 204.999 289.633L215.666 278.966C216.102 278.531 216.21 277.878 215.993 277.333L462.088 31.2382C462.197 31.2382 462.306 31.2382 462.306 31.2382C462.632 31.2382 462.85 31.1293 463.067 30.9117L468.074 25.9048C468.292 25.9048 468.401 26.0137 468.618 26.0137C469.163 26.0137 469.707 25.796 470.142 25.3606L506.605 -11.102C507.04 -11.5373 507.258 -12.0816 507.258 -12.7346L515.748 -21.2244C516.074 -21.5509 516.183 -21.9863 516.183 -22.4217L523.911 -30.1496C524.129 -30.3673 524.237 -30.6938 524.129 -31.0203L730.931 -237.823L730.169 -238.585L523.367 -31.7822C523.04 -31.7822 522.714 -31.7822 522.496 -31.5645L514.768 -23.8367C514.333 -23.8367 513.897 -23.7278 513.571 -23.4013L505.081 -14.9115C504.537 -14.9115 503.884 -14.6938 503.448 -14.2584L466.986 22.2042C466.442 22.7484 466.224 23.6191 466.442 24.2722L461.435 29.279C461.108 29.6055 461.108 29.932 461.217 30.3674L215.122 276.68C214.578 276.463 213.925 276.572 213.489 277.007L202.823 287.674C202.278 287.674 201.625 287.891 201.19 288.327L164.727 324.789C164.292 325.225 164.074 325.769 164.074 326.422L152.428 338.068C152.101 338.395 151.993 338.721 151.993 339.048L144.265 346.776C143.938 347.102 143.938 347.429 144.047 347.864L-58.5107 550.313L-57.7488 551.075L144.809 348.517C144.918 348.517 145.027 348.517 145.136 348.517Z"
              fill="#1D6BFC"
            />
            <path
              d="M139.367 341.116C139.694 341.116 139.911 341.007 140.129 340.789L147.857 333.061C148.183 333.061 148.619 332.953 148.837 332.626L160.483 320.98C160.483 320.98 160.483 320.98 160.592 320.98C161.136 320.98 161.68 320.762 162.115 320.327L198.687 283.755C199.122 283.32 199.34 282.776 199.34 282.123L210.333 271.129C210.769 270.694 210.877 270.041 210.66 269.497L454.578 25.5784C454.687 25.5784 454.796 25.5784 454.796 25.5784C455.122 25.5784 455.34 25.4695 455.558 25.2518L460.564 20.2451C460.782 20.2451 460.891 20.3539 461.109 20.3539C461.653 20.3539 462.197 20.1362 462.632 19.7008L499.204 -16.8706C499.639 -17.306 499.857 -17.8502 499.857 -18.5033L508.129 -26.7754C508.456 -27.1019 508.564 -27.5373 508.564 -27.9727L516.292 -35.7005C516.51 -35.9182 516.619 -36.2448 516.51 -36.5713L724.401 -244.462L723.639 -245.224L515.748 -37.3332C515.422 -37.3332 515.095 -37.3332 514.877 -37.1155L507.149 -29.3876C506.714 -29.3876 506.279 -29.2788 505.952 -28.9523L497.68 -20.6801C497.136 -20.6801 496.483 -20.4624 496.047 -20.0271L459.476 16.5443C458.932 17.0886 458.714 17.9593 458.932 18.6124L453.925 23.6192C453.598 23.9457 453.598 24.2722 453.707 24.7076L209.898 268.844C209.354 268.626 208.701 268.735 208.265 269.17L197.272 280.163C196.728 280.163 196.075 280.381 195.639 280.816L159.068 317.388C158.632 317.823 158.415 318.367 158.415 319.021L146.769 330.667C146.442 330.993 146.333 331.32 146.333 331.646L138.605 339.374C138.279 339.701 138.279 340.027 138.388 340.463L-65.041 543.782L-64.2791 544.544L139.149 341.116C139.258 341.116 139.367 341.116 139.367 341.116Z"
              fill="#1D6BFC"
            />
            <path
              d="M133.925 333.497C134.251 333.497 134.469 333.388 134.687 333.17L142.415 325.442C142.741 325.442 143.176 325.333 143.394 325.007L155.04 313.361C155.04 313.361 155.04 313.361 155.149 313.361C155.694 313.361 156.238 313.143 156.673 312.708L193.462 275.918C193.898 275.483 194.115 274.939 194.115 274.286L205.653 262.748C206.088 262.313 206.197 261.66 205.979 261.116L446.741 20.3538C446.85 20.3538 446.959 20.3538 446.959 20.3538C447.285 20.3538 447.503 20.245 447.721 20.0273L452.727 15.0205C452.945 15.0205 453.054 15.1293 453.272 15.1293C453.816 15.1293 454.36 14.9117 454.796 14.4763L491.585 -22.3128C492.02 -22.7482 492.238 -23.2924 492.238 -23.9455L500.183 -31.8911C500.51 -32.2176 500.619 -32.653 500.619 -33.0883L508.347 -40.8162C508.564 -41.0339 508.673 -41.3605 508.564 -41.687L717.87 -250.667L717.108 -251.428L507.802 -42.2312C507.476 -42.2312 507.149 -42.2312 506.932 -42.0135L499.204 -34.2856C498.768 -34.2856 498.333 -34.1768 498.006 -33.8503L490.061 -25.9047C489.517 -25.9047 488.864 -25.687 488.428 -25.2516L451.639 11.5375C451.095 12.0817 450.877 12.9525 451.095 13.6055L446.088 18.6123C445.761 18.9389 445.762 19.2654 445.87 19.7008L205.217 260.463C204.673 260.245 204.02 260.354 203.585 260.789L192.047 272.327C191.503 272.327 190.85 272.544 190.415 272.98L153.625 309.769C153.19 310.204 152.972 310.748 152.972 311.401L141.326 323.048C141 323.374 140.891 323.701 140.891 324.027L133.163 331.755C132.836 332.082 132.836 332.408 132.945 332.844L-71.5718 537.252L-70.8099 538.014L133.707 333.497C133.816 333.497 133.925 333.497 133.925 333.497Z"
              fill="#1D6BFC"
            />
            <path
              d="M128.809 325.66C129.136 325.66 129.353 325.551 129.571 325.333L137.299 317.605C137.625 317.605 138.061 317.497 138.278 317.17L149.925 305.524C149.925 305.524 149.925 305.524 150.034 305.524C150.578 305.524 151.122 305.306 151.557 304.871L188.782 267.646C189.217 267.211 189.435 266.667 189.435 266.014L201.19 254.259C201.625 253.823 201.734 253.17 201.517 252.626L438.469 15.6735C438.578 15.6735 438.687 15.6735 438.687 15.6735C439.013 15.6735 439.231 15.5647 439.448 15.347L444.455 10.3402C444.673 10.3402 444.782 10.449 445 10.449C445.544 10.449 446.088 10.2314 446.523 9.79599L483.748 -27.4285C484.183 -27.8639 484.401 -28.4081 484.401 -29.0612L492.129 -36.7891C492.455 -37.1156 492.564 -37.5509 492.564 -37.9863L500.292 -45.7142C500.51 -45.9319 500.619 -46.2584 500.51 -46.585L711.34 -257.197L710.578 -257.959L499.748 -47.1292C499.421 -47.1292 499.095 -47.1292 498.877 -46.9115L491.149 -39.1836C490.714 -39.1836 490.278 -39.0748 489.952 -38.7482L482.224 -31.0204C481.68 -31.0204 481.027 -30.8027 480.591 -30.3673L443.367 6.85721C442.823 7.40143 442.605 8.27216 442.823 8.92523L437.816 13.932C437.489 14.2586 437.489 14.5851 437.598 15.0205L200.646 251.864C200.102 251.646 199.448 251.755 199.013 252.191L187.258 263.946C186.714 263.946 186.061 264.163 185.625 264.599L148.401 301.823C147.965 302.259 147.748 302.803 147.748 303.456L136.102 315.102C135.775 315.429 135.666 315.755 135.666 316.082L127.938 323.81C127.612 324.136 127.612 324.463 127.721 324.898L-78.1025 530.721L-77.3406 531.483L128.482 325.66C128.591 325.66 128.7 325.66 128.809 325.66Z"
              fill="#1D6BFC"
            />
            <path
              d="M123.803 317.497C124.129 317.497 124.347 317.388 124.564 317.17L132.292 309.442C132.619 309.442 133.054 309.333 133.272 309.007L144.918 297.361C144.918 297.361 144.918 297.361 145.027 297.361C145.571 297.361 146.115 297.143 146.551 296.708L184.211 259.048C184.646 258.612 184.864 258.068 184.864 257.415L196.945 245.333C197.381 244.898 197.49 244.245 197.272 243.701L429.871 11.1021C429.979 11.1021 430.088 11.1021 430.088 11.1021C430.415 11.1021 430.632 10.9933 430.85 10.7756L435.857 5.76877C436.075 5.76877 436.183 5.87759 436.401 5.87759C436.945 5.87759 437.49 5.65991 437.925 5.22455L475.585 -32.4353C476.02 -32.8707 476.238 -33.4149 476.238 -34.068L483.748 -41.5782C484.075 -41.9047 484.183 -42.3401 484.183 -42.7755L491.911 -50.5033C492.129 -50.721 492.238 -51.0476 492.129 -51.3741L704.809 -263.728L704.047 -264.49L491.367 -51.8095C491.041 -51.8095 490.714 -51.8095 490.496 -51.5918L482.768 -43.8639C482.333 -43.8639 481.898 -43.755 481.571 -43.4285L474.061 -35.9183C473.517 -35.9183 472.864 -35.7006 472.428 -35.2652L434.877 2.28577C434.333 2.82999 434.115 3.70074 434.333 4.35379L429.326 9.3606C429 9.68713 429 10.0137 429.109 10.449L196.51 243.048C195.966 242.83 195.313 242.939 194.877 243.374L182.796 255.456C182.252 255.456 181.598 255.674 181.163 256.109L143.503 293.769C143.068 294.204 142.85 294.748 142.85 295.401L131.204 306.939C130.877 307.265 130.769 307.592 130.769 307.918L123.041 315.646C122.714 315.973 122.714 316.299 122.823 316.735L-84.6328 524.191L-83.8709 524.952L123.585 317.497C123.694 317.497 123.694 317.497 123.803 317.497Z"
              fill="#1D6BFC"
            />
            <path
              d="M119.122 309.116C119.449 309.116 119.666 309.007 119.884 308.789L127.612 301.061C127.938 301.061 128.374 300.953 128.592 300.626L140.238 288.98C140.782 288.98 141.326 288.762 141.762 288.327L179.966 250.123C180.401 249.687 180.619 249.143 180.619 248.49L193.462 235.646C193.898 235.211 194.006 234.558 193.789 234.014L420.945 6.85727C421.054 6.85727 421.163 6.85727 421.163 6.85727C421.489 6.85727 421.707 6.74841 421.925 6.53073L426.932 1.52393C427.149 1.52393 427.258 1.63278 427.476 1.63278C428.02 1.63278 428.564 1.41507 429 0.979706L467.204 -37.2244C467.639 -37.6597 467.857 -38.2039 467.857 -38.857L475.04 -46.0407C475.367 -46.3672 475.476 -46.8026 475.476 -47.238L483.204 -54.9659C483.421 -55.1835 483.53 -55.5101 483.421 -55.8366L698.279 -270.258L697.517 -271.02L482.66 -56.272C482.333 -56.272 482.006 -56.272 481.789 -56.0543L474.061 -48.3264C473.625 -48.3264 473.19 -48.2176 472.864 -47.891L465.68 -40.7074C465.136 -40.7074 464.483 -40.4897 464.047 -40.0543L425.843 -1.85022C425.299 -1.306 425.081 -0.435242 425.299 0.217804L420.292 5.22461C419.966 5.55115 419.966 5.87769 420.074 6.31305L193.136 233.361C192.591 233.143 191.938 233.252 191.503 233.687L178.66 246.531C178.115 246.531 177.462 246.748 177.027 247.184L138.823 285.279C138.387 285.714 138.17 286.259 138.17 286.912L126.523 298.558C126.197 298.884 126.088 299.211 126.088 299.538L118.36 307.265C118.034 307.592 118.034 307.918 118.143 308.354L-91.1636 517.551L-90.4017 518.313L118.904 309.116C119.013 309.116 119.013 309.116 119.122 309.116Z"
              fill="#1D6BFC"
            />
            <path
              d="M114.768 300.517C115.095 300.517 115.312 300.408 115.53 300.191L123.258 292.463C123.585 292.463 124.02 292.354 124.238 292.027L135.884 280.381C135.884 280.381 135.884 280.381 135.993 280.381C136.537 280.381 137.081 280.163 137.517 279.728L176.7 240.544C177.136 240.109 177.353 239.565 177.353 238.912L190.306 225.959C190.741 225.524 190.85 224.871 190.632 224.327L411.476 3.48312C411.585 3.48312 411.693 3.48312 411.693 3.48312C412.02 3.48312 412.238 3.37427 412.455 3.15659L417.462 -1.85022C417.68 -1.85022 417.789 -1.74136 418.006 -1.74136C418.551 -1.74136 419.095 -1.95905 419.53 -2.39444L458.714 -41.5781C459.149 -42.0135 459.367 -42.5577 459.367 -43.2108L466.224 -50.0679C466.55 -50.3944 466.659 -50.8298 466.659 -51.2652L474.387 -58.9931C474.605 -59.2108 474.714 -59.5373 474.605 -59.8638L691.748 -276.789L690.986 -277.551L473.734 -60.2992C473.408 -60.2992 473.081 -60.2992 472.863 -60.0815L465.135 -52.3536C464.7 -52.3536 464.265 -52.2448 463.938 -51.9182L457.081 -45.0611C456.537 -45.0611 455.884 -44.8434 455.448 -44.408L416.265 -5.22437C415.721 -4.68015 415.503 -3.80939 415.721 -3.15634L410.714 1.85049C410.387 2.177 410.387 2.50354 410.496 2.9389L189.761 223.674C189.217 223.456 188.564 223.565 188.129 224L175.176 236.953C174.632 236.953 173.979 237.17 173.544 237.606L134.36 276.789C133.925 277.225 133.707 277.769 133.707 278.422L122.061 290.068C121.734 290.395 121.625 290.721 121.625 291.048L113.897 298.776C113.571 299.102 113.571 299.429 113.68 299.864L-97.6943 511.021L-96.9324 511.782L114.442 300.408C114.551 300.408 114.659 300.517 114.768 300.517Z"
              fill="#1D6BFC"
            />
            <path
              d="M110.633 291.483C110.959 291.483 111.177 291.374 111.394 291.156L119.122 283.429C119.449 283.429 119.884 283.32 120.102 282.993L131.748 271.347C131.748 271.347 131.748 271.347 131.857 271.347C132.401 271.347 132.945 271.129 133.381 270.694L173.326 230.748C173.762 230.313 173.979 229.769 173.979 229.116L187.258 215.837C187.694 215.401 187.803 214.748 187.585 214.204L401.68 0.108917C401.789 0.108917 401.898 0.108917 401.898 0.108917C402.224 0.108917 402.442 6.10352e-05 402.66 -0.217621L407.667 -5.2244C407.884 -5.2244 407.993 -5.1156 408.211 -5.1156C408.755 -5.1156 409.299 -5.33328 409.734 -5.76865L449.68 -45.7142C450.115 -46.1496 450.333 -46.6938 450.333 -47.3469L456.973 -53.9863C457.299 -54.3129 457.408 -54.7482 457.408 -55.1836L465.136 -62.9115C465.354 -63.1292 465.462 -63.4557 465.354 -63.7822L685.217 -283.32L684.456 -284.082L464.483 -64.1088C464.156 -64.1088 463.83 -64.1088 463.612 -63.8911L455.884 -56.1632C455.449 -56.1632 455.013 -56.0544 454.687 -55.7278L448.047 -49.0884C447.503 -49.0884 446.85 -48.8707 446.415 -48.4353L406.578 -8.59857C406.034 -8.05435 405.816 -7.18359 406.034 -6.53055L401.027 -1.52374C400.701 -1.1972 400.701 -0.870667 400.809 -0.435303L186.714 213.551C186.17 213.333 185.517 213.442 185.081 213.878L171.803 227.157C171.258 227.157 170.605 227.374 170.17 227.81L130.224 267.755C129.789 268.191 129.571 268.735 129.571 269.388L117.925 281.034C117.599 281.361 117.49 281.687 117.49 282.014L109.762 289.742C109.435 290.068 109.435 290.395 109.544 290.83L-104.225 504.49L-103.463 505.252L110.415 291.374C110.415 291.483 110.524 291.483 110.633 291.483Z"
              fill="#1D6BFC"
            />
            <path
              d="M104.537 271.456C104.864 271.456 105.081 271.347 105.299 271.129L113.027 263.401C113.353 263.401 113.789 263.292 114.006 262.966L125.653 251.32C126.197 251.32 126.741 251.102 127.176 250.667L169.19 208.653C169.625 208.218 169.843 207.673 169.843 207.02L184.755 192.109C185.19 191.673 185.299 191.02 185.081 190.476L381.217 -5.65985C381.326 -5.65985 381.435 -5.65985 381.435 -5.65985C381.762 -5.65985 381.979 -5.76871 382.197 -5.98639L387.204 -10.9932C387.421 -10.9932 387.53 -10.8843 387.748 -10.8843C388.292 -10.8843 388.836 -11.102 389.272 -11.5374L429.435 -51.7007C429.87 -52.136 430.088 -52.6803 430.088 -53.3333L435.748 -58.9932C436.074 -59.3197 436.183 -59.7551 436.183 -60.1905L443.911 -67.9184C444.129 -68.136 444.238 -68.4626 444.129 -68.7891L672.156 -296.381L671.394 -297.143L443.476 -69.3333C443.149 -69.3333 442.823 -69.3333 442.605 -69.1156L434.877 -61.3877C434.442 -61.3877 434.006 -61.2789 433.68 -60.9524L428.02 -55.2925C427.476 -55.2925 426.823 -55.0748 426.387 -54.6394L386.224 -14.4762C385.68 -13.9319 385.462 -13.0612 385.68 -12.4081L380.673 -7.40134C380.347 -7.0748 380.347 -6.74826 380.455 -6.3129L184.428 189.823C183.884 189.605 183.231 189.714 182.796 190.15L167.884 205.061C167.34 205.061 166.687 205.279 166.251 205.714L124.238 247.728C123.802 248.163 123.585 248.707 123.585 249.361L111.938 261.007C111.612 261.333 111.503 261.66 111.503 261.986L103.775 269.714C103.449 270.041 103.449 270.367 103.557 270.803L-117.286 491.429L-116.524 492.19L104.319 271.347C104.319 271.456 104.428 271.456 104.537 271.456Z"
              fill="#1D6BFC"
            />
            <path
              d="M101.707 261.333C102.033 261.333 102.251 261.225 102.469 261.007L110.197 253.279C110.523 253.279 110.959 253.17 111.176 252.844L122.823 241.197C122.823 241.197 122.823 241.197 122.931 241.197C123.476 241.197 124.02 240.98 124.455 240.544L167.993 197.007C168.428 196.572 168.646 196.027 168.646 195.374L184.21 179.81C184.646 179.374 184.754 178.721 184.537 178.177L370.333 -7.6189C370.442 -7.6189 370.55 -7.6189 370.55 -7.6189C370.877 -7.6189 371.095 -7.72775 371.312 -7.94543L376.319 -12.9522C376.537 -12.9522 376.646 -12.8434 376.863 -12.8434C377.408 -12.8434 377.952 -13.0611 378.387 -13.4965L419.421 -54.5305C419.857 -54.9659 420.074 -55.5101 420.074 -56.1631L425.516 -61.6053C425.843 -61.9318 425.952 -62.3672 425.952 -62.8026L433.68 -70.5305C433.897 -70.7482 434.006 -71.0747 433.897 -71.4012L665.625 -302.911L664.863 -303.673L433.027 -71.9454C432.7 -71.9454 432.374 -71.9454 432.156 -71.7278L424.428 -63.9999C423.993 -63.9999 423.557 -63.891 423.231 -63.5645L417.788 -58.1223C417.244 -58.1223 416.591 -57.9046 416.156 -57.4693L375.122 -16.4352C374.578 -15.891 374.36 -15.0203 374.578 -14.3672L369.571 -9.36041C369.244 -9.03387 369.244 -8.70734 369.353 -8.27197L183.666 177.415C183.122 177.197 182.469 177.306 182.033 177.742L166.469 193.306C165.925 193.306 165.272 193.524 164.836 193.959L121.299 237.497C120.863 237.932 120.646 238.476 120.646 239.129L108.999 250.776C108.673 251.102 108.564 251.429 108.564 251.755L100.836 259.483C100.51 259.81 100.51 260.136 100.618 260.572L-123.817 484.898L-123.055 485.66L101.38 261.225C101.489 261.333 101.598 261.333 101.707 261.333Z"
              fill="#1D6BFC"
            />
            <path
              d="M99.095 250.884C99.4216 250.884 99.6392 250.776 99.8569 250.558L107.585 242.83C107.911 242.83 108.347 242.721 108.564 242.395L120.211 230.748C120.211 230.748 120.211 230.748 120.32 230.748C120.864 230.748 121.408 230.531 121.843 230.095L167.34 184.599C167.775 184.163 167.993 183.619 167.993 182.966L184.755 166.204C185.19 165.769 185.299 165.116 185.081 164.572L358.252 -8.7074C358.36 -8.7074 358.469 -8.7074 358.469 -8.7074C358.796 -8.7074 359.013 -8.81622 359.231 -9.03394L364.238 -14.0407C364.456 -14.0407 364.564 -13.9319 364.782 -13.9319C365.326 -13.9319 365.871 -14.1496 366.306 -14.585L408.864 -57.1428C409.299 -57.5782 409.517 -58.1224 409.517 -58.7754L414.632 -63.8911C414.959 -64.2176 415.068 -64.653 415.068 -65.0883L422.796 -72.8162C423.013 -73.0339 423.122 -73.3605 423.013 -73.687L659.095 -309.442L658.333 -310.204L422.252 -74.2312C421.925 -74.2312 421.598 -74.2312 421.381 -74.0135L413.653 -66.2856C413.217 -66.2856 412.782 -66.1768 412.456 -65.8503L407.34 -60.7346C406.796 -60.7346 406.143 -60.5169 405.707 -60.0815L363.149 -17.5237C362.605 -16.9795 362.388 -16.1088 362.605 -15.4557L357.598 -10.4489C357.272 -10.1224 357.272 -9.79584 357.381 -9.36047L184.211 163.81C183.666 163.592 183.013 163.701 182.578 164.136L165.816 180.898C165.272 180.898 164.619 181.116 164.183 181.551L118.687 227.048C118.251 227.483 118.034 228.027 118.034 228.68L106.388 240.327C106.061 240.653 105.952 240.98 105.952 241.306L98.2243 249.034C97.8977 249.361 97.8977 249.687 98.0066 250.122L-130.347 478.367L-129.585 479.129L98.7685 250.776C98.8773 250.884 98.9862 250.884 99.095 250.884Z"
              fill="#1D6BFC"
            />
            <path
              d="M96.7003 240.109C97.0268 240.109 97.2445 240 97.4622 239.782L105.19 232.054C105.517 232.054 105.952 231.946 106.17 231.619L117.816 219.973C117.816 219.973 117.816 219.973 117.925 219.973C118.469 219.973 119.013 219.755 119.449 219.32L167.775 170.993C168.211 170.558 168.428 170.014 168.428 169.361L186.279 151.51C186.714 151.075 186.823 150.422 186.605 149.878L345.843 -9.3605C345.952 -9.3605 346.061 -9.3605 346.061 -9.3605C346.387 -9.3605 346.605 -9.46933 346.823 -9.68701L351.83 -14.6938C352.047 -14.6938 352.156 -14.585 352.374 -14.585C352.918 -14.585 353.462 -14.8027 353.898 -15.238L398.088 -59.4285C398.523 -59.8639 398.741 -60.4081 398.741 -61.0612L403.748 -66.068C404.074 -66.3945 404.183 -66.8299 404.183 -67.2653L411.911 -74.9931C412.129 -75.2108 412.238 -75.5374 412.129 -75.8639L652.564 -315.973L651.802 -316.735L411.476 -76.4081C411.149 -76.4081 410.823 -76.4081 410.605 -76.1904L402.877 -68.4625C402.442 -68.4625 402.006 -68.3537 401.68 -68.0272L396.673 -63.0203C396.129 -63.0203 395.476 -62.8027 395.04 -62.3673L350.85 -18.1768C350.306 -17.6326 350.088 -16.7618 350.306 -16.1088L345.299 -11.102C344.972 -10.7755 344.972 -10.4489 345.081 -10.0136L185.843 149.116C185.299 148.898 184.646 149.007 184.21 149.442L166.36 167.293C165.816 167.293 165.163 167.51 164.728 167.946L116.401 216.272C115.966 216.708 115.748 217.252 115.748 217.905L104.102 229.551C103.775 229.878 103.666 230.204 103.666 230.531L95.9384 238.259C95.6118 238.585 95.6119 238.912 95.7207 239.347L-136.878 471.837L-136.116 472.599L96.4826 240C96.5914 240.109 96.7003 240.109 96.7003 240.109Z"
              fill="#1D6BFC"
            />
            <path
              d="M94.5233 229.225C94.8498 229.225 95.0675 229.116 95.2852 228.898L103.013 221.17C103.34 221.17 103.775 221.061 103.993 220.735L115.639 209.089C116.183 209.089 116.727 208.871 117.163 208.435L169.299 156.299C169.734 155.864 169.952 155.32 169.952 154.667L189.435 135.184C189.87 134.748 189.979 134.095 189.761 133.551L333.326 -9.79587C333.435 -9.79587 333.544 -9.79587 333.544 -9.79587C333.87 -9.79587 334.088 -9.90469 334.306 -10.1224L339.312 -15.1292C339.53 -15.1292 339.639 -15.0203 339.857 -15.0203C340.401 -15.0203 340.945 -15.238 341.38 -15.6734L387.312 -61.6054C388.074 -62.3673 388.183 -63.4557 387.639 -64.2176L646.034 -322.503L645.272 -323.265L386.877 -64.9795C386.006 -65.5237 384.918 -65.4149 384.265 -64.653L338.333 -18.721C337.789 -18.1768 337.571 -17.3061 337.789 -16.653L332.782 -11.6462C332.455 -11.3197 332.455 -10.9932 332.564 -10.5578L189 132.898C188.455 132.68 187.802 132.789 187.367 133.225L179.421 141.17L167.884 152.708C167.34 152.708 166.687 152.925 166.251 153.361L114.115 205.497C113.68 205.932 113.462 206.476 113.462 207.129L101.816 218.776C101.489 219.102 101.38 219.429 101.38 219.755L93.6525 227.483C93.326 227.81 93.326 228.136 93.4349 228.571L-143.409 465.306L-142.647 466.068L94.3056 229.116C94.3056 229.225 94.4145 229.225 94.5233 229.225Z"
              fill="#1D6BFC"
            />
            <path
              d="M140.564 52.4626C140.891 52.4626 141.108 52.3538 141.326 52.1361L149.054 44.4083C149.38 44.4083 149.816 44.2994 150.033 43.9728L161.68 32.3266C161.68 32.3266 161.68 32.3266 161.789 32.3266C162.333 32.3266 162.877 32.1089 163.312 31.6736L213.38 -18.3945C214.142 -19.1564 214.251 -20.2448 213.707 -21.0067L580.618 -387.918L579.857 -388.68L212.945 -21.6598C212.074 -22.204 210.986 -22.0952 210.333 -21.3333L160.265 28.7348C159.829 29.1701 159.612 29.7144 159.612 30.3674L147.965 42.0137C147.639 42.3402 147.53 42.6667 147.53 42.9933L139.802 50.7212C139.476 51.0477 139.476 51.3742 139.584 51.8096L-208.715 400.109L-207.953 400.871L140.346 52.5715C140.455 52.4627 140.564 52.4626 140.564 52.4626Z"
              fill="#1D6BFC"
            />
            <path
              d="M93.7616 165.007C94.0881 165.007 94.3058 164.898 94.5235 164.68L102.36 156.735C102.578 156.626 102.904 156.517 103.122 156.408L114.768 144.762C114.768 144.762 114.768 144.762 114.877 144.762C115.421 144.762 115.966 144.544 116.401 144.109L321.68 -61.2789C322.115 -61.7143 322.333 -62.2585 322.333 -62.9115L323.095 -63.6735C323.53 -64.1088 323.639 -64.7619 323.421 -65.3061L613.272 -355.156L612.51 -355.918L322.66 -66.068C322.115 -66.2857 321.462 -66.1768 321.027 -65.7415L320.265 -64.9796C319.721 -64.9796 319.068 -64.7619 318.632 -64.3265L113.245 141.061C112.809 141.497 112.591 142.041 112.591 142.694L100.945 154.34C100.619 154.667 100.51 155.102 100.51 155.537L92.8908 163.156C92.6731 163.374 92.5643 163.701 92.5643 164.027L-176.17 432.762L-175.409 433.524L93.2173 164.898C93.435 164.898 93.5439 165.007 93.7616 165.007Z"
              fill="#1D6BFC"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_42311_4269" x1="0" y1="80" x2="1197.28" y2="80" gradientUnits="userSpaceOnUse">
        <stop stop-color="#431554" />
        <stop offset="0.53696" stop-color="#1C1C42" />
        <stop offset="0.889554" stop-color="#2F1542" />
      </linearGradient>
      <clipPath id="clip0_42311_4269">
        <rect width="227" height="160" fill="white" />
      </clipPath>
      <clipPath id="clip1_42311_4269">
        <rect width="783.673" height="783.673" fill="white" transform="translate(-35.3267 -214.422)" />
      </clipPath>
    </defs>
  </svg>
`
)
