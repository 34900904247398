import { useContext } from 'react'

import { ReliableBenefitsIcon } from '@opswat/react-icon'

import { AcademyNoData, AcademyOngoingCourse, AcademySection, AcademySwiper } from '../../components'
import { AcademyContext } from '../../context'

const OngoingCoursesSection = () => {
  const { academyProfile, ctaLink } = useContext(AcademyContext)

  const { ongoingCourses } = academyProfile

  const isEmptyData = ongoingCourses.length === 0

  return (
    <AcademySection title="Ongoing Courses">
      {isEmptyData ? (
        <AcademyNoData
          icon={<ReliableBenefitsIcon size={30} color="#707682" />}
          content={
            <>
              Oops! It looks like you haven't started your cybersecurity training yet!
              <br />
              Take the first step toward mastering cybersecurity and securing your digital future. Our expert-led
              courses at OPSWAT Academy are designed to equip you with the skills you need.
            </>
          }
          buttonText={'Start Today'}
          buttonLink={ctaLink}
        />
      ) : (
        <AcademySwiper slidesToShow={2} data={ongoingCourses} component={AcademyOngoingCourse} />
      )}
    </AcademySection>
  )
}

export default OngoingCoursesSection
