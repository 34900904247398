import { Grid } from '@opswat/react-ui'

import CompletedCoursesSection from './sections/CompletedCoursesSection'
import LearningProgressSection from './sections/LearningProgressSection'
import OngoingCoursesSection from './sections/OngoingCoursesSection'
import RecommendedCoursesSection from './sections/RecommendedCoursesSection'

const AcademyStudentView = () => {
  return (
    <Grid container item xs={12} rowSpacing={2.5}>
      <Grid item xs={12}>
        <LearningProgressSection />
      </Grid>
      <Grid item xs={12}>
        <OngoingCoursesSection />
      </Grid>
      <Grid item xs={12}>
        <RecommendedCoursesSection />
      </Grid>
      <Grid item xs={12}>
        <CompletedCoursesSection />
      </Grid>
    </Grid>
  )
}

export default AcademyStudentView
