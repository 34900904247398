import { Grid } from '@opswat/react-ui'

import { AcademyAnalytics } from '../../../../api/academy/type'
import { StatisticCard } from '../../components'
import { StatisticType, useStatistics } from '../hooks'

interface StatisticProps {
  analytics?: AcademyAnalytics
}

const Statistic = ({ analytics }: StatisticProps) => {
  const { stats } = useStatistics(analytics)

  return (
    <Grid container justifyContent={'space-between'} alignItems={'stretch'} alignSelf={'stretch'} spacing={2}>
      {stats.map((stat: StatisticType) => (
        <Grid item xs={12} sm={6} md={3} key={stat.id}>
          <StatisticCard {...stat} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Statistic
