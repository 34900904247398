import { useCallback } from 'react'
import ReactApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'

import { Box, Typography } from '@opswat/react-ui'

import { getSeriesPercentages } from '../../LicensedProductsPage/CustomerLicensesPage/sections/ChartSection/utils'

interface IProps {
  id: string
  title: string
  description: string
  centerTitle: React.ReactNode
  colors: string[]
  labels: string[]
  statistics: Record<string, any>
}

const AcademyDonutChart = ({ id, title, description, centerTitle, colors, labels, statistics }: IProps) => {
  const handleRenderDonutChart = useCallback(() => {
    return (
      <ReactApexChart
        id={id}
        series={Object.values(statistics)}
        type="donut"
        width="200px"
        height="200px"
        options={{
          colors: colors,
          labels: labels,
          plotOptions: {
            pie: {
              donut: {
                size: '60%'
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          tooltip: {
            custom: ({ series, seriesIndex }) => {
              const percentages = getSeriesPercentages(series, 0)
              return renderToString(
                <div className="custom-chart-tooltip">
                  <span className="custom-chart-tooltip-title">{percentages[seriesIndex]}%</span>
                  <span>{labels[seriesIndex]}</span>
                  <span className="custom-chart-tooltip-content">
                    {series[seriesIndex]} Course{series[seriesIndex] > 1 && 's'}
                  </span>
                </div>
              )
            }
          },
          states: {
            active: {
              filter: {
                type: 'none'
              }
            },
            hover: {
              filter: {
                type: 'lighten',
                value: 0.1
              }
            }
          }
        }}
      />
    )
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px'
      }}
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        {handleRenderDonutChart()}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-45%, -60%)',
            textAlign: 'center',
            pointerEvents: 'none'
          }}
        >
          {centerTitle}
        </div>
      </Box>
      <Box>
        <Typography variant="subtitle1" textTransform="uppercase">
          {title}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} whiteSpace="pre-wrap">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Box>
    </Box>
  )
}

export default AcademyDonutChart
