import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import _min from 'lodash/min'
import { usePortalPermissionsQuery } from 'myopswat-web/src/api/permission'
import {
  useLazyPortalRoleQuery,
  usePortalRoleCreateMutation,
  usePortalRoleUpdateMutation,
  useRoleObjectsQuery
} from 'myopswat-web/src/api/role'
import { ICreateUpdatePortalRoleForm, IRoleObjectForm } from 'myopswat-web/src/api/role/types'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CreateUpdateRoleForm from './CreateUpdateRoleForm'
import { roleSchema } from './RoleSchema'

interface IProps {
  selectedPortalRoleId?: string
  viewOnly?: boolean
  organization: any
  isSingleOrg?: boolean
}

const defaultPortalRole = {
  id: '',
  name: '',
  description: '',
  roleObjects: []
}

const CreateUpdateRoleModal = NiceModal.create(({ selectedPortalRoleId, viewOnly, organization, isSingleOrg }: IProps) => {
  const { t: translate } = useTranslation()
  const [portalRoleCreate, { isLoading: isCreating }] = usePortalRoleCreateMutation()
  const [portalRoleUpdate, { isLoading: isUpdating }] = usePortalRoleUpdateMutation()
  const { enqueueSnackbar } = useSnackbar()
  const modal = useModal()
  const formRef = useForm<ICreateUpdatePortalRoleForm>({
    defaultValues: defaultPortalRole,
    mode: 'onChange',
    resolver: yupResolver(roleSchema)
  })
  const {
    reset,
    handleSubmit,
    formState: { isValid },
    watch
  } = formRef
  const roleObjectsInput = watch('roleObjects')
  const {
    data: globalPortalPermissions,
    isFetching: isFetchingPermissions,
    isError: isGetGlobalPermissionError
  } = usePortalPermissionsQuery(null)
  const {
    data: defaultRoleObjects,
    isFetching: isFetchingRoleObjects,
    isError: isGetRoleObjectsError
  } = useRoleObjectsQuery(null)
  const [
    getPortalRoleById,
    { data: selectedPortalRole, isFetching: isFetchingPortalRole, isError: isGetPortalRoleIdError }
  ] = useLazyPortalRoleQuery()

  const onSubmit: SubmitHandler<ICreateUpdatePortalRoleForm> = async (formData: ICreateUpdatePortalRoleForm) => {
    try {
      let res
      if (selectedPortalRole) res = await portalRoleUpdate(formData).unwrap()
      else {
        formData.id = undefined
        res = await portalRoleCreate(formData).unwrap()
      }
      if (res.success) {
        if (selectedPortalRole) enqueueSnackbar(translate('updatePortalRoleSuccess'), { variant: 'success' })
        else enqueueSnackbar(translate('createPortalRoleSuccess'), { variant: 'success' })
        handleResetForm()
        modal.resolve()
        modal.remove()
      } else if (selectedPortalRole) {
        const errorMessages = res.errors.map((e: any) => e.message)
        if (errorMessages.length > 0) enqueueSnackbar(errorMessages.join(', '), { variant: 'error' })
        else enqueueSnackbar(translate('updatePortalRoleFail'), { variant: 'error' })
      } else {
        const errorMessages = res.errors.map((e: any) => e.message)
        if (errorMessages.length > 0) enqueueSnackbar(errorMessages.join(', '), { variant: 'error' })
        else enqueueSnackbar(translate('createPortalRoleFail'), { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const handleResetForm = () => {
    reset(defaultPortalRole)
  }

  const onCancel = () => {
    handleResetForm()
    modal.remove()
  }

  useEffect(() => {
    handleResetForm()
    if (selectedPortalRoleId) {
      getPortalRoleById({ id: selectedPortalRoleId, organizationId: organization.id })
    }
  }, [selectedPortalRoleId, organization.id])

  const isLoading = useMemo(() => {
    return isCreating || isUpdating || isFetchingPermissions || isFetchingRoleObjects || isFetchingPortalRole
  }, [isCreating, isUpdating, isFetchingPermissions, isFetchingRoleObjects, isFetchingPortalRole])

  useEffect(() => {
    if (isGetGlobalPermissionError || isGetPortalRoleIdError || isGetRoleObjectsError) {
      enqueueSnackbar('Getting role has failed. Please give the system a moment then try again.', { variant: 'error' })
    }
  }, [isGetGlobalPermissionError, isGetPortalRoleIdError, isGetRoleObjectsError])

  const handleSelectPermission = (roleObject: IRoleObjectForm) => {
    const excludeOldRoleObjects = roleObjectsInput.filter(r => r.id.toString() !== roleObject.id.toString())
    return [...excludeOldRoleObjects, roleObject]
  }

  useEffect(() => {
    if (isFetchingRoleObjects || !defaultRoleObjects) return

    let defaultRoleObjectInput: IRoleObjectForm[] = []
    if (selectedPortalRole) defaultRoleObjectInput = [...(selectedPortalRole?.roleObjects ?? [])]

    defaultRoleObjects.forEach(defaultObject => {
      const existedRoleObject = defaultRoleObjectInput.find(inputObject => inputObject.id === defaultObject.id)
      if (!existedRoleObject) {
        defaultRoleObjectInput.push({
          id: defaultObject.id,
          selectedPermissions: [_min(defaultObject.permissions.map(r => r.priority)) ?? 1]
        })
      }
    })

    reset({
      id: selectedPortalRole?.id ?? '',
      name: selectedPortalRole?.name ?? '',
      description: selectedPortalRole?.description ?? '',
      roleObjects: defaultRoleObjectInput
    })
  }, [selectedPortalRole, defaultRoleObjects, isFetchingRoleObjects, isFetchingPortalRole])

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isValid || selectedPortalRole?.isDefault || viewOnly}
      title={selectedPortalRole ? 'Role detail' : 'Create Role'}
      open={modal.visible}
      onClose={onCancel}
      onExited={onCancel}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit, onError)}
      confirmBtnLabel="Submit"
      minHeight={300}
      maxWidth="sm"
      hideConfirmBtn={viewOnly}
      content={
        <form>
          <CreateUpdateRoleForm
            formRef={formRef}
            globalPortalPermissions={globalPortalPermissions || []}
            defaultRoleObjects={defaultRoleObjects || []}
            isTableLoading={isFetchingRoleObjects || isFetchingPermissions || isFetchingPortalRole}
            onSelectPermission={handleSelectPermission}
            viewOnly={viewOnly || selectedPortalRole?.isDefault}
            organization={organization}
            isSingleOrg={isSingleOrg}
          />
        </form>
      }
    />
  )
})

export default CreateUpdateRoleModal
