import _get from 'lodash/get'

import { Link } from 'react-router-dom'

import { NotificationPositiveIcon } from '@opswat/react-icon'
import { Box, ListItem, Typography } from '@opswat/react-ui'

interface IProps {
  propsData: any
}

const AcademyCompletedCourse = ({ propsData }: IProps) => {
  return (
    <ListItem
      component={Link}
      to={_get(propsData, 'courseUrl')}
      target="_blank"
      sx={{
        width: '100%',
        height: '100%',
        transition: 'transform 0.75s',
        padding: '10px 20px',
        background: '#F4F4F5',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <img src={_get(propsData, 'courseIconBadgeUrl')} alt="academy course badge" width={70} height={96} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '10px',
          height: '100%',
          py: '5px'
        }}
      >
        <Typography variant="h3" lineHeight="20px" color="#141E2F">
          {_get(propsData, 'courseName')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center'
            }}
          >
            <NotificationPositiveIcon size={16} />
            <Typography variant="subtitle2" color="#485161">
              Completed
            </Typography>
          </Box>
          <Box sx={{ height: '4px', background: '#1D6BFC', width: '100%' }} />
        </Box>
      </Box>
    </ListItem>
  )
}

export default AcademyCompletedCourse
