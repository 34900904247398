import { RequestFailedIcon } from '@opswat/react-icon'
import { Box, Grid } from '@opswat/react-ui'

import AcademyLoadingErrorLayout from './AcademyLoadingErrorLayout'
import ItemNotFoundMessage from './ItemNotFoundMessage'

const AcademyError = () => {
  return (
    <AcademyLoadingErrorLayout>
      <Grid item container xs={12} direction="column" alignItems="center">
        <ItemNotFoundMessage />
        <Box sx={{ mt: 1 }}>
          <RequestFailedIcon />
        </Box>
      </Grid>
    </AcademyLoadingErrorLayout>
  )
}

export default AcademyError
