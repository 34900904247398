import { Box, Grid, Typography } from '@opswat/react-ui'
import CircularLoader from 'myopswat-web/src/assets/images/circular-loader.svg'
import AcademyLoadingErrorLayout from './AcademyLoadingErrorLayout'

const AcademyLoading = () => {
  return (
    <AcademyLoadingErrorLayout>
      <Grid item container xs={12} direction="column" alignItems="center">
        <Typography variant="subtitle2" sx={{ lineHeight: '20px' }}>
          One moment while we are retrieving your data
        </Typography>
        <Box
          component="img"
          sx={{
            mt: 1,
            animation: 'rotate 0.8s linear infinite'
          }}
          src={CircularLoader}
          width={50}
          height={50}
          alt="loading"
        />
      </Grid>
    </AcademyLoadingErrorLayout>
  )
}

export default AcademyLoading
