/* eslint-disable no-var */
import { isPlainObject } from '@reduxjs/toolkit'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { DocumentNode } from 'graphql'
import { ClientError, GraphQLClient } from 'graphql-request'
import i18next from 'i18next'
import { enqueueSnackbar } from 'notistack'

import _get from 'lodash/get'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _toLower from 'lodash/toLower'

import { getCookie, getLocalStorage, removeLocalStorage } from '@opswat/react-core'

import type { ErrorResponse, GraphqlRequestBaseQueryArgs, PrepareHeaders, RequestHeaders } from './types'

import { AUTHENTICATION_CODES, DIALOGS, ENDPOINT_NOT_TOKEN_ARRAY, KEYS } from '@myopswat/common'
import { DIALOG_MESSAGES } from 'myopswat-web/src/constants'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { setDialogMessage, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'

const stripUndefined = (obj: any) => {
  if (!isPlainObject(obj)) {
    return obj
  }
  const copy: Record<string, any> = { ...obj }
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === 'undefined') delete copy[k]
  }
  return copy
}

const checkForCustomerServerError = (response: any): { isServerError: boolean; errorMessage: string } => {
  const errors = _get(response, 'errors')
  if (errors && errors.length > 0) {
    const errorMessage = _get(errors, '0.message')
    if (errorMessage) {
      return { isServerError: true, errorMessage }
    }
  }

  return { isServerError: false, errorMessage: '' }
}

export const graphqlRequestBaseQuery = <E = ErrorResponse>(
  options: GraphqlRequestBaseQueryArgs<E>
): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  E,
  Partial<Pick<ClientError, 'request' | 'response'>>
> => {
  const client = 'client' in options ? options.client : new GraphQLClient(options.url)
  const requestHeaders: RequestHeaders = 'requestHeaders' in options ? options.requestHeaders : {}

  return async ({ document, variables }, { getState, dispatch, endpoint, forced, type, extra }) => {
    const handleAuthError = (statusCode: number, code: string) => {
      const codeValue = _toLower(code)
      if (statusCode === 403 && _has(DIALOG_MESSAGES, code)) {
        dispatch(setDialogMessage(_get(DIALOG_MESSAGES, code, '')))
      } else if (statusCode === 401 && _includes(AUTHENTICATION_CODES, codeValue)) {
        dispatch(
          toggleDialogs({
            [DIALOGS.SESSION_TIMEOUT]: true,
            [DIALOGS_WEB.NPS_NOTIFICATION]: false,
            [DIALOGS_WEB.NPS_SURVEY]: false
          })
        )
        removeLocalStorage(KEYS.BOTPRESS_WEBCHAT)
        if (window.botpress?.initialized) {
          window.botpress.close()
        }
      } else if (statusCode === 403 && codeValue === 'permission_denied') {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.PERMISSION_DENIED]: true,
            [DIALOGS_WEB.NPS_NOTIFICATION]: false,
            [DIALOGS_WEB.NPS_SURVEY]: false
          })
        )
      }
    }

    try {
      const prepareHeaders: PrepareHeaders = options.prepareHeaders ?? (x => x)
      const headers = new Headers(stripUndefined(requestHeaders))
      let result = null
      const accessToken =
        process.env.REACT_APP_TOKEN_STORAGE === KEYS.COOKIE
          ? getCookie(KEYS.TOKEN_KEY)
          : getLocalStorage(KEYS.TOKEN_KEY)

      if (!_includes(ENDPOINT_NOT_TOKEN_ARRAY, endpoint)) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      const preparedHeaders = await prepareHeaders(headers, {
        getState,
        endpoint,
        forced,
        type,
        extra
      })

      result = await client.request({
        document,
        variables,
        requestHeaders: preparedHeaders
      })

      const statusCode =
        _get(result, `${endpoint}.errors.0.statusCode`, 400) || _get(result, 'errors.0.statusCode', 400)
      const code = _get(result, `${endpoint}.errors.0.code`, '') || _get(result, 'errors.0.code', '')

      handleAuthError(statusCode, code)

      // Transform response for shorter code
      const resultKeys = _keys(result)
      const data = resultKeys.length === 1 ? _get(result, resultKeys[0]) : result

      return {
        data: data,
        meta: {}
      }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, stack, request, response } = error

        const { isServerError, errorMessage } = checkForCustomerServerError(response)

        const statusCode = _get(response?.errors, '0.statusCode', 400)
        const code = _get(response?.errors, '0.code', '')

        const customErrors =
          options.customErrors ??
          (() => ({
            code,
            statusCode,
            name,
            message: isServerError
              ? errorMessage
              : 'Network error occurred, and your request could not be completed. Please try again later.',
            stack
          }))
        const customizedErrors = type === 'query' ? (response as E) : (customErrors(error) as E)

        handleAuthError(statusCode, code)

        return { error: customizedErrors, meta: { request, response } }
      }

      if (error instanceof TypeError) {
        if (error.message === 'Network request failed') {
          enqueueSnackbar(i18next.t('connectionError'), {
            variant: 'warning',
            preventDuplicate: true
          })
        }
      }

      throw error
    }
  }
}
